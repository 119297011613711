import { checkSmtpApi } from '@/api/module/mainApi';
import { language_KR } from '@public/language/language_KR';
import { language_JP } from '@public/language/language_JP';
import { language_EN } from '@public/language/language_EN';

const setDarkMode = localStorage.getItem('dark-mode');

const commonStore = {
  namespaced: true,
  state: {
    isLoading: false,
    darkMode: setDarkMode ? setDarkMode : false,
    styles: {
      light: {
        // Scroll
        '--scroll-bg': 'inherit',
        // 1:1 문의 button
        '--button-bg': 'transparent',
        '--button-border-color': '#003f86',
        '--button-text-color': '#003f86',

        // common component color
        '--header-title-color': '#181818',
        '--header-bg-color': '#f8f8f8',
        '--header-border-color': '#d4d4d4',
        '--header-font-color': '#86868b',
        '--header-font-hover-color': '#003f86',

        '--footer-bg-color': '#f4f7fc',
        '--footer-desc-color': '#1d1d1f',

        '--list-card-bg-orange': '#fff7ee',
        '--list-card-bg-blue': '#f5f5f9',
        '--list-card-title-color': '#202020',
        '--list-card-warring-box-color': '#fb1e66',
        '--list-card-warring-text-color': '#fb1e66',

        '--table-column-bg': '#003f86',
        '--accordion-bg-color': '#f7f8fa',
        '--table-tr-bg': 'transparent',
        '--table-tr-hover': '#f7f8fa',
        '--table-td-color': '#202020',
        '--table-tr-border-color': '#e4e4e4',

        '--subTitle-text-color': '#1d1d1f',
        '--subTitle-desc-color': '#8e8e93',

        '--input-border-color': '#b8b8bd',
        '--input-disabled-bg': '#e4e4e4',
        '--input-placeholder-color': '#8e8e93',
        '--input-bg-color': '#ffffff',

        '--btn-disabled-bg': '#c8c8c8',

        '--current-modal-bg': '#fff',

        // banner section
        '--banner-title-color': '#003f86',
        '--banner-bg': '#f4f4f8',

        // The Login
        '--login-container-bg': '#f8f8f8',
        '--login-container-title': '#1d1d1f',
        '--login-active-checkText': '#000',
        '--login-btn-bg': '#ececec',
        '--login-btn-color': '#86868b',
        '--login-checkbox-border': 'gray',
        '--login-checkbox-border-width': '1px',

        // The vdProgress
        '--vdprogress-container-bg': '#f8f8f8',
        '--vdprogress-container-title': '#1d1d1f',
        '--vdprogress-cardActive-bg': 'transparent',

        // The vdApplication
        '--vdApplication-container-bg': '#f8f8f8',
        '--vdApplication-container-title': '#1d1d1f',

        // The Cloud
        '--vd-cloud-bg': '#f8f8f8',
        '--vd-cloud-border': '#e4e4e4',
        '--vd-cloud-text': '#8e8e93',
        '--vd-cloud-text2': '#1c1c1d',
        '--vd-cloud-text3': '#003f86',
        '--vd-cloud-strong': '#003f86',
        '--vd-cloud-span': '#1d1d1f',
        '--vdList-active-bg': '#fff',
        '--vdList-active-color': '#003f86',
        '--carouselBtn--bg': '#d4d4d4',
        '--carouselBtn-hover-bg': '#005bc1',
        '--carouselBtn-disable-bg': '#e9e9ea',
        '--carouselBtn-disable-color': '#8e8e93',

        // second section color
        '--second-section-bg': 'transparent',
        // listCard
        // '--orange-hover-color': '#ff7f00',
        '--orange-hover-color': '#8e8e93',
        '--blue-hover-color': '#006dec',

        // third section color
        '--third-section-bg': '#f8f8f8',
        '--third-section-title-color': '#003f86',
        '--third-section-desc-color': '#515154',
        '--third-section-close-btn-hover': require('@/assets/imgs/icon_fold_hover.png'),
        '--third-section-open-btn-hover': require('@/assets/imgs/icon_unfold.png'),

        // inquiryPage
        '--inquiry-bg-color': 'transparent',

        '--primary-color': '#003f86',
        '--text-color': '#f8f8f8',
        '--main-bg': '#ffffff',
        '--text-black-color': '#000',

        // header
        '--li-active-color': '#003f86',
        '--li-color': '#000',

        //modal
        '--modal-page-bg': 'rgba(0, 0, 0, 0.5)',
        '--modal-bg': '#fff',
        '--modal-text': '#000',

        // pagination
        '--pagination-bg': '#fff',
        '--pagination-border-color': '#e4e4e4',
        '--pagination-font': '#86868b',
        '--pagination-disable-font': '#e1e1e1',
        '--pagination-disable-bg': '#fff',
        '--pagination-disable-border': '#eff3f6',
        '--pagination-active': '#003f86',
        '--pagination-active-bg': '#f8f8f8',
        '--pagination-hover-font': '#003f86',
        '--pagination-hover-bg': '#f8f8f8',

        '--disable-border-color': '#c8c8c8',

        '--header-line-color': '#ccc',
        '--slider-bg': '#ccc',
        '--little-shortcut-color': '#004da4',
        '--little-bg': '#fff',
      },
      dark: {
        // Scroll
        '--scroll-bg': 'red',

        // 1:1 문의 button
        '--button-bg': '#48484a',
        '--button-border-color': '#48484a',
        '--button-text-color': '#c8c8c8',

        // common component color
        '--header-title-color': '#fff',
        '--header-bg-color': '#2C2C2E',
        '--header-border-color': '#2C2C2E',
        '--header-font-color': '#8e8e93',
        '--header-font-hover-color': '#f8f8f8',

        '--footer-bg-color': '#2e2f31',
        '--footer-desc-color': '#c8c8c8',

        '--list-card-bg-orange': '#2c2c2e',
        '--list-card-bg-blue': '#2c2c2e',
        '--list-card-title-color': '#f8f8f8',
        '--list-card-warring-box-color': '#ff7272',
        '--list-card-warring-text-color': '#ffffff',

        '--table-column-bg': '#003f86',
        '--accordion-bg-color': 'transparent',
        '--table-tr-bg': '#2c2c2e',
        '--table-tr-hover': '#303033',
        '--table-td-color': '#e4e4e4',
        '--table-tr-border-color': '#8e8e93',

        '--subTitle-text-color': '#f8f8f8',
        '--subTitle-desc-color': '#e4e4e4',

        '--input-border-color': '#3a3a3d',
        '--input-disabled-bg': '#3a3a3d',
        '--input-placeholder-color': '#8e8e93',
        '--input-bg-color': '#3a3a3d',

        '--btn-disabled-bg': '#48484a',

        '--current-modal-bg': '#3a3a3d',

        // banner section
        '--banner-title-color': '#fff',
        '--banner-bg': '#30303a',

        // The Login
        '--login-container-bg': '#2c2c2e',
        '--login-container-title': '#fff',
        '--login-active-checkText': '#fff',
        '--login-btn-bg': '#666',
        '--login-btn-color': '#c8c8c8',
        '--login-checkbox-border': '#006dec',
        '--login-checkbox-border-width': '3px',

        // The vdProgress
        '--vdprogress-container-bg': '#2c2c2e',
        '--vdprogress-container-title': '#fff',
        '--vdprogress-cardActive-bg': '#fff',

        // The vdApplication
        '--vdApplication-container-bg': '#2c2c2e',
        '--vdApplication-container-title': '#fff',

        // The Cloud
        '--vd-cloud-bg': '#2c2c2e',
        '--vd-cloud-border': '#3a3a3d',
        '--vd-cloud-text': '#8e8e93',
        '--vd-cloud-text2': '#fff',
        '--vd-cloud-text3': '#fff',
        '--vd-cloud-strong': '#fff',
        '--vd-cloud-span': '#c8c8c8',
        '--vdList-active-bg': '#3a3a3d',
        '--vdList-active-color': '#fff',
        '--carouselBtn--bg': '#3a3a3d',
        '--carouselBtn-hover-bg': '#48484a',
        '--carouselBtn-disable-bg': '#3a3a3d',
        '--carouselBtn-disable-color': '#8e8e93',

        // second section color
        '--second-section-bg': '#1c1c1e',
        // listCard
        '--orange-hover-color': '#e4e4e4',
        '--blue-hover-color': '#e4e4e4',

        // third section color
        '--third-section-bg': '#2c2c2e',
        '--third-section-title-color': '#f8f8f8',
        '--third-section-desc-color': '#8e8e93',
        '--third-section-close-btn-hover': require('@/assets/imgs/icon_close_hover_dark.png'),
        '--third-section-open-btn-hover': require('@/assets/imgs/icon_open_hover_dark.png'),

        // inquiryPage
        '--inquiry-bg-color': '#2c2c2e',

        '--primary-color': '#0078ff',
        '--text-color': '#c8c8c8',
        '--main-bg': '#1c1c1e',
        '--text-black-color': '#fff',

        // header
        '--li-active-color': '#c8c8c8',
        '--li-color': '#c8c8c8',

        //modal
        '--modal-page-bg': 'rgba(100, 100, 100, 0.5)',
        '--modal-bg': '#1c1c1e',
        '--modal-text': '#fff',

        // pagination
        '--pagination-bg': '#2c2c2e',
        '--pagination-border-color': '#2c2c2e',
        '--pagination-font': '#8e8e93',
        '--pagination-disable-font': '#86868b',
        '--pagination-disable-bg': '#313132',
        '--pagination-disable-border': '#313132',
        '--pagination-active': '#f8f8f8',
        '--pagination-active-bg': '#6c6c6d',
        '--pagination-hover-font': '#f8f8f8',
        '--pagination-hover-bg': '#2c2c2e',

        '--disable-border-color': '#48484a',

        '--header-line-color': '#86868b',
        '--slider-bg': '#3a3a3d',
        '--little-shortcut-color': '#006dec',
        '--little-bg': '#3a3a3d',
      },
    },
    skeleton: true,
    setIntervalFunc: {
      popupInfo: function () {},
      progressVd: function () {},
    },
    smtpState: '', // smtp사용여부
    language: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'KR',
    langJson: localStorage.getItem('lang')
      ? require(`@public/language/language_${localStorage.getItem('lang') || 'KR'}`)[
          `language_${localStorage.getItem('lang')}`
        ]
      : language_KR,
    langOpt: {
      chooseVal: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'KR',
      dropdown: [
        { text: '한국어', value: 'KR' },
        // { text: 'English', value: 'EN' },
        { text: '日本語', value: 'JP' },
      ],
    },
  },
  getters: {
    isDarkMode(state) {
      return state.darkMode !== false;
    },
    returnChooseLang(state) {
      return state.langOpt.chooseVal;
    },
    IS_LANG(state) {
      return keyPath => {
        return keyPath[state.language] || 'Unknown';
      };
    },
  },
  mutations: {
    CHOOSE_LANGUAGE(state, value) {
      state.langOpt.chooseVal = value;
      localStorage.setItem('lang', value || 'KR');
      switch (value) {
        case 'EN':
          state.langJson = language_EN;
          state.language = 'EN';
          document.title = 'CenterPost User Portal';
          break;
        case 'JP':
          state.langJson = language_JP;
          state.language = 'JP';
          document.title = 'CenterPost ユーザーポータル';
          break;
        case 'KR':
        default:
          state.langJson = language_KR;
          state.language = 'KR';
          document.title = 'CenterPost 사용자 포탈';
          break;
      }
    },
    SET_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_DARK_MODE(state, checked) {
      checked ? localStorage.setItem('dark-mode', checked) : localStorage.removeItem('dark-mode');
      state.darkMode = !state.darkMode;
    },
    SET_SKELETON(state, _) {
      state.skeleton = !state.skeleton;
    },
    SET_INTERVAL_FUNC(state, data) {
      const { title, func } = data;

      state.setIntervalFunc[title] = func;
    },
    SET_SMTP_STATE(state, smtpState) {
      state.smtpState = smtpState;
    },
  },
  actions: {
    // SMTP 사용여부 확인
    async CHECK_SMTP({ state, commit }) {
      commit('SET_LOADING', true);
      try {
        const { data } = await checkSmtpApi();
        switch (data.status) {
          case '5000':
          case '0000':
            if (typeof data.output === 'string') {
              // 0: 사용 안함, 1: 사용함, NODATA: 사용여부 데이터 없음
              commit('SET_SMTP_STATE', data.output);
            }
            break;
          default:
            alert(state.langJson.modules.common.failUserData);
            break;
        }
      } catch (e) {
        console.error(e);
      } finally {
        commit('SET_LOADING', false);
      }
    },
  },
};

export default commonStore;
