<template>
  <router-view />
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
  name: 'App',
  async created() {
    if (!localStorage.getItem('lang')) {
      await this.getUserCountry();
    } else {
      this.setTitle();
    }
  },
  watch: {
    async returnChooseLang(newVal, oldVal) {
      if (newVal !== oldVal) {
        // window.location.reload();
      }
    },
  },
  computed: {
    ...mapGetters('common', ['returnChooseLang']),
  },
  methods: {
    ...mapMutations('common', ['CHOOSE_LANGUAGE']),
    async getUserCountry() {
      try {
        const userLanguage = navigator.language || navigator.userLanguage;
        const userCountryCode = userLanguage.split('-')[1];
        await this.CHOOSE_LANGUAGE(userCountryCode);
      } catch (error) {
        console.error(error);
      }
    },
    setTitle() {
      switch (localStorage.getItem('lang')) {
        case 'EN':
          document.title = 'CenterPost User Portal';
          return;
        case 'JP':
          document.title = 'CenterPost ユーザーポータル';
          return;
        case 'KR':
        default:
          document.title = 'CenterPost 사용자 포탈';
          return;
      }
    },
  },
};
</script>

<style>
@import url('@/assets/css/reset.css');
</style>
