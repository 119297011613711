// http://119.196.102.171:5050/user/allocatedVD
// http://119.196.102.171:5050/user/VMID

import { instance } from '@/api';

//  vd 정보 받아오기
export const allocatedVdApi = data => {
  return instance.post('/user/allocatedVD', data);
};
// VMID 받아오기 (Linker접속을 위함)
export const getVmIdApi = data => {
  return instance.post('/user/VMID', data);
};

// 새로운 VD 할당받기
export const newVdApi = data => {
  return instance.post('/user/request/newvd', data);
};

// VD 프로그래스 받아오기
export const progressVdApi = data => {
  return instance.post('/user/request/progress', data);
};

// VD 재시작
export const vdRestartApi = data => {
  return instance.post('/user/restartvd', data);
};
