<template>
  <transition name="slide">
    <tr :key="isKey" :class="$style.accordionTr" v-if="showAccordion">
      <td
        :colspan="colspan"
        style="
          padding: 1.2rem 1.8rem;
          text-align: left;
          cursor: default;
          line-height: 2rem;
          word-wrap: break-word;
          overflow-wrap: break-word;
        "
      >
        <!--첨부파일 색션-->
        <div v-if="returnFile2">
          {{ _json.common.accordionTr.attachment }}
          <a
            @click="rememberDl(2)"
            :class="[$style.fileDl, fileDownload2 ? $style.active : '']"
            :href="`${url}${content.FilePath2}`"
            download
          >
            {{ content.FileName2 }}
          </a>
        </div>
        <div v-if="returnFile3">
          {{ _json.common.accordionTr.attachment }}
          <a
            @click="rememberDl(3)"
            :class="[$style.fileDl, fileDownload3 ? $style.active : '']"
            :href="`${url}${content.FilePath3}`"
            download
          >
            {{ content.FileName3 }}
          </a>
        </div>
        <div v-if="returnFile4">
          {{ _json.common.accordionTr.attachment }}
          <a
            @click="rememberDl(3)"
            :class="[$style.fileDl, fileDownload4 ? $style.active : '']"
            :href="`${url}${content.FilePath4}`"
            download
          >
            {{ content.FileName4 }}
          </a>
        </div>
        <div v-if="returnStraightDiv">
          <div :class="$style.straight" />
        </div>
        <!--이미지 및 컨텐츠 색션-->
        <div>
          <p :class="$style.content" v-text="returnContent" />
          <br />
          <img
            v-if="
              (content.FileName1 !== null && content.FileName1 !== undefined) ||
              content.FilePath1 !== null
            "
            :class="$style.img"
            :src="`${url}${content.FilePath1}`"
            :alt="_json.common.accordionTr.imgPreview"
          />
        </div>
        <slot></slot>
      </td>
    </tr>
  </transition>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      fileDownload2: false,
      fileDownload3: false,
      fileDownload4: false,
      url: '',
    };
  },
  props: {
    isKey: {
      type: [String, Number],
    },
    colspan: String,
    showAccordion: { type: Boolean, default: false },
    content: Object,
    localStorageName: {
      type: [String, Number],
    },
  },
  mounted() {
    this.checkDl();
    this.url = `${window.location.protocol}//${window.location.hostname}/userapi`;
  },
  computed: {
    returnFile2() {
      let file2 = this.content?.FileName2 !== null && this.content?.FileName2 !== undefined;
      return file2;
    },
    returnFile3() {
      let file3 = this.content?.FileName3 !== null && this.content?.FileName3 !== undefined;
      return file3;
    },
    returnFile4() {
      let file4 = this.content?.FileName4 !== null && this.content?.FileName4 !== undefined;
      return file4;
    },
    returnStraightDiv() {
      let file2 = this.content?.FileName2 !== null && this.content?.FileName2 !== undefined;
      let file3 = this.content?.FileName3 !== null && this.content?.FileName3 !== undefined;
      let file4 = this.content?.FileName4 !== null && this.content?.FileName4 !== undefined;
      return file2 || file3 || file4;
    },
    returnContent() {
      // let str = '';

      // if (this.content?.Content) {
      //   str = this.content.Content.replaceAll(/(\n|\r\n)/g, '<br>');
      //
      //   return str;
      // }

      let txt = document.createElement('textarea');
      txt.innerHTML = this.content.Content;

      return txt.value;
    },
  },
  methods: {
    checkDl() {
      if (localStorage.getItem(this.localStorageName) !== null) {
        for (let i = 2; i <= 4; i++) {
          let arr = this.checkLocalStorage(i);
          if (arr[0] === this.content[`SID${i}`]) {
            this[`fileDownload${i}`] = true;
          }
        }
      }
    },
    rememberDl(idx) {
      this[`fileDownload${idx}`] = true;
      let check = localStorage.getItem(this.localStorageName);
      if (check !== null) {
        let arr = this.checkLocalStorage(idx);
        if (arr[0]) {
          return;
        }
        let SID = localStorage.getItem(this.localStorageName);
        SID = SID + ',' + this.content[`SID${idx}`];
        localStorage.setItem(this.localStorageName, SID);
        return;
      }
      localStorage.setItem(this.localStorageName, this.content[`SID${idx}`]);
    },
    checkLocalStorage(idx) {
      let SID = localStorage.getItem(this.localStorageName);
      let arr = SID.split(',');
      return arr.filter(SID => SID === this.content[`SID${idx}`]);
    },
  },
};
</script>

<style module>
.accordionTr {
  background-color: var(--accordion-bg-color) !important;
}
.accordionTr:hover {
  background-color: var(--accordion-bg-color) !important;
}
.accordionTr td {
  vertical-align: middle;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-all;
  white-space: pre-wrap;
}
.accordionTr td p {
  max-width: 942px;
}
.content {
  display: inline-block;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
}

/* 내용 */
.fileDl {
  display: inline-block;
  color: #1c79c8;
  text-decoration: underline;
  margin-bottom: 0.5rem;
}
.fileDl:hover {
  color: #542d95;
}
.fileDl.active {
  color: #542d95;
}
.straight {
  width: 100%;
  border-bottom: 2px solid var(--table-tr-border-color);
  margin-bottom: 1rem;
}
.img {
  width: 100%;
  background-size: contain;
}

.content {
  white-space: pre-wrap;
}
</style>

<style scoped>
/*테이블 슬라이드 애니메이션*/

.slide-enter-active {
  transition: all 0.5s;
}

.slide-enter {
  transform: translateY(-30px);
  opacity: 0;
}
</style>
