<template>
  <button
    :type="type"
    :class="[$style.btn, $style[btnStyle]]"
    :style="cssProps"
    :disabled="disabled"
    @click="$emit('isClick')"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'button',
    },
    width: {
      type: String,
      default: '221px',
    },
    padding: {
      type: String,
      default: '206px',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    btnStyle: {
      type: String,
      default: '',
      validator: function (value) {
        return ['', 'warning'].includes(value);
      },
    },
  },
  computed: {
    cssProps() {
      return {
        '--width': this.width,
        '--height': this.height,
      };
    },
  },
};
</script>

<style module>
.btn {
  display: block;
  width: var(--width);
  padding: 0.75rem 0.5rem;
  text-align: center;
  background-color: #005bc1;
  color: #fff;
  font-size: 0.875rem;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.btn:disabled {
  background-color: #ececec;
  color: #86868b;
  cursor: default;
}

.btn:not(:disabled):hover {
  background: #004da4;
  color: #f8f8f8;
  cursor: pointer;
}

/* Button Style */
.btn.warning {
  background-color: #fff0f0;
  color: #dd5e56;
}

.btn.warning:hover {
  background-color: #e90909;
  color: #fff;
}
</style>
