<template>
  <div
    :class="$style.basicCard"
    :style="{ width: width, paddingBottom: paddingBottom ? '5rem' : '' }"
  >
    <slot></slot>
    <slot name="btnArea"></slot>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: '100%',
    },
    paddingBottom: Boolean,
  },
};
</script>

<style module>
.basicCard {
  position: relative;
  background-color: var(--inquiry-bg-color);
  border-color: #0078ff;
  border-radius: 4px;
  border-style: solid none none;
  border-width: 4px 0 0;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  line-height: 1rem;
  padding: 1.75rem 2rem;
  margin: 0 auto 2.3rem;
}
</style>
