<template>
  <div :class="$style.container" :style="cssProps">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    padding: {
      type: String,
      default: '60px 4px',
    },
    bg: {
      type: String,
      default: 'inherit',
    },
    width: {
      type: String,
      default: '1000px',
    },
  },
  computed: {
    cssProps() {
      return {
        '--props-padding': this.padding,
        '--props-bg': this.bg,
        '--props-width': this.width,
      };
    },
  },
};
</script>

<style module>
.container {
  position: relative;
  margin: 0 auto;
  width: var(--props-width);
  height: 100%;
  padding: var(--props-padding);
  background-color: var(--props-bg);
}
</style>
