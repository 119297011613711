<template>
  <div
    :class="$style.modal"
    ref="littleModal"
    :style="cssProps"
    @mousedown="handleMouseDown"
    @mouseup="handleMouseUp"
  >
    <div :class="$style.content" @mousedown="contentMouseDown">
      <slot name="content"></slot>
    </div>
    <div :class="$style.btnGroup">
      <slot name="btn"></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modalTop: 0,
      modalLeft: 0,
      contentClick: false,
    };
  },
  props: {
    Top: {
      type: String,
      default: '0px',
    },
    Left: {
      type: String,
      default: '0px',
    },
    width: {
      type: String,
      default: '250px',
    },
  },
  computed: {
    cssProps() {
      return {
        '--top': this.Top,
        '--left': this.Left,
        '--width': this.width,
      };
    },
  },
  methods: {
    // ================== [ 모달 클릭시 동작 ] ==================
    handleMouseDown(e) {
      if (this.contentClick) {
        this.contentClick = false;
        return;
      }

      const targetModal = e.currentTarget;

      // 모달 현재 위치값
      // 브라우저에서 사용자에게 웹페이지가 보여지는 영역을 기준으로 좌표를 표시
      this.modalTop = e.clientX - targetModal.offsetLeft;
      this.modalLeft = e.clientY - targetModal.offsetTop;

      document.addEventListener('mousemove', this.handleDragg);
    },
    // ================== [ 모달 드래그시 동작 ] ==================
    handleDragg(e) {
      const targetModal = this.$refs.littleModal;

      // 이동된 위치값
      let newX = e.clientX - this.modalTop;
      let newY = e.clientY - this.modalLeft;

      // // x축 제한
      // if (newX < 0) {
      //   newX = 0;
      //   document.removeEventListener('mousemove', this.handleDragg);
      // } else if (newX > 1800) {
      //   newX = 1800;
      //   document.removeEventListener('mousemove', this.handleDragg);
      // }
      //
      // // Y축 제한
      // if (newY < 200) {
      //   newY = 205;
      //   document.removeEventListener('mousemove', this.handleDragg);
      // } else if (newY > 1000) {
      //   newY = 1000;
      //   document.removeEventListener('mousemove', this.handleDragg);
      // }

      targetModal.style.left = newX + 'px';
      targetModal.style.top = newY + 'px';
    },
    // ================== [ 모달 드래그시 끝날시 동작 ] ==================
    handleMouseUp() {
      document.removeEventListener('mousemove', this.handleDragg);
    },
    contentMouseDown() {
      this.contentClick = true;
    },
  },
};
</script>

<style module>
.modal {
  position: absolute;
  top: var(--top);
  left: var(--left);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1.375rem;
  padding: 2rem 2.75rem;
  width: var(--width);
  background-color: var(--little-bg);
  border-radius: 8px;
  color: var(--modal-text);
  z-index: 100;
  box-shadow: rgba(50, 50, 93, 0.25) 0 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  border-top: 4px solid #006dec;
}

.content {
  flex: 1;
  width: 100%;
  text-align: center;
}

.btnGroup {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
}
</style>
