<template>
  <section :class="$style.bg">
    <TheContainer padding="0 4px" :width="'1100px'">
      <div
        style="
          position: relative;
          display: grid;
          gap: 1.25rem;
          grid-template-columns: 652px 1fr;
          padding: 1.375rem 0;
        "
      >
        <TheListCard
          :class="$style.card"
          :cardTitle="_json.home.secondSection.notices"
          @isClick="$router.push('/notice')"
          :listLength="noticeList.length"
        >
          <li v-for="(item, i) in noticeList" :key="i" @click="movePage({ page: 'notice', item })">
            <table>
              <colgroup>
                <col style="width: 10%" />
                <col style="width: 75%" />
                <col style="width: 15%" />
              </colgroup>
              <tbody>
                <tr>
                  <td>
                    <p :class="[$style.column, $style[returnWarringBox(item.NoticeType)]]">
                      {{ returnNoticeType(item.NoticeType) }}
                    </p>
                  </td>
                  <td :class="[$style.maxWidth, $style[returnNoticeCssType(item.NoticeType)]]">
                    <span>
                      {{ item.Subject }}
                    </span>
                  </td>
                  <td :class="$style.regDate">{{ returnRegDate(item.RegDate) }}</td>
                </tr>
              </tbody>
            </table>
          </li>
        </TheListCard>
        <TheListCard
          cardTitle="FAQ"
          color="blue"
          @isClick="$router.push('/faq')"
          :listLength="faqList.length"
        >
          <li
            v-for="(item, i) in faqList"
            :key="i"
            @click="movePage({ page: 'faq', item })"
            :class="$style.faqText"
          >
            {{ item.Subject }}
          </li>
        </TheListCard>

        <!-- ================ [VD할당 진행상황 알림 팝업] ================ -->
        <VdProcessStateModal
          v-if="popupInfo?.RequestPopUpFlag && popupInfo?.RequestPopUpType !== 'd'"
        />
        <!-- ================ [ 1:1문의 답변 알림 팝업] ================ -->
        <TheLittleModal
          v-if="popupInfo?.InquiryPopUpFlag"
          id="qnaModal"
          Top="100px"
          Left="86.5%"
          width="300px"
        >
          <template #content>
            <div :class="$style.modalWrap">
              <strong :class="$style.strong">
                {{ _json.home.secondSection.inquiryAnswer }}
              </strong>
              <button :class="$style.linkBtn" @click="moveQnaPage">
                [ {{ _json.home.secondSection.goTo }} ]
              </button>
            </div>
          </template>
          <template #btn>
            <TheButton width="150px" @isClick="closeModal">
              {{ _json.home.secondSection.close }}
            </TheButton>
          </template>
        </TheLittleModal>
        <!-- ================ [VD 만료일 안내 알림 팝업] ================ -->
        <TheLittleModal
          v-if="vdExpiredModalData?.vdExpiredModal"
          Top="140px"
          Left="70%"
          width="700px"
          id="vdExpiredModal"
        >
          <template #content>
            <div :class="$style.modalWrap">
              <strong :class="$style.title">{{ _json.home.secondSection.annae }}</strong>
              <p :class="$style.text" v-html="vdExpiredModalData?.Detail"></p>
            </div>
          </template>
          <template #btn>
            <TheButton width="500px" @isClick="closeVdExpiredModal">
              {{ _json.home.secondSection.confirm }}
            </TheButton>
          </template>
        </TheLittleModal>
      </div>
    </TheContainer>
  </section>
</template>

<script>
import { fetchFaqListApi, fetchNoticeListApi } from '@/api/module/subPageApi';
import { GET_LIST_ACTION } from '@/utils/globalAction';
import { mapState } from 'vuex';
import { qnaReadApi } from '@/api/module/mainApi';
import { setCookie } from '@/utils/cookie';

export default {
  data() {
    return {
      noticeList: [],
      faqList: [],
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('modal', ['popupInfo', 'vdExpiredModalData']),
    returnNoticeCssType() {
      return NoticeType => {
        switch (Number(NoticeType)) {
          case 3:
            return 'red';
          case 2:
            return 'red';
          default:
            return '';
        }
      };
    },
    returnNoticeType() {
      return NoticeType => {
        switch (Number(NoticeType)) {
          case 0:
            return this._json.home.secondSection.all;
          case 1:
            return this._json.home.secondSection.general;
          case 3:
            return this._json.home.secondSection.urgent;
          case 2:
            return this._json.home.secondSection.urgent;
          default:
            return this._json.home.secondSection.general;
        }
      };
    },
    returnWarringBox() {
      return NoticeType => {
        switch (Number(NoticeType)) {
          case 3:
            return 'warring';
          default:
            return '';
        }
      };
    },
    returnRegDate() {
      return RegDate => {
        if (!RegDate) {
          return;
        }
        let arr = RegDate.split(' ');
        return arr[0];
      };
    },
  },
  watch: {
    async user(newVal, oldVal) {
      if (newVal !== oldVal) {
        await this.getFaqList();
        await this.getNoticeList();
      }
    },
  },
  async mounted() {
    try {
      await this.getFaqList();
      await this.getNoticeList();
    } catch (e) {
      console.error(e);
    }
  },
  methods: {
    async movePage({ page, item }) {
      await this.$router.push(`/${page}/${item.BoardSeq}`);
    },
    // 홈FAQ 리스트 받아오기
    async getFaqList() {
      this.faqList = await GET_LIST_ACTION(
        fetchFaqListApi({ pageSize: '5', pageNumber: '1' }),
        this._json.home.secondSection.failFaqList,
      );
    },
    // 홈Notice 리스트 받아오기
    async getNoticeList() {
      this.noticeList = await GET_LIST_ACTION(
        fetchNoticeListApi({
          userId: this.user?.UserAlias,
          pageSize: '5',
          pageNumber: '1',
        }),
        this._json.home.secondSection.failNoticeList,
      );
    },
    // 1:1문의 팝업창 닫기
    closeModal() {
      const qnaModal = document.querySelector('#qnaModal');
      qnaModal.style.display = 'none';
    },
    // qna 팝업창 바로가기 이동하기
    async moveQnaPage() {
      let { data } = await qnaReadApi({
        userId: this.user?.UserAlias,
        seq: this.popupInfo?.InquiryPopUpSeq,
      });
      switch (data.status) {
        case '0000':
        case '5000':
          break;
        default:
          console.error(this._json.home.secondSection.failInquiryList);
          break;
      }

      await this.$router.push(`/qna/${this.popupInfo?.InquiryPopUpSeq}`);
    },
    // vd 만료일자 안내 팝업 닫기
    async closeVdExpiredModal() {
      await setCookie(`vdExpiredModal${this.user.UserAlias ? this.user.UserAlias : ''}`, 'close');
      const vdExpiredModal = document.querySelector('#vdExpiredModal');
      vdExpiredModal.style.display = 'none';
    },
  },
};
</script>

<style module>
.bg {
  background-color: var(--second-section-bg);
}
.column {
  display: inline-block;
  background-color: #6c6c6d;
  border-radius: 3px;
  text-align: center;
  color: #f8f8f8;
  padding: 0.14rem 0.8rem;
  font-size: 0.875rem;
}

.maxWidth {
  max-width: 380px;
}

.maxWidth span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.faqText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.warring {
  background-color: var(--list-card-warring-box-color);
  color: #ffffff;
}
.red {
  color: var(--list-card-warring-text-color) !important;
}
.red:hover {
  text-decoration-color: var(--list-card-warring-text-color) !important;
  text-underline-offset: 5px;
  transition: all 0.5s ease-in-out;
  color: #003f86;
}
.red span:hover::after {
  width: 100%;
  height: 20px;
  padding-bottom: calc(1rem + 5px);
  background-color: red;
  transition: all 0.5s ease-in-out !important;
}
.regDate {
  display: block;
  width: 100px;
  text-align: end;
  color: #8e8e93;
}

/* 팝업창 스타일 =========================*/
.modalWrap {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.strong {
  font-weight: 700;
}

.strong span {
  color: var(--list-card-warring-text-color);
}

.linkBtn {
  font-weight: 400;
  color: var(--little-shortcut-color);
}

.title {
  font-size: 1.2rem;
  font-weight: 700;
}

.text {
  width: 100%;
  text-align: start;
  line-height: 1.8rem;
}

.text strong {
  font-weight: 700;
}

.card {
  height: 250px;
}
</style>
