<template>
  <TheModal
    :title="_json.modal.infoRegi.infoRegi"
    :desc="_json.modal.infoRegi.noRegisteredBirth_Email"
    height="auto"
    :width="language === 'EN' ? '493px' : '432px'"
    name="informationRegist"
    @isSubmit="registInformation"
  >
    <template #content>
      <div :class="$style.content">
        <TheInput
          :placeholder="_json.modal.infoRegi.birthday"
          :value="birthday"
          name="birthday"
          @isInput="setUserInfo($event.target)"
        />
        <div :class="$style.emailWrap">
          <TheInput
            :disabledPlaceholder="smtpSuccess ? email : _json.modal.infoRegi.notRegiEmail"
            :placeholder="`${_json.modal.infoRegi.eg} jshwang@tilon.com`"
            :value="email"
            name="email"
            @isInput="setUserInfo($event.target)"
            :disabled="checked || smtpSuccess"
          />
        </div>
        <div :class="$style.buttonWrap">
          <button
            :style="{ width: language === 'EN' ? '225px' : '' }"
            :disabled="sendSmtp"
            @click="sendSmtpCode"
            type="button"
          >
            {{ _json.modal.infoRegi.sendAuthEmail }}
          </button>
          <strong v-if="smtpSuccess"> {{ _json.modal.infoRegi.authComplete }}</strong>
          <strong v-else>{{ _json.modal.infoRegi.authInComplete }}</strong>
        </div>
        <div :class="$style.buttonWrap">
          <input type="checkbox" id="skipEmail" v-model="checked" />
          <label :class="$style.checkCustom" for="skipEmail" @click="skipEmailAlert"></label>
          <div :class="$style.checkText">{{ _json.modal.infoRegi.skipEmail }}</div>
        </div>
      </div>
    </template>
    <template #btn>
      <TheButton
        :style="{ width: language === 'EN' ? '194px' : '134px' }"
        :disabled="submitBtnDisabled"
        type="submit"
        ref="submitBtn"
      >
        {{ _json.modal.infoRegi.confirm }}
      </TheButton>
      <TheButton
        :style="{ width: language === 'EN' ? '194px' : '134px' }"
        btnStyle="warning"
        @isClick="modalClose"
      >
        {{ _json.modal.infoRegi.close }}
      </TheButton>
    </template>
  </TheModal>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { getSecureCodeApi, updateBirthdayApi, updateEmailApi } from '@/api/module/mainApi';
import { CHECK_BIRTHDAY_RANGE, CHECK_HANGLE, CHECK_NUMBER } from '@/utils/pwdReg';

export default {
  data() {
    return {
      birthday: '',
      email: '',
      checked: false,
      smtp: false,
      birthdayResult: false,
      emailResult: false,
      smtpClick: false,
    };
  },
  watch: {
    async checked(newVal) {
      if (newVal) {
        await this.SET_ALERT_VALUE({
          desc: this._json.modal.infoRegi.skipDesc,
          btnName: this._json.modal.infoRegi.confirm,
        });
      }
    },
    isTimerModalSuccess(newVal, oldVal) {
      if (newVal !== oldVal && !!newVal) {
        this.smtp = true;
      }
    },
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('modal', ['modalList']),
    ...mapGetters('modal', ['isTimerModalSuccess']),
    submitBtnDisabled() {
      return this.checked ? this.birthday === '' : this.birthday === '' || this.email === '';
    },
    smtpSuccess() {
      return this.email !== '' && !this.checked && this.smtp;
    },
    sendSmtp() {
      // 이메일 건너뛰기를 체크하거나, 인증메일 발송에 성공!!
      return this.email === '' || !!this.checked || !!this.smtp || this.smtpClick;
    },
  },
  mounted() {
    this.setFocus();
  },
  methods: {
    ...mapMutations('modal', ['SET_MODAL_OPEN', 'SET_ALERT_VALUE', 'SET_TIMER_MODAL']),
    // ...mapActions('auth', ['LOGOUT_MODULE']),
    setUserInfo(e) {
      this[e.name] = e.value;
    },
    modalClose() {
      this.SET_MODAL_OPEN('informationRegist');
    },
    // 이메일 등록 안함
    async skipEmailAlert() {
      if (!this.checked) {
        await this.SET_ALERT_VALUE({
          desc: this._json.modal.infoRegi.skipDesc,
        });
        this.email = '';
      }
    },
    // smtp코드 전송
    async sendSmtpCode() {
      this.smtpClick = true;
      if (this.email === '') {
        await this.SET_ALERT_VALUE({
          desc: this._json.modal.infoRegi.enterEmail,
        });
        return;
      }
      let res = CHECK_HANGLE(this.email);
      if (res) {
        await this.SET_ALERT_VALUE({
          desc: this._json.modal.infoRegi.noKor,
        });
        this.smtpClick = false;
        return;
      }
      try {
        const { data } = await getSecureCodeApi({
          UserID: this.user?.UserAlias,
          Email: this.email,
          // Email: this.email + '@tilon.com',
        });
        switch (data.status) {
          case '0000':
          case '5000':
            // 성공
            await this.SET_TIMER_MODAL({ UserID: this.user?.UserAlias, login: true });
            break;
          case '0001':
          case '5001':
            // 실패
            await this.SET_ALERT_VALUE({
              desc: this._json.modal.infoRegi.sendFail,
            });
            break;
          case '0002':
          case '5002':
            // 실패
            await this.SET_ALERT_VALUE({
              desc: this._json.modal.infoRegi.emailFail,
            });
            break;
          default:
            // 실패
            await this.SET_ALERT_VALUE({
              desc: this._json.modal.infoRegi.sendFail,
            });
            break;
        }
        this.smtpClick = false;
      } catch (e) {
        console.error(e);
      }
    },
    // 정보등록
    async registInformation() {
      if (!this.checked && !this.smtp) {
        await this.SET_ALERT_VALUE({
          desc: this._json.modal.infoRegi.yetComplete,
        });
        return;
      }

      // 생년월일 유효성 검사
      if (this.birthday === '') {
        await this.SET_ALERT_VALUE({
          desc: this._json.modal.infoRegi.enterBirthday,
        });
        return;
      }
      // 생일 유효성 체크
      let res = CHECK_NUMBER(this.birthday);
      if (!res) {
        await this.SET_ALERT_VALUE({
          desc: this._json.modal.infoRegi.onlyNumber,
          btnName: this._json.modal.infoRegi.confirm,
        });
        return;
      }

      res = CHECK_BIRTHDAY_RANGE(this.birthday);
      if (!res.result) {
        await this.SET_ALERT_VALUE({
          desc: res.text,
          btnName: this._json.modal.infoRegi.confirm,
        });
        return;
      }

      if (this.checked) {
        // 생년월일만 등록
        await this.confirmBirthday();
        if (this.birthdayResult) {
          await this.SET_MODAL_OPEN('informationRegist');
          await this.SET_ALERT_VALUE({
            desc: this._json.modal.infoRegi.infoChangeSuccess,
          });

          // [ FirstLogin 을 임의로 1로 만드는 로직...... ]
          let tmp = decodeURI(sessionStorage.getItem('user'));
          tmp = tmp.replaceAll('%3A', ':').replaceAll('%2C', ',');
          let userInfo = JSON.parse(tmp);
          userInfo.FirstLogin = 1;
          const encodedUserInfo = encodeURIComponent(JSON.stringify(userInfo));
          sessionStorage.setItem('user', encodedUserInfo);
        }
        if (!this.birthdayResult) {
          this.SET_ALERT_VALUE({ desc: this._json.modal.infoRegi.infoChangeFail });
        }
        return;
      } else {
        // 이메일 유효성 검사
        if (this.email === '') {
          await this.SET_ALERT_VALUE({
            desc: this._json.modal.infoRegi.enterEmail,
          });
          return;
        }
        let res = CHECK_HANGLE(this.email);
        if (res) {
          await this.SET_ALERT_VALUE({
            desc: this._json.modal.infoRegi.noKor,
            btnName: this._json.modal.infoRegi.confirm,
          });
          return;
        }

        // 생년월일, 이메일 모두 등록
        await this.confirmBirthday();
        await this.confirmEmail();
        if (this.birthdayResult && this.emailResult) {
          await this.SET_MODAL_OPEN('informationRegist');
          await this.SET_ALERT_VALUE({
            desc: this._json.modal.infoRegi.infoChangeSuccess,
          });

          // [ FirstLogin 을 임의로 1로 만드는 로직...... ]
          let tmp = decodeURI(sessionStorage.getItem('user'));
          tmp = tmp.replaceAll('%3A', ':').replaceAll('%2C', ',');
          let userInfo = JSON.parse(tmp);
          userInfo.FirstLogin = 1;
          const encodedUserInfo = encodeURIComponent(JSON.stringify(userInfo));
          sessionStorage.setItem('user', encodedUserInfo);

          return;
        }

        if (!this.birthdayResult && this.emailResult) {
          await this.SET_ALERT_VALUE({
            desc: this._json.modal.infoRegi.infoChangeFail_birth,
          });
          return;
        }

        if (this.birthdayResult && !this.emailResult) {
          await this.SET_ALERT_VALUE({
            desc: this._json.modal.infoRegi.infoChangeFail_email,
          });
          return;
        }

        if (!this.birthdayResult && !this.emailResult) {
          await this.SET_ALERT_VALUE({
            desc: this._json.modal.infoRegi.infoChangeFail_info,
          });
          return;
        }
      }
    },
    // 이메일 등록
    async confirmEmail() {
      try {
        const emailData = this.email;
        // const emailData = this.email + '@tilon.com';

        const { data } = await updateEmailApi({ Email: emailData });

        switch (data.status) {
          case '0000':
          case '5000':
            // 성공
            this.emailResult = true;
            break;
          case '0001':
          case '5001':
            // 실패
            this.emailResult = false;
            break;
          default:
            // 실패
            this.emailResult = false;
            break;
        }
      } catch (e) {
        console.error(e);
      }
    },
    // 생일 등록
    async confirmBirthday() {
      try {
        const { data } = await updateBirthdayApi({ Birthday: this.birthday });

        switch (data.status) {
          case '0000':
          case '5000':
            // 성공
            this.birthdayResult = true;
            return;
          case '0001':
          case '5001':
            // 실패
            this.birthdayResult = false;
            return;
          default:
            // 실패
            this.birthdayResult = false;
            return;
        }
      } catch (e) {
        console.error(e);
      }
    },
    setFocus() {
      this.$refs.submitBtn.$el.focus();
    },
  },
};
</script>

<style module>
.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.emailWrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.625rem;
}

.buttonWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.625rem;
}

.buttonWrap button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 106px;
  height: 32px;
  border: 1px solid #d4d4d4;
  border-radius: 2px;
  background-color: #f6f6f6;
  font-size: 0.8rem;
  color: #86868b;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.buttonWrap button:hover {
  background-color: #dfdfdf;
  color: #4c4c4d;
}

.buttonWrap button:disabled {
  cursor: not-allowed;
}

.buttonWrap strong {
  font-size: 0.85rem;
}

.buttonWrap input {
  display: none;
}

.buttonWrap input:checked + .checkCustom {
  background: url('@/assets/imgs/icon_checkbox_checked.png') no-repeat;
}
.buttonWrap input:checked ~ .checkText {
  color: var(--login-active-checkText);
}

.checkCustom {
  display: block;
  width: 20px;
  height: 20px;
  border: 1px gray;
  /*border: var(--login-checkbox-border-width) solid var(--login-checkbox-border);*/
  border-radius: 2px;
  background: url('@/assets/imgs/icon_checkbox_none.png') no-repeat;
  cursor: pointer;
}

.checkCustom:hover {
  background: url('@/assets/imgs/icon_checkbox_hover.png') no-repeat;
}

.checkText {
  display: flex;
  align-items: center;
  margin-left: 6px;
  color: #86868b;
  font-size: 0.8rem;
  transition: all 0.3s ease-in-out;
}
</style>
