<template>
  <footer :class="$style.footer">
    <TheContainer padding="0 4px">
      <div :class="$style.footerWrap">
        <div
          style="
            width: 100%;
            height: 100%;
            text-align: center;
            line-height: 70px;
            color: var(--footer-desc-color);
          "
        >
          <p>© TILON INC. ALL RIGHT RESERVED</p>
        </div>
      </div>
    </TheContainer>
  </footer>
</template>

<script>
export default {};
</script>

<style module>
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background-color: var(--footer-bg-color);
  z-index: 100;
}
.footerWrap {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  /*justify-content: center;*/
  height: 70px;
}
.footerLogo {
  background-image: url('@/assets/imgs/footer_logo.png');
  width: 130px;
  height: 41px;
  background-size: cover;
}
.footerDescArea {
  display: flex;
  gap: 0.5rem;
  align-content: center;
  height: 1rem;
}
.footerDescArea strong {
  color: var(--primary-color);
  font-weight: 700;
  letter-spacing: -0.24px;
}
.footerDesc {
  display: flex;
  /*gap: 0.5rem;*/
  column-gap: 0.438rem;
  align-content: center;
  color: #86868b;
  font-size: 0.875rem;
  padding-top: calc(1rem - 0.875rem);
}

.footerDesc > li {
  line-height: 0.85rem;
}

.footerDesc li:nth-child(2n-1) {
  color: var(--footer-desc-color);
  font-weight: 500;
}
.footerDesc li:nth-child(2n-1):after {
  content: '|';
  display: inline-block;
  color: #86868b;
  margin-left: 0.5rem;
}
.footerDesc li:nth-child(2n) {
  margin-right: 0.3rem;
}
.footerDesc span {
  color: var(--primary-color);
  font-weight: 500;
}
</style>
