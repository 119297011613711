var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.theCloudContainer},[(_vm.vdNameList.length > 0)?_c('article',{class:_vm.$style.vdContainer2},[_c('div',{class:_vm.$style.vdControlBox},[(_vm.vdNameList.length !== 0)?_c('div',{class:_vm.$style.carouselWrap},[_c('ul',{class:_vm.$style.vdList},[_vm._l((_vm.visibleList),function(list,idx){return [_c('li',{key:idx,class:_vm.activeIdx === idx ? _vm.$style.active : ''},[_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.selectVdName(list, idx)}}},[_vm._v(" "+_vm._s(list?.dedicateFlag === 1 ? list?.VDName : list?.VMGroupName)+" ")])])]})],2)]):_vm._e(),_c('div',{class:_vm.$style.vdInformationBox},[(_vm.vdNameList.length > 3)?_c('button',{class:[
            _vm.$style.carouselBtn,
            _vm.$style.prev,
            _vm.realIdx === 0 ? _vm.$style.disableCarouselBtn : '',
          ],attrs:{"type":"button","disabled":_vm.realIdx === 0},on:{"click":function($event){return _vm.prevClick()}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'caret-left'],"size":"2xl"}})],1):_vm._e(),_c('p',[_c('span',{class:[_vm.$style.vdInformationText, _vm.$style.pcStatus]},[_vm._v(" "+_vm._s(_vm._json.home.cloud.pcStatus)),_c('strong',[_vm._v(_vm._s(_vm.isVMState))]),_vm._v(_vm._s(_vm._json.home.cloud.pcStatusDesc)+" ")]),_c('span',{class:_vm.$style.vdInformationText},[_vm._v(" "+_vm._s(_vm.isExpirationText)+" : "+_vm._s(_vm.isExpirationDate)+" ")])]),(_vm.vdNameList.length > 3)?_c('button',{class:[
            _vm.$style.carouselBtn,
            _vm.$style.next,
            _vm.realIdx === _vm.vdNameList.length - 1 ? _vm.$style.disableCarouselBtn : '',
          ],attrs:{"type":"button","disabled":_vm.realIdx === _vm.vdNameList.length - 1},on:{"click":function($event){return _vm.nextClick()}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'caret-right'],"size":"2xl"}})],1):_vm._e()])]),_c('div',{class:_vm.$style.vdBox},[_c('button',{class:_vm.$style.vdBtn,attrs:{"type":"button","disabled":_vm.isVdBtnDisabled},on:{"click":function($event){return _vm.getVmId(_vm.selectedVd)}}},[_c('div',{class:_vm.$style.vdIcon}),_c('h3',{class:_vm.$style.vdName},[_vm._v(_vm._s(_vm.isVMName))])]),(_vm.selectedVd.dedicateFlag === 1)?_c('button',{class:_vm.$style.vdRestartBtn,attrs:{"type":"button","disabled":_vm.isVdRestartBtnDisabled},on:{"click":function($event){_vm.SET_ALERTCONFIRM_VALUE({
            desc: this._json.home.cloud.wouldRestart,
            func: () => {
              _vm.restartVd(_vm.selectedVd.VDName);
            },
          })}}},[_c('span',{class:_vm.$style.vdRestartIcon})]):_vm._e()]),_c('div',{class:_vm.$style.vdNoticeBox,on:{"mouseover":_vm.mouseOn,"mouseout":_vm.mouseOut}},[_c('strong',{style:({ padding: _vm.language === 'JP' ? '0 12px 0 14px' : '' })},[_vm._v(_vm._s(_vm._json.home.cloud.notice))]),_c('div',{class:_vm.$style.vdNoticeMarquee},[_c('marquee',{ref:"marquee",style:(`color:${_vm.returnContentHexCode}`)},[_vm._v(" "+_vm._s(_vm.selectedVd?.Content ? _vm.selectedVd?.Content : _vm._json.home.cloud.welcome)+" ")])],1)])]):_vm._e(),(_vm.vdNameList.length === 0 && _vm.vdProgressData[0]?.Req_Flag !== undefined)?_c('TheVdProgress'):_vm._e(),(_vm.vdNameList.length === 0 && _vm.vdProgressData.length === 0)?_c('TheVdApplication',{style:({ height: _vm.language === 'EN' ? '250px' : '' })}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }