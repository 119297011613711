<template>
  <TheModal
    height="auto"
    :title="_json.modal.alertConfirm.notice"
    name="alertConfirm"
    @isSubmit="[modalList.alertConfirm.confirmFunc(), modalClose()]"
  >
    <template #content>
      <p v-html="modalList.alertConfirm?.desc" />
    </template>
    <template #btn>
      <TheButton width="134px" type="submit" ref="submitBtn">
        {{ modalList.alertConfirm?.btnName }}
      </TheButton>
      <TheButton width="134px" btnStyle="warning" @isClick="modalClose">
        {{ _json.modal.alertConfirm.close }}
      </TheButton>
    </template>
  </TheModal>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  computed: {
    ...mapState('modal', ['modalList']),
  },
  mounted() {
    this.setFocus();
  },
  methods: {
    ...mapMutations('modal', ['SET_MODAL_OPEN']),
    modalClose() {
      this.SET_MODAL_OPEN('alertConfirm');
    },
    setFocus() {
      this.$refs.submitBtn.$el.focus();
    },
  },
};
</script>
