import { instance } from '@/api';

// FAQ
export const fetchFaqListApi = data => {
  return instance.post('/user/faq/list', data);
};

export const getFaqPaginationApi = data => {
  return instance.post('/user/faq/list/paging', data);
};

export const openFaqContentApi = data => {
  return instance.post('/user/faq/content', data);
};

// Notice
export const fetchNoticeListApi = data => {
  return instance.post('/user/notice/list', data);
};

export const getNoticePaginationApi = data => {
  return instance.post('/user/notice/list/paging', data);
};

export const openNoticeContentApi = data => {
  return instance.post('/user/notice/content', data);
};

// user info change
// 비밀번호 정책 받아오기
export const getUserPwdPolicyApi = data => {
  return instance.post('/user/pwdpolicyWithUser', data);
};
// 비밀번호 변경하기
export const changeUserPwd = data => {
  return instance.post('/user/pwdchange', data);
};
// email 변경을 위한 인증번호 발송
export const secureCodeApi = data => {
  return instance.post('/user/updateinfo/getSecureCode', data);
};

// QnA 1:1문의
export const addQnaApi = data => {
  return instance.post('/user/inquiry/new', data);
};

export const deleteQnaApi = data => {
  return instance.post('/user/inquiry/remove', data);
};

export const fetchQnaApi = data => {
  return instance.post('/user/inquiry/list', data);
};

export const getQnaPaginationApi = data => {
  return instance.post('/user/inquiry/list/paging', data);
};

export const checkQnaApi = data => {
  return instance.post('/user/inquiry/read', data);
};
export const uploadFileApi = data => {
  return instance.post('/user/file/upload', data);
};

// changeUserInfo
export const updateUserBirthdayApi = data => {
  return instance.post('/user/updateinfo/birthday', data);
};
export const updateUserEmailApi = data => {
  return instance.post('/user/updateinfo/email', data);
};
export const resetPwdApi = data => {
  return instance.post('/user/setPassword', data);
};

// 프로그램 다운로드
export const getClientDescApi = () => {
  return instance.post('/user/clientInstall/list');
};
