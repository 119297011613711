<template>
  <TheModal
    :title="_json.modal.authentication.userAuth"
    :desc="_json.modal.authentication.emailAuth"
    height="auto"
    width="432px"
    name="authentication"
    @isSubmit="sendSecretKey"
  >
    <template #content>
      <div :class="$style.content">
        <TheInput
          :placeholder="_json.modal.authentication.enterId"
          :value="id"
          name="id"
          @isInput="setUserInfo($event.target)"
        />
        <TheInput
          :placeholder="_json.modal.authentication.enterName"
          :value="name"
          name="name"
          @isInput="setUserInfo($event.target)"
        />
        <TheInput
          :placeholder="_json.modal.authentication.birthday"
          :value="birthday"
          name="birthday"
          @isInput="setUserInfo($event.target)"
        />
      </div>
    </template>
    <template #btn>
      <TheButton
        width="134px"
        :disabled="id === '' || name === '' || birthday === ''"
        type="submit"
        ref="submitBtn"
      >
        {{ _json.modal.authentication.confirm }}
      </TheButton>
      <TheButton width="134px" @isClick="modalClose" btnStyle="warning">
        {{ _json.modal.authentication.close }}
      </TheButton>
    </template>
  </TheModal>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { sendSecretKeyApi } from '@/api/module/mainApi';
import { CHECK_NUMBER, CHECK_BIRTHDAY_RANGE, CHECK_HANGLE } from '@/utils/pwdReg';

export default {
  data() {
    return {
      id: '',
      name: '',
      birthday: '',
    };
  },
  computed: {
    ...mapState('modal', ['modalList']),
  },
  mounted() {
    this.setFocus();
  },
  methods: {
    ...mapMutations('modal', ['SET_MODAL_OPEN', 'SET_ALERT_VALUE', 'SET_TIMER_MODAL']),
    setUserInfo(e) {
      if (e.value === '') {
        return;
      }
      this[e.name] = e.value;
    },
    modalClose() {
      this.SET_MODAL_OPEN('authentication');
    },
    // 인증번호 전송
    async sendSecretKey() {
      try {
        // [ 아이디 양식 체크 로직 ]
        let res = CHECK_HANGLE(this.id);
        if (res) {
          await this.SET_ALERT_VALUE({
            desc: this._json.modal.authentication.errorId,
            btnName: this._json.modal.authentication.confirm,
          });
          return;
        }
        // [ 생년월일 체크 로직 ]
        res = CHECK_NUMBER(this.birthday);
        if (!res) {
          await this.SET_ALERT_VALUE({
            desc: this._json.modal.authentication.errorBirthday,
            btnName: this._json.modal.authentication.confirm,
          });
          return;
        }
        res = CHECK_BIRTHDAY_RANGE(this.birthday);
        if (!res.result) {
          await this.SET_ALERT_VALUE({
            desc: res.text,
            btnName: this._json.modal.authentication.confirm,
          });
          return;
        }

        const { data } = await sendSecretKeyApi({
          UserID: this.id,
          UserName: this.name,
          Birthday: this.birthday,
          Email: '',
          UserFlag: 'True',
        });
        switch (data.status) {
          case '0000':
          case '5000':
            // 성공
            await this.SET_TIMER_MODAL({ UserID: this.id });
            break;
          case '0001':
          case '5001':
            // 실패
            await this.SET_ALERT_VALUE({
              desc: this._json.modal.authentication.errorResetPwd,
              btnName: this._json.modal.authentication.confirm,
            });
            break;
          case '0002':
          case '5002':
            // 실패
            await this.SET_ALERT_VALUE({
              desc: this._json.modal.authentication.errorEmail,
              btnName: this._json.modal.authentication.confirm,
            });
            break;
          case '0003':
          case '5003':
            //이름 미등록으로 사용자 아이디 인증 불가
            await this.SET_ALERT_VALUE({
              desc: this._json.modal.authentication.notExistId,
              btnName: this._json.modal.authentication.confirm,
            });
            break;
          case '0004':
          case '5004':
            // 실패
            await this.SET_ALERT_VALUE({
              desc: this._json.modal.authentication.wrongEmail,
              btnName: this._json.modal.authentication.confirm,
            });
            break;
          case '0005':
          case '5005':
            await this.SET_ALERT_VALUE({
              desc: this._json.modal.authentication.notMatchName,
              btnName: this._json.modal.authentication.confirm,
            });
            return;
          case '0006':
          case '5006':
            await this.SET_ALERT_VALUE({
              desc: this._json.modal.authentication.notMatchBirthday,
              btnName: this._json.modal.authentication.confirm,
            });
            return;
          case '0007':
          case '5007':
            await this.SET_ALERT_VALUE({
              desc: this._json.modal.authentication.notMatchName_birthday,
              btnName: this._json.modal.authentication.confirm,
            });
            return;
          case '0008':
          case '5008':
            //생년월일 미등록으로 사용자 아이디 인증 불가
            await this.SET_ALERT_VALUE({
              desc: this._json.modal.authentication.notExistBirth,
              btnName: this._json.modal.authentication.confirm,
            });
            break;
          default:
            this.SET_ALERT_VALUE({
              desc: this._json.modal.authentication.wrongCourtNet,
              btnName: this._json.modal.authentication.confirm,
            });
            break;
        }
      } catch (e) {
        console.error(e);
      }
    },
    setFocus() {
      this.$refs.submitBtn.$el.focus();
    },
  },
};
</script>

<style module>
.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
}

.notice {
  margin: 0.75rem 0 0.625rem;
  text-align: center;
  line-height: 24px;
}
</style>
