<template>
  <div
    v-if="content"
    :class="$style.noticeModal"
    :style="`top: ${Top}; left: ${Left};`"
    ref="noticeModal"
    :id="`id${index}`"
    @mousedown="handleMouseDown"
    @mouseup="handleMouseUp"
  >
    <div :class="$style.noticeModalHeader">
      <h4 :style="{ color: content?.TitleHexCode }">{{ noticeType }} {{ content?.Subject }}</h4>
      <button @click="closeModal">X</button>
    </div>
    <div :class="$style.noticeModalContent" ref="noticeModalContent" @mousedown="contentMouseDown">
      <!--첨부파일 색션-->
      <div :class="$style.contentP">
        <div v-if="content?.FilePath2 !== null && content?.FilePath2 !== undefined">
          {{ _json.common.noticeModal.attachment }}
          <a
            @click="rememberDl(2)"
            :class="[$style.fileDl, fileDownload2 ? $style.active : '']"
            :href="`${url}${content.FilePath2}`"
            download
          >
            {{ content.FileName2 }}
          </a>
        </div>
        <div v-if="content?.FilePath3 !== null && content?.FilePath3 !== undefined">
          {{ _json.common.noticeModal.attachment }}
          <a
            @click="rememberDl(3)"
            :class="[$style.fileDl, fileDownload3 ? $style.active : '']"
            :href="`${url}${content.FilePath3}`"
            download
          >
            {{ content.FileName3 }}
          </a>
        </div>
        <div v-if="content?.FilePath4 !== null && content?.FilePath4 !== undefined">
          {{ _json.common.noticeModal.attachment }}
          <a
            @click="rememberDl(3)"
            :class="[$style.fileDl, fileDownload4 ? $style.active : '']"
            :href="`${url}${content.FilePath4}`"
            download
          >
            {{ content.FileName4 }}
          </a>
        </div>
      </div>
      <div v-if="returnStraightDiv" :class="$style.straight" />
      <!--이미지 및 컨텐츠 색션-->
      <div :class="$style.contentP">
        <!-- <p v-html="content?.Content" />-->
        <p v-text="returnContent" style="white-space: pre-wrap" />

        <img
          v-if="content?.FilePath1 !== null && content?.FilePath1 !== undefined"
          :class="$style.img"
          :src="`${url}${content?.FilePath1}`"
          :alt="_json.common.noticeModal.imgPreview"
        />
      </div>
    </div>
    <div :class="$style.noticeModalFooter">
      <div>
        <button @click="noShowOne">[{{ _json.common.noticeModal.stopToday }}]</button>
        <button @click="noShowSeven">[{{ _json.common.noticeModal.stopWeek }}]</button>
      </div>
      <button @click="closeModal">[{{ _json.common.noticeModal.close }}]</button>
    </div>
  </div>
</template>

<script>
import { setCookie } from '@/utils/cookie';
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'TheNoticeModal',
  data() {
    return {
      fileDownload2: false,
      fileDownload3: false,
      fileDownload4: false,
      url: '',
      contentClick: false,
    };
  },
  props: {
    content: {
      type: Object,
    },
    Top: {
      type: String,
      default: '0',
    },
    Left: {
      type: String,
      default: '0',
    },
    SeqNumber: {
      type: String,
    },
    localStorageName: {
      type: [String, Number],
    },
    index: {
      type: Number,
    },
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('modal', ['noticeModalList']),
    returnStraightDiv() {
      let file2 = this.content?.FilePath2 !== null && this.content?.FilePath2 !== undefined;
      let file3 = this.content?.FilePath3 !== null && this.content?.FilePath3 !== undefined;
      let file4 = this.content?.FilePath4 !== null && this.content?.FilePath4 !== undefined;
      return file2 || file3 || file4;
    },
    returnContent() {
      // let str = '';
      // if (this.content?.Content) {
      //   str = this.content.Content.replaceAll(/(\n|\r\n)/g, '<br>');
      //   return str;
      // }
      // return str;
      let txt = document.createElement('textarea');
      txt.innerHTML = this.content.Content;

      return txt.value;
    },
    noticeType() {
      switch (this.content?.NoticeType) {
        case 0:
          return '';
        case 1:
          return `[ ${this._json.common.noticeModal.general} ]`;
        case 3:
          return `[ ${this._json.common.noticeModal.urgent} ]`;
        default:
          return '';
      }
    },
  },
  async mounted() {
    await this.checkDl();
    this.url = `${window.location.protocol}//${window.location.hostname}/userapi`;
  },
  methods: {
    ...mapMutations('modal', ['DELETE_NOTICEMODAL_DATA']),
    // 팝업창 닫기
    async closeModal() {
      await this.DELETE_NOTICEMODAL_DATA(this.SeqNumber);

      this.$refs.noticeModal.style.display = 'none';
    },
    // 하루 안보기
    async noShowOne() {
      await setCookie(
        `dontShow${this.SeqNumber}${this.user.UserAlias ? this.user.UserAlias : ''}`,
        1,
      );
      this.$refs.noticeModal.style.display = 'none';
    },
    // 일주일 안보기
    async noShowSeven() {
      await setCookie(
        `dontShow${this.SeqNumber}${this.user.UserAlias ? this.user.UserAlias : ''}`,
        7,
      );
      this.$refs.noticeModal.style.display = 'none';
    },
    // ================== [ 모달 클릭시 동작 ] ==================
    handleMouseDown(e) {
      if (this.contentClick) {
        this.contentClick = false;
        return;
      }

      const targetModal = e.currentTarget;

      // 랜더링 당시 돔의 넓이값 구한후 css에 넣기
      if (targetModal) {
        targetModal.style.width = `${targetModal.offsetWidth}px`;
      }

      // 클릭한 모달 zindex 세팅
      targetModal.style.zIndex = '500';
      this.noticeModalList.forEach(seq => {
        if (this.index !== Number(seq)) {
          const otherModal = document.querySelector(`#id${Number(seq)}`);
          if (otherModal) {
            otherModal.style.zIndex = '400';
          }
        }
      });

      // 모달 현재 위치값
      // 브라우저에서 사용자에게 웹페이지가 보여지는 영역을 기준으로 좌표를 표시
      this.modalTop = e.clientX - targetModal.offsetLeft;
      this.modalLeft = e.clientY - targetModal.offsetTop;
      document.addEventListener('mousemove', this.handleDragg);
    },
    // ================== [ 모달 드래그시 동작 ] ==================
    handleDragg(e) {
      const targetModal = this.$refs.noticeModal;

      // 이동된 위치값
      let newX = e.clientX - this.modalTop;
      let newY = e.clientY - this.modalLeft;

      // // x축 제한
      // if (newX < 0) {
      //   newX = 0;
      //   document.removeEventListener('mousemove', this.handleDragg);
      // } else if (newX > 1800) {
      //   newX = 1800;
      //   document.removeEventListener('mousemove', this.handleDragg);
      // }
      //
      // // Y축 제한
      // if (newY < 200) {
      //   newY = 205;
      //   document.removeEventListener('mousemove', this.handleDragg);
      // } else if (newY > 1000) {
      //   newY = 1000;
      //   document.removeEventListener('mousemove', this.handleDragg);
      // }

      targetModal.style.left = newX + 'px';
      targetModal.style.top = newY + 'px';
    },
    // ================== [ 모달 드래그시 끝날시 동작 ] ==================
    handleMouseUp() {
      document.removeEventListener('mousemove', this.handleDragg);
    },
    contentMouseDown() {
      this.contentClick = true;
    },
    // ================== [ 첨부파일 다운로드 ] ==================
    checkDl() {
      if (localStorage.getItem(this.localStorageName) !== null) {
        for (let i = 2; i <= 4; i++) {
          let arr = this.checkLocalStorage(i);
          if (arr[0] === this.content[`SID${i}`]) {
            this[`fileDownload${i}`] = true;
          }
        }
      }
    },
    rememberDl(idx) {
      this[`fileDownload${idx}`] = true;
      let check = localStorage.getItem(this.localStorageName);
      if (check !== null) {
        let arr = this.checkLocalStorage(idx);
        if (arr[0]) {
          return;
        }
        let SID = localStorage.getItem(this.localStorageName);
        SID = SID + ',' + this.content[`SID${idx}`];
        localStorage.setItem(this.localStorageName, SID);
        return;
      }
      localStorage.setItem(this.localStorageName, this.content[`SID${idx}`]);
    },
    checkLocalStorage(idx) {
      let SID = localStorage.getItem(this.localStorageName);
      let arr = SID.split(',');
      return arr.filter(SID => SID === this.content[`SID${idx}`]);
    },
  },
};
</script>

<style module>
.noticeModal {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 500px;
  /*max-width: 1900px;*/
  max-width: 778px;
  padding: 0 19px;
  background-color: #3a5680;
  color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  z-index: 400;
}

.noticeModalHeader {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
}

.noticeModalHeader h4 {
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.noticeModalHeader button {
  position: absolute;
  right: 0;
  padding: 0;
  font-weight: 700;
}

.noticeModalContent {
  background-color: #fff;
  min-height: 200px;
  color: #000;
}

.noticeModalFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}

.noticeModalFooter div {
  display: flex;
  column-gap: 10px;
}

.noticeModalFooter button {
  padding: 0;
  font-weight: 700;
}

/* 내용 */
.fileDl {
  display: inline-block;
  color: #1c79c8;
  text-decoration: underline;
  margin-bottom: 0.5rem;
}
.fileDl:hover {
  color: #542d95;
}
.fileDl.active {
  color: #542d95;
}
.straight {
  width: 100%;
  border-bottom: 2px solid var(--table-tr-border-color);
  margin-bottom: 1rem;
}

.contentP {
  padding: 5px 10px;

  vertical-align: middle;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-all;
  white-space: pre-wrap;
}
.contentP p {
  max-width: 748px;
}

.img {
  display: block;
  width: 100%;
  background-size: contain;
}
</style>
