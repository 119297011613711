<template>
  <input
    :type="type"
    :required="required"
    :class="[$style.input, disabled ? $style.disabled : '']"
    :placeholder="disabled ? disabledPlaceholder : placeholder"
    @change="$emit('isChange', $event)"
    @input="$emit('isInput', $event)"
    @blur="$emit('isBlur', $event)"
    :name="name"
    :value="value"
    :disabled="disabled"
    :maxlength="maxLength"
  />
</template>

<script>
export default {
  props: {
    placeholder: String,
    name: String,
    value: [String, Number],
    required: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'text',
      validator: function (val) {
        return ['text', 'date', 'time', 'password', 'datetime-local'].includes(val);
      },
    },
    disabled: {
      type: [String, Boolean],
      default: false,
    },
    disabledPlaceholder: {
      type: String,
      default: '',
    },
    maxLength: {
      type: [String, Number, Boolean],
      default: false,
    },
  },
};
</script>

<style module>
.input {
  width: 100%;
  background-color: var(--input-bg-color);
  border-radius: 4px;
  border: 1px solid var(--input-border-color);
  display: inline-block;
  font-size: 1rem;
  padding: 1rem;
  color: var(--table-td-color);
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: var(--table-td-color) !important;
  -webkit-box-shadow: 0 0 0 1000px var(--input-bg-color) inset;
}
.input::placeholder {
  color: var(--input-placeholder-color);
  font-size: 0.875rem;
}
.disabled {
  cursor: not-allowed;
  /*border-color: var(--list-card-warring-box-color);*/
  background-color: var(--input-disabled-bg);
}
.disabled::placeholder {
  color: var(--list-card-warring-box-color);
}
</style>
