<template>
  <transition name="slide">
    <tr :key="isKey" :class="$style.accordionTr" v-if="showAccordion">
      <td
        :colspan="colspan"
        style="
          padding: 1.2rem 1.8rem;
          text-align: left;
          cursor: default;
          line-height: 2rem;
          word-wrap: break-word;
          overflow-wrap: break-word;
          word-break: break-all;
        "
      >
        <!--이미지 및 컨텐츠 색션-->
        <div>
          <div v-if="content.Content" :class="$style.flexWrap">
            <strong :class="$style.iconQ">Q</strong>
            <p
              v-text="returnContent"
              style="word-wrap: break-word; word-break: break-all; white-space: pre-wrap"
            />
          </div>
          <img
            v-if="returnFile1"
            :class="$style.img"
            :src="`${url}${content.FilePath1}`"
            :alt="_json.common.qnaAccordionTr.imgPreview"
          />
          <div v-if="content.Response" :class="$style.flexWrap" style="margin-top: 32px">
            <strong :class="$style.iconA">A</strong>
            <p v-text="returnResponse" style="word-wrap: break-word; word-break: break-all" />
          </div>
        </div>
        <slot></slot>
      </td>
    </tr>
  </transition>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      url: '',
    };
  },
  props: {
    isKey: {
      type: [String, Number],
    },
    colspan: String,
    showAccordion: { type: Boolean, default: false },
    content: Object,
    localStorageName: {
      type: [String, Number],
    },
  },
  computed: {
    returnContent() {
      // let str = '';
      // if (this.content?.Content) {
      //   str = this.content.Content.replaceAll(/(\n|\r\n)/g, '<br>');
      //   return str;
      // }
      let txt = document.createElement('textarea');
      txt.innerHTML = this.content.Content;

      return txt.value;
    },
    returnResponse() {
      // let str = '';
      // if (this.content?.Response) {
      //   str = this.content.Response.replaceAll(/(\n|\r\n)/g, '<br>');
      //   return str;
      // }

      let txt = document.createElement('textarea');
      txt.innerHTML = this.content.Response;

      return txt.value;
    },

    returnFile1() {
      let file1 = this.content?.FileName !== null && this.content?.FileName !== undefined;
      return file1;
    },
  },
  mounted() {
    this.url = `${window.location.protocol}//${window.location.hostname}/userapi`;
  },
};
</script>

<style module>
.accordionTr {
  background-color: var(--accordion-bg-color) !important;
}
.accordionTr:hover {
  background-color: var(--accordion-bg-color) !important;
}
.accordionTr td {
  vertical-align: middle;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-all;
  white-space: pre-wrap;
}
.accordionTr td p {
  max-width: 882px;
}

/* 내용 */
.straight {
  width: 100%;
  border-bottom: 2px solid var(--table-tr-border-color);
  margin-bottom: 1rem;
}
.img {
  max-width: calc(100% - 65px);
  background-size: contain;
  margin-left: 65px;
}

.flexWrap {
  display: flex;
  /*align-items: center;*/
  column-gap: 16px;
}
.iconQ {
  /*margin-top: 10px;*/
  height: 40px;
  width: 25px;
  font-family: Meiryo, 'Meiryo UI', 'Spoqa Han Sans Neo', 'sans-serif';
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -0.48px;
  color: #005bc1;
  text-align: center;
  margin-right: 1.5rem;
}

.iconA {
  margin-top: 3px;
  width: 26px;
  height: 40px;
  font-family: Meiryo, 'Meiryo UI', 'Spoqa Han Sans Neo', 'sans-serif';
  font-size: 32px;
  font-weight: bold;
  letter-spacing: -0.48px;
  color: #fb1e66;
  text-align: center;
  margin-right: 1.5rem;
}
</style>

<style scoped>
/*테이블 슬라이드 애니메이션*/

.slide-enter-active {
  transition: all 0.5s;
}

.slide-enter {
  transform: translateY(-30px);
  opacity: 0;
}
</style>
