import { instance } from '@/api';

// login
export const loginApi = data => {
  return instance.post('/user/login', data, {
    headers: {
      'Content-Type': 'application/json',
      Accept: '*/*',
    },
  });
};

// logout
export const logoutApi = data => {
  return instance.post('/user/logout', data, {
    params: data,
  });
};
