<template>
  <div :class="$style.theCloudContainer">
    <article v-if="vdNameList.length > 0" :class="$style.vdContainer2">
      <div :class="$style.vdControlBox">
        <div :class="$style.carouselWrap" v-if="vdNameList.length !== 0">
          <ul :class="$style.vdList">
            <template v-for="(list, idx) in visibleList">
              <li :key="idx" :class="activeIdx === idx ? $style.active : ''">
                <button type="button" @click="selectVdName(list, idx)">
                  {{ list?.dedicateFlag === 1 ? list?.VDName : list?.VMGroupName }}
                </button>
              </li>
            </template>
          </ul>
        </div>
        <div :class="$style.vdInformationBox">
          <button
            v-if="vdNameList.length > 3"
            :class="[
              $style.carouselBtn,
              $style.prev,
              realIdx === 0 ? $style.disableCarouselBtn : '',
            ]"
            @click="prevClick()"
            type="button"
            :disabled="realIdx === 0"
          >
            <font-awesome-icon :icon="['fas', 'caret-left']" size="2xl" />
          </button>
          <p>
            <span :class="[$style.vdInformationText, $style.pcStatus]">
              {{ _json.home.cloud.pcStatus }}<strong>{{ isVMState }}</strong
              >{{ _json.home.cloud.pcStatusDesc }}
            </span>
            <!--<span :class="$style.vdInformationText">남은 기간: {{ isExpirationDate }}</span>-->
            <span :class="$style.vdInformationText">
              {{ isExpirationText }} : {{ isExpirationDate }}
            </span>
          </p>
          <button
            :class="[
              $style.carouselBtn,
              $style.next,
              realIdx === vdNameList.length - 1 ? $style.disableCarouselBtn : '',
            ]"
            @click="nextClick()"
            v-if="vdNameList.length > 3"
            type="button"
            :disabled="realIdx === vdNameList.length - 1"
          >
            <font-awesome-icon :icon="['fas', 'caret-right']" size="2xl" />
          </button>
        </div>
      </div>
      <div :class="$style.vdBox">
        <button
          :class="$style.vdBtn"
          type="button"
          :disabled="isVdBtnDisabled"
          @click="getVmId(selectedVd)"
        >
          <div :class="$style.vdIcon" />
          <h3 :class="$style.vdName">{{ isVMName }}</h3>
        </button>
        <button
          v-if="selectedVd.dedicateFlag === 1"
          :class="$style.vdRestartBtn"
          type="button"
          :disabled="isVdRestartBtnDisabled"
          @click="
            SET_ALERTCONFIRM_VALUE({
              desc: this._json.home.cloud.wouldRestart,
              func: () => {
                restartVd(selectedVd.VDName);
              },
            })
          "
        >
          <span :class="$style.vdRestartIcon"></span>
        </button>
      </div>
      <div :class="$style.vdNoticeBox" @mouseover="mouseOn" @mouseout="mouseOut">
        <strong :style="{ padding: language === 'JP' ? '0 12px 0 14px' : '' }">{{
          _json.home.cloud.notice
        }}</strong>
        <div :class="$style.vdNoticeMarquee">
          <marquee ref="marquee" :style="`color:${returnContentHexCode}`">
            {{ selectedVd?.Content ? selectedVd?.Content : _json.home.cloud.welcome }}
          </marquee>
        </div>
      </div>
    </article>
    <TheVdProgress v-if="vdNameList.length === 0 && vdProgressData[0]?.Req_Flag !== undefined" />
    <TheVdApplication
      :style="{ height: language === 'EN' ? '250px' : '' }"
      v-if="vdNameList.length === 0 && vdProgressData.length === 0"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { objectAscending } from '@/utils/sort';

export default {
  name: 'TheCloud',
  data() {
    return {
      isVdBtnDisabled: false,
      isVdRestartBtnDisabled: false,
      startIdx: 0,
      activeIdx: 0,
      realIdx: 0,
      listShowCnt: 3,
      activeVdName: '',
      ws: null,
    };
  },
  watch: {
    popupInfo: async function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (this.popupInfo?.AllocatedVDFlag) {
          if (this.vdNameList.length === 0) {
            await this.getAllocatedVdList(); // vd 리스트
          }
        }
      }
    },
  },
  async mounted() {
    await this.getAllocatedVdList(); // vd 리스트
    await this.GET_PROGRESS_VD(); // vd 신청상태
    await this.immediatelyRunVd(); // vd 한개일때 바로시작
    this.activeVdName = this.vdNameList[0]?.VDName;
    await this.handleWebSocket();
  },
  destroyed() {
    // this.handleWebSocket('off');
    this.ws.close();
  },
  computed: {
    ...mapState('vd', ['vdProgressData', 'vdNameList', 'selectedVd']),
    ...mapState('modal', ['vdAccessModalData', 'popupInfo']),
    ...mapState('common', ['language']),
    ...mapGetters('common', ['isDarkMode']),
    returnContentHexCode() {
      if (this.isDarkMode) {
        return '#f8f8f8';
      }
      return this.selectedVd?.ContentHexCode ? this.selectedVd?.ContentHexCode : '';
    },
    isVMState() {
      // [1 : 기타, 2 : 동작 중, 3 : 종료, 4 : 종료 중, 6 : 저장, 9 : 일시 중지, 10 : 시작 중, 11 : 재시작]
      switch (Number(this.selectedVd?.VMState)) {
        case 1:
          return this._json.home.cloud.miscellaneous;
        case 2:
          return this._json.home.cloud.running;
        // case 3:
        // case '3':
        // return '중지';
        case 3:
        case 4:
          return this._json.home.cloud.stopped;
        case 6:
          return this._json.home.cloud.save;
        case 9:
          return this._json.home.cloud.pause;
        case 10:
          return this._json.home.cloud.starting;
        case 11:
          return this._json.home.cloud.restart;
        case 1000:
          return this._json.home.cloud.normalGroup;
        default:
          return this._json.home.cloud.abnormal;
      }
    },
    isVMName() {
      if (this.selectedVd === undefined) {
        return;
      }
      const { VMGroupName, VDName, dedicateFlag } = this.selectedVd;
      if (Number(dedicateFlag) === 1) {
        if (!VDName) {
          return '- -';
        }
        return VMGroupName ? `${VMGroupName} - ${VDName}` : VDName;
      }

      if (Number(dedicateFlag) === 2) {
        if (!VMGroupName) {
          return '- -';
        }
        return VMGroupName ? VMGroupName : '';
      }
      return '- -';
    },
    isExpirationDate() {
      return this.selectedVd?.ExpirationDate ? this.selectedVd?.ExpirationDate : '- -';
    },
    isExpirationText() {
      // 만료일 : 외주직원 남은기간: 공무원
      return this.selectedVd?.ExpirationDate
        ? this._json.home.cloud.expirationDate
        : this._json.home.cloud.remainingPeriod;
    },
    // 캐러셀
    visibleList() {
      return this.vdNameList.slice(this.startIdx, this.startIdx + this.listShowCnt);
    },
  },
  methods: {
    ...mapMutations('modal', [
      'SET_ALERTCONFIRM_VALUE',
      'SET_VD_ACCESSMODAL_DATA',
      'SET_MODAL_OPEN',
      'SET_VD_EXPIREDMODAL_DATA',
    ]),
    ...mapMutations('vd', ['SET_VDNAME_LIST', 'SET_SELECTED_VD']),
    ...mapActions('modal', ['FETCH_VD_EXPIRED_MODALDATA', 'FETCH_VD_ACCESS_MODALDATA']),
    ...mapActions('vd', ['GET_ALLOCATED_VD_LIST', 'GET_VM_ID', 'GET_PROGRESS_VD', 'RESTART_VD']),
    handleWebSocket() {
      this.ws = new WebSocket('ws://127.0.0.1:11300');

      this.ws.onopen = () => {
        console.log('WebSocket Connected');
      };

      this.ws.onerror = error => {
        console.error('WebSocket Error', error);
      };

      this.ws.onclose = event => {
        console.log('WebSocket Closed', event);
      };
    },
    // 할당된 VD정보 받아오기
    async getAllocatedVdList() {
      const { output } = await this.GET_ALLOCATED_VD_LIST();
      if (output) {
        objectAscending(output); // 오름차순 정렬
        this.SET_VDNAME_LIST(output);
        await this.setSelectedVd(output[0]);
      }
    },
    // VMID 받아오기(linker에 접속하기 위함)
    async getVmId(selectedVd) {
      if (selectedVd?.VDName === undefined) {
        return;
      }

      // 관리자 접속제한
      if (Number(selectedVd.AccessRestrictionFlag) === 1) {
        await this.FETCH_VD_ACCESS_MODALDATA();
        return;
      }
      // 만료 접속제한
      if (Number(this.vdAccessModalData?.dateExpired) === 2) {
        await this.SET_MODAL_OPEN('vdAccess');
        return;
      }

      this.isVdBtnDisabled = true;
      await this.GET_VM_ID(selectedVd);
      setTimeout(() => {
        this.isVdBtnDisabled = false;
      }, 5000);
    },
    // VD 한대만 할당받은 계정일 경우 VD 바로 시작 -> 로그인 했을때 한번만
    immediatelyRunVd() {
      setTimeout(async () => {
        if (sessionStorage.getItem('VD') !== 'start') {
          if (this.vdNameList.length === 1) {
            sessionStorage.setItem('VD', 'start');
            await this.getVmId(this.selectedVd);
          }
        }
      }, 1000);
    },
    // VD 재실행
    async restartVd(VDName) {
      if (VDName === undefined) {
        return;
      }
      this.isVdRestartBtnDisabled = true;
      await this.RESTART_VD(VDName);
      setTimeout(() => {
        this.isVdRestartBtnDisabled = false;
      }, 5000);
    },
    // 캐러셀
    // vd 이름 클릭
    async selectVdName(list, idx) {
      await this.setSelectedVd(list);
      this.activeIdx = idx;
      this.activeVdName = list.VDName;
      let returnIdx = this.getRealIdx(list);
      this.realIdx = returnIdx;
    },
    getRealIdx(list) {
      for (let i = 0; i < this.vdNameList.length; i++) {
        if (list.VDName === this.vdNameList[i].VDName) {
          return i;
        }
      }
    },
    // btn 클릭
    prevClick() {
      this.realIdx--;
      if (this.activeIdx > 0) {
        this.activeIdx--;
      } else {
        if (this.startIdx >= this.listShowCnt) {
          this.startIdx -= this.listShowCnt;
          this.activeIdx = this.listShowCnt - 1;
        }
      }
      this.setSelectedVd(this.visibleList[this.activeIdx]);
    },
    nextClick() {
      this.realIdx++;
      if (this.activeIdx < this.listShowCnt - 1) {
        this.activeIdx++;
      } else {
        if (this.startIdx + this.listShowCnt < this.vdNameList.length) {
          this.startIdx += this.listShowCnt;
          this.activeIdx = 0;
        }
      }
      this.setSelectedVd(this.visibleList[this.activeIdx]);
    },
    // VD선택 및 만료모달 팝업정보 확인
    async setSelectedVd(data) {
      await this.SET_SELECTED_VD(data);

      if (data?.VDName) {
        await this.FETCH_VD_EXPIRED_MODALDATA(data?.VDName);
      }

      if (!data?.VDName) {
        await this.SET_VD_EXPIREDMODAL_DATA({});
      }
    },
    // marquee
    mouseOn() {
      this.$refs.marquee.stop();
    },
    mouseOut() {
      this.$refs.marquee.start();
    },
  },
};
</script>

<style module>
.theCloudContainer {
  //width: 420px;
}

.vdContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.vdContainer2 {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.vdList {
  flex: 1;
  display: flex;
  column-gap: 6px;
  overflow: hidden;
}

.vdList li {
  flex: 1;
  padding: 4px;
  background-color: var(--vd-cloud-bg);
  border-top: 1px solid var(--vd-cloud-border);
  border-right: 1px solid var(--vd-cloud-border);
  border-left: 1px solid var(--vd-cloud-border);
  font-size: 1rem;
  border-radius: 8px 8px 0 0;
  color: var(--vd-cloud-text);
  overflow: hidden;
  min-width: 100px;
  max-width: 138px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vdList li button {
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vdList .active button {
  color: var(--vdList-active-color);
  font-weight: 900;
}

.vdControlBox {
  width: 100%;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
}

.vdInformationBox {
  display: flex;
  align-items: center;
  width: 100%;
  height: 42px;
  border: 1px solid var(--vd-cloud-border);
  box-shadow: 0 4px 8px #0000001f;
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--vd-cloud-text2);
  overflow: hidden;
}

.vdInformationBox > p {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 26px;
  width: 100%;
  flex: 1;
  background-color: var(--vd-cloud-bg);
}

.vdInformationText {
  position: relative;
  text-align: center;
  color: var(--vd-cloud-span);
}

.pcStatus::after {
  position: absolute;
  content: '|';
  top: 0;
  right: -15px;
  color: var(--vd-cloud-span);
  font-size: 0.9rem;
}

.vdInformationText > strong {
  color: var(--vd-cloud-strong);
  font-weight: 700;
}

.carouselBtn {
  height: 100%;
  background-color: var(--carouselBtn--bg);
  font-size: 14px;
  color: #ffffff;
}
.carouselBtn:hover {
  background-color: var(--carouselBtn-hover-bg);
  font-weight: 600;
}

.carouselBtn.prev {
  padding: 0 4px 0 8px;
}
.carouselBtn.next {
  padding: 0 8px 0 4px;
}

.disableCarouselBtn {
  cursor: default;
  /*background-color: var(--carouselBtn-disable-bg);*/
  /*color: var(--carouselBtn-disable-color);*/
}
.disableCarouselBtn:hover {
  background-color: var(--carouselBtn--bg);
  color: #ffffff;
}

.vdBox {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 18px;
  padding: 25px 24px;
  width: 424px;
  height: 204px;
  background-color: var(--vd-cloud-bg);
  box-shadow: 0 4px 8px #0000001f;
  border-radius: 4px;
}

.vdBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 17px;
}

.vdBtn:disabled {
  opacity: 0.7;
  pointer-events: none;
}

.vdBtn:hover .vdIcon {
  background-image: url('@/assets/imgs/icon_window_hover.png');
}

.vdIcon {
  width: 190px;
  height: 120px;
  background-image: url('@/assets/imgs/icon_window.png');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  transition: 0.3s ease-in-out;
}

.vdName {
  font-size: 1.3rem;
  font-weight: 700;
  color: var(--vd-cloud-text2);
  letter-spacing: -0.35px;
  max-width: 22.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.vdRestartBtn {
  position: absolute;
  top: 12px;
  right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background: linear-gradient(135deg, #d9d9d9, #989898);
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.vdRestartIcon {
  width: 15.3px;
  height: 15.3px;
  background: url('@/assets/imgs/icon_restart.png') no-repeat;
  transition: all 0.5s ease-in-out;
}

.vdRestartBtn:hover {
  background: linear-gradient(#eb5044, #cc261a);
}

.vdRestartBtn:hover .vdRestartIcon {
  transform: rotate(270deg);
}

.vdRestartBtn:disabled {
  opacity: 0.7;
  pointer-events: none;
}

.vdNoticeBox {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
  width: 100%;
  background: var(--vd-cloud-bg);
  border-radius: 4px;
  border: 1px solid var(--vd-cloud-border);
  font-size: 1rem;
  box-shadow: 0 4px 8px #0000001f;
  color: var(--vd-cloud-text3);
}

.vdNoticeBox strong {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px 0 16px;
  font-weight: 700;
  width: 90px;
}

.vdNoticeBox strong::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 1px;
  height: 100%;
  background-color: var(--vd-cloud-text3);
}

.vdNoticeMarquee {
  flex-grow: 1;
  display: flex;
  /*justify-content: center;*/
  align-items: center;
  padding: 0 16px;
  font-weight: 400;
  color: var(--vd-cloud-text3);
}

.vdNoticeMarquee > marquee {
  display: flex;
  /*justify-content: center;*/
  align-items: center;
  max-width: 300px;
}

/*캐로셀*/
.carouselWrap {
  display: flex;
  position: relative;
  /*overflow: hidden;*/
  /*width: 420px;*/
}
</style>
