<template>
  <div :class="$style.tableWrap" :style="({ marginBottom: marginBottom }, cssProps)">
    <table
      :class="[$style.table, noneHover ? $style.noneHover : '']"
      :style="{ marginBottom: tableMarginBottom }"
    >
      <colgroup>
        <slot name="colgroup"></slot>
      </colgroup>
      <thead :class="$style.thead">
        <tr>
          <th v-for="(item, idx) in columns" :key="idx">{{ item }}</th>
        </tr>
      </thead>
      <tbody :class="$style.tbody">
        <slot name="tbody"></slot>
      </tbody>
    </table>
    <div :class="$style.paginationWrap">
      <slot name="pagination"></slot>
    </div>
    <div :class="$style.bottomBtnArea">
      <slot name="bottomBtn"></slot>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    columns: {
      type: [Array || Object],
    },
    marginBottom: {
      type: [String, Number],
      default: '140px',
    },
    padding: {
      type: String,
      default: '1.6rem 0',
    },
    noneHover: {
      type: [Boolean, String],
      default: false,
    },
    tableMarginBottom: {
      type: [String, Number],
      default: '1rem',
    },
  },
  computed: {
    cssProps() {
      return {
        '--padding': this.padding,
      };
    },
  },
};
</script>

<style module>
.tableWrap {
  width: 100%;
  position: relative;
}
.table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}
/* 테이블 컬럼 */
.thead tr {
  height: 44px;
  background-color: #003f86;
  color: #f8f8f8;
  text-align: center;
  font-weight: 500;
}
.thead th {
  color: #f8f8f8;
  /*line-height: ;*/
  vertical-align: middle;
  padding: 0.75rem 0;
  text-align: center;
}
.thead th:not(:last-child) {
  border-right: 1px solid var(--main-bg);
}
/* table body */
.tbody tr {
  background-color: var(--table-tr-bg);
  color: var(--table-td-color);
  text-align: center;
  border-bottom: 1px solid var(--table-tr-border-color);
  cursor: pointer;
  height: 72px;
}
.tbody tr:hover {
  background-color: var(--table-tr-hover);
}
.noneHover .tbody tr {
  cursor: default;
  height: 99.55px;
}
.noneHover .tbody tr:hover {
  background-color: var(--table-tr-bg);
}
.tbody tr td {
  white-space: pre-wrap;
  vertical-align: middle;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-all;
  /*overflow: hidden;*/
}
.tbody tr td:nth-child(2) {
  text-align: left;
  padding-left: 2.5rem;
}
/* 페이지네이션 */
.paginationWrap {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
}
/* 최하단 버튼 */
.bottomBtnArea {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  gap: 0.5rem;
}
.bottomBtnArea button {
  background-color: var(--button-bg);
  border: 3px solid var(--button-border-color);
  border-radius: 4px;
  color: var(--button-text-color);
  letter-spacing: -0.24px;
  line-height: 1rem;
  padding: 1.2rem 2.87rem;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
  margin-bottom: 3rem;
  align-self: center;
}
/* 마우스 떠났을때도 애니메이션 주려면.. transition 사용해야 하나? */
.bottomBtnArea button:hover {
  color: #ffffff;
  box-shadow: inset 0 -4em 0 0 #003f86;
  border-color: #003f86;
}
</style>
