<template>
  <TheLittleModal
    v-if="!disable"
    Top="90px"
    Left="85%"
    :width="checkRequestState ? '250px' : '490px'"
  >
    <template #content>
      <!--//-->
      <div v-if="checkRequestState" :class="$style.modalWrap">
        <p>{{ _json.modal.vdProgress.vdProgress }}</p>
        <strong :class="$style.strong">
          {{ isRequestState }}
        </strong>
      </div>

      <!--//-->
      <div v-if="popupInfo?.RequestPopUpType === 'o'" :class="$style.modalWrap">
        <p>{{ _json.modal.vdProgress.vdProgress }}</p>
        <strong :class="$style.strong"> {{ _json.modal.vdProgress.approved }} </strong>
        <p>
          {{ _json.modal.vdProgress.leastOneday }}
        </p>
      </div>

      <!--//-->
      <div v-if="popupInfo?.RequestPopUpType === 'r'" :class="$style.modalWrap">
        <p>{{ _json.modal.vdProgress.vdProgress }}<br /></p>
        <strong :class="$style.strong">{{ _json.modal.vdProgress.rejection }}</strong>
        <p v-text="Comments" style="white-space: pre-wrap" />
        <div :class="$style.checkBox">
          <input type="checkbox" id="checksaveid" v-model="checked" />
          <label
            :class="darkMode ? $style.checkCustomDark : $style.checkCustom"
            for="checksaveid"
          />
          <div :class="darkMode ? $style.checkTextDark : $style.checkText">
            {{ _json.modal.vdProgress.checkReason }}
          </div>
        </div>
      </div>
    </template>
    <template #btn>
      <!--//-->
      <div v-if="popupInfo?.RequestPopUpType !== 'r'" :class="$style.buttonWrap">
        <TheButton width="150px" @isClick="closeModal($event)">
          {{ _json.modal.vdProgress.close }}
        </TheButton>
      </div>

      <!--//-->
      <div v-if="popupInfo?.RequestPopUpType === 'r'" :class="$style.buttonWrap">
        <TheButton :disabled="!checked" width="150px" @isClick="closeModal($event)">
          {{ _json.modal.vdProgress.confirm }}
        </TheButton>
        <TheButton width="150px" @isClick="setDisableModal" :btnStyle="checked ? 'warning' : ''">
          {{ _json.modal.vdProgress.close }}
        </TheButton>
      </div>
    </template>
  </TheLittleModal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { progressReadApi } from '@/api/module/mainApi';

export default {
  name: 'VdProcessStateModal',
  data() {
    return {
      checked: false,
      disable: false,
    };
  },
  computed: {
    ...mapState('common', ['darkMode', 'setIntervalFunc']),
    ...mapState('vd', ['vdProgressData']),
    ...mapState('modal', ['popupInfo']),
    isRequestState() {
      switch (this.popupInfo?.RequestPopUpType) {
        case 'd':
          return this._json.modal.vdProgress.review;
        case 'o':
          return this._json.modal.vdProgress.approve;
        default:
          return ' ';
      }
    },
    checkRequestState() {
      switch (this.popupInfo?.RequestPopUpType) {
        case 'r':
          return false;
        case 'o':
          return false;
        default:
          return true;
      }
    },
    Comments() {
      let txt = document.createElement('textarea');
      txt.innerHTML = this.vdProgressData[0]?.Comment2;

      return txt.value;
    },
  },
  async mounted() {
    await this.GET_PROGRESS_VD();
  },
  methods: {
    ...mapActions('modal', ['GET_POPUP_INFO']),
    ...mapActions('vd', ['GET_PROGRESS_VD']),
    // 모달창 읽으며 닫기
    async closeModal() {
      // 프로세스 읽기 기능
      try {
        const { data } = await progressReadApi({
          idx: this.vdProgressData[0]?.idx ? this.vdProgressData[0]?.idx : '',
        });
        switch (data.status) {
          case '0000':
          case '5000':
            await this.GET_POPUP_INFO();
            await this.GET_PROGRESS_VD();
            await clearInterval(this.setIntervalFunc.progressVd);
            this.disable = true;
            return;
          case '0001':
          case '5001':
          case '0004':
          case '5004':
            console.error(this._json.modal.vdProgress.failRead);
            return;
          default:
            return;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 모달창 일시적 닫기
    setDisableModal() {
      this.disable = true;
    },
  },
};
</script>

<style module>
.modalWrap {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.strong {
  font-weight: 700;
}

.strong span {
  color: var(--list-card-warring-text-color);
}

.text strong {
  font-weight: 700;
}

.buttonWrap {
  display: flex;
  justify-content: center;
  column-gap: 15px;
}

/* 체크박스 커스텀 ============================================================================== */

.checkBox {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

.checkBox input {
  display: none;
}

.checkBox input:checked + .checkCustom {
  background: url('@/assets/imgs/icon_checkbox_checked.png') no-repeat;
}
.checkBox input:checked ~ .checkText {
  color: var(--login-active-checkText);
}

.checkBox input:checked + .checkCustomDark {
  background-color: #f8f8f8;
}

.checkBox input:checked + .checkCustomDark:after {
  content: '✔';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  color: #0078ff;
  text-align: center;
  font-size: 15px;
  transform: translate(-50%, -50%);
}

.checkBox input:checked ~ .checkTextDark {
  color: #fff;
}

.checkBox .checkCustomDark:hover ~ .checkTextDark {
  color: #fff;
}

.checkCustom {
  display: block;
  width: 20px;
  height: 20px;
  border: 1px gray;
  /*border: var(--login-checkbox-border-width) solid var(--login-checkbox-border);*/
  border-radius: 2px;
  background: url('@/assets/imgs/icon_checkbox_none.png') no-repeat;
  cursor: pointer;
}

.checkCustom:hover {
  background: url('@/assets/imgs/icon_checkbox_hover.png') no-repeat;
}

.checkCustomDark {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  border: 2px solid #006dec;
  border-radius: 2px;
  background-color: #3a3a3d;
  cursor: pointer;
}

.checkCustomDark:hover {
  background-color: #6c6c6d;
}

.checkText {
  display: flex;
  align-items: center;
  margin-left: 6px;
  color: #86868b;
  font-size: 0.8rem;
  transition: all 0.3s ease-in-out;
}

.checkTextDark {
  display: flex;
  align-items: center;
  margin-left: 6px;
  color: #86868b;
  font-size: 0.8rem;
  transition: all 0.3s ease-in-out;
}
</style>
