import { loginApi, logoutApi } from '@/api/module/authApi';
import router from '@/routes';
import axios from 'axios';
import { _random } from '@/utils/random';

const encodedUser = sessionStorage.getItem('user');

const authStore = {
  namespaced: true,
  state: {
    user: sessionStorage.getItem('user')
      ? JSON.parse(decodeURIComponent(encodedUser))
      : {
          // UserAlias: '',
          // UserLevel: 0, 0: 사용자 1: 관리자 2: 운영관리자
          // enc_str: '',
          // loginserver: '',
        },
    token: sessionStorage.getItem('auth') ? sessionStorage.getItem('auth') : '',
    fileUpload: false,
    userAlias: '',
  },
  getters: {
    isLogin(state) {
      return Object.keys(state.user).length > 0;
    },
    ifFileUpload(state) {
      return Object.keys(state.user).length > 0 && state.fileUpload;
    },
  },
  mutations: {
    SET_FILE_UPLOAD_STATE(state, value) {
      state.fileUpload = value;
    },
    SET_USER_INFO(state, setData) {
      const encodedUserInfo = encodeURIComponent(JSON.stringify(setData));
      sessionStorage.setItem('user', encodedUserInfo);
      state.user = setData;
    },
    SET_USER_TOKEN(state, payload) {
      sessionStorage.setItem('auth', payload);
      state.token = payload;
    },
    LOG_OUT(state, type) {
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('auth');
      state.user = {};
      state.userAlias = '';

      if (type !== 'checkActiveID') {
        localStorage.removeItem('activeID');
        sessionStorage.removeItem('activeID');
      }
    },
    SET_ALIAS(state, value) {
      state.userAlias = value;
    },
  },
  actions: {
    // 로그인
    async LOGIN({ rootState, state, commit }, { id, pw }) {
      commit('common/SET_LOADING', true, { root: true });
      try {
        let checkIdCode = 0;

        const { data } = await loginApi({
          UserID: id,
          Password: pw,
        });
        switch (data.status) {
          case '0000':
          case '5000':
            checkIdCode = Math.floor(_random() * 1000) + 1;

            localStorage.setItem('activeID', id + checkIdCode);
            sessionStorage.setItem('activeID', id + checkIdCode);
            commit('SET_USER_INFO', data?.output[0]);
            commit('SET_USER_TOKEN', data?.output[0].accessToken);
            if (Number(state.user.FirstLogin) === 0 && rootState.common.smtpState === '1') {
              //모달 띄워주기
              commit('modal/SET_MODAL_OPEN', 'informationRegist', { root: true });
            }
            break;
          case '0003':
          case '5003':
            commit(
              'modal/SET_ALERT_VALUE',
              {
                desc: rootState.common.langJson.modules.auth.lockedAccount_admin,
              },
              { root: true },
            );
            break;
          case '0004':
          case '5004':
            commit(
              'modal/SET_ALERT_VALUE',
              {
                desc: rootState.common.langJson.modules.auth.title,
              },
              { root: true },
            );
            break;
          case '0006':
          case '5006':
            // 최초 로그인시 비밀번호 초기화 옵션 선택 시
            commit('SET_ALIAS', data?.output[0].UserAlias);
            commit(
              'modal/SET_ALERT_VALUE',
              { desc: rootState.common.langJson.modules.auth.changeIdPwd },
              { root: true },
            );
            await router.push('/firstlogin');
            break;
          case '0007':
          case '5007':
            commit(
              'modal/SET_ALERT_VALUE',
              {
                desc: rootState.common.langJson.modules.auth.pwdResetDesc,
              },
              { root: true },
            );
            break;
          case '0008':
          case '0005':
          case '5008':
          case '5005':
            commit(
              'modal/SET_ALERT_VALUE',
              {
                desc: rootState.common.langJson.modules.auth.failLogin,
              },
              { root: true },
            );
            break;
          // SMTP 사용하지 않을 때
          case '0017':
          case '5017':
            commit(
              'modal/SET_ALERT_VALUE',
              {
                desc: rootState.common.langJson.modules.auth.lockedAccount_pwd,
              },
              { root: true },
            );
            break;
          default:
            commit(
              'modal/SET_ALERT_VALUE',
              { desc: rootState.common.langJson.modules.auth.lockedAccount_undefined },
              { root: true },
            );
            break;
        }
      } catch (e) {
        console.error(e);
        commit('common/SET_LOADING', false, { root: true });
      } finally {
        commit('common/SET_LOADING', false, { root: true });
      }
    },
    // 로그아웃
    async LOGOUT_ACTION({ commit }, desc) {
      commit('common/SET_LOADING', true, { root: true });
      let status;

      try {
        const { data } = await logoutApi({
          description: String(desc),
        });
        status = data.status;
        switch (true) {
          case status === '0000':
          case status === '5000':
            // 이력쌓기 성공
            break;
          default:
            // 이력쌓기 실패
            break;
        }
      } catch (e) {
        console.error(e);
      } finally {
        commit('common/SET_LOADING', false, { root: true });
      }
    },
    async LINKER_LOGOUT() {
      try {
        await axios.post('http://127.0.0.1:11300/CenterPostUserPortal/Logout');
      } catch (e) {
        console.error(e);
      }
    },
    // 로그아웃 모듈
    async LOGOUT_MODULE({ commit, dispatch }, logoutData) {
      let desc, type;

      if (logoutData) {
        desc = logoutData.desc;
        type = logoutData.type;
      }

      if (!logoutData) {
        desc = '0';
      }

      await dispatch('LOGOUT_ACTION', desc);
      // await dispatch('LINKER_LOGOUT');
      await commit('LOG_OUT', type);
    },
  },
};

export default authStore;
