import Vue from 'vue';

// common
import TheHeader from '@/components/common/TheHeader.vue';
import TheFooter from '@/components/common/TheFooter.vue';
import TheContainer from '@/components/common/TheContainer.vue';
import TheListCard from '@/components/common/TheListCard.vue';
import TheSubpageTitle from '@/components/common/TheSubpageTitle.vue';
import TheAccordionTr from '@/components/common/TheAccordionTr.vue';
import TheBasicCard from '@/components/common/TheBasicCard.vue';
import TheModal from '@/components/common/TheModal.vue';
import TheQnaAccordionTr from '@/components/common/TheQnaAccordionTr.vue';
import TheNoticeModal from '@/components/common/TheNoticeModal.vue';
import ThePagination from '@/components/common/ThePagination.vue';
import TheLittleModal from '@/components/common/TheLittleModal.vue';

Vue.component('TheAccordionTr', TheAccordionTr);
Vue.component('TheHeader', TheHeader);
Vue.component('TheFooter', TheFooter);
Vue.component('TheContainer', TheContainer);
Vue.component('TheListCard', TheListCard);
Vue.component('TheSubpageTitle', TheSubpageTitle);
Vue.component('TheBasicCard', TheBasicCard);
Vue.component('TheModal', TheModal);
Vue.component('TheQnaAccordionTr', TheQnaAccordionTr);
Vue.component('TheNoticeModal', TheNoticeModal);
Vue.component('ThePagination', ThePagination);
Vue.component('TheLittleModal', TheLittleModal);

// element
import TheInput from '@/components/element/TheInput.vue';
import TheTextarea from '@/components/element/TheTextarea.vue';
import TheFileUpload from '@/components/element/TheFileUpload.vue';
import TheButton from '@/components/element/TheButton.vue';
import TheTable from '@/components/element/TheTable.vue';

Vue.component('TheInput', TheInput);
Vue.component('TheTextarea', TheTextarea);
Vue.component('TheFileUpload', TheFileUpload);
Vue.component('TheButton', TheButton);
Vue.component('TheTable', TheTable);
