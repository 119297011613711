import { render, staticRenderFns } from "./TheModal.vue?vue&type=template&id=22ce3ebc&"
import script from "./TheModal.vue?vue&type=script&lang=js&"
export * from "./TheModal.vue?vue&type=script&lang=js&"
import style0 from "./TheModal.vue?vue&type=style&index=0&id=22ce3ebc&prod&module=true&lang=css&"
import style1 from "./TheModal.vue?vue&type=style&index=1&id=22ce3ebc&prod&lang=css&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports