import Vue from 'vue';
import Vuex from 'vuex';

import commonStore from '@/store/modules/commonStore';
import authStore from '@/store/modules/authStore';
import vdStore from '@/store/modules/vdStore';
import modalStore from '@/store/modules/modalStore';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    common: commonStore,
    auth: authStore,
    vd: vdStore,
    modal: modalStore,
  },
});
