import { mapState } from 'vuex';
import { language_KR } from '@public/language/language_KR';
import { language_JP } from '@public/language/language_JP';
import { language_EN } from '@public/language/language_EN';

export const _json = {
  computed: {
    ...mapState('common', ['language']),
    _json() {
      switch (this.language) {
        case 'KR':
          return language_KR;
        case 'JP':
          return language_JP;
        case 'EN':
        default:
          return language_EN;
      }
    },
  },
};
