import Vue from 'vue';
import App from './App.vue';
import router from '@/routes';
import store from '@/store';
import { _json } from '@public/language/index';

Vue.config.productionTip = false;

// import plugins
import '@/plugins/fontAwesomeIcon';

//Global Component
import '@/components/index';
import '@/layouts/index';

Vue.mixin(_json);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
