<template>
  <textarea
    :required="required"
    :class="$style.textarea"
    :placeholder="placeholder"
    :style="{ height: isHeight }"
    @input="$emit('isInput', $event)"
    :name="name"
    :value="value"
    :maxlength="maxLength"
  />
</template>

<script>
export default {
  props: {
    placeholder: String,
    isHeight: { type: [String, Number], default: '200px' },
    name: String,
    value: [String, Number],
    maxLength: {
      type: [Number, String, Boolean],
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style module>
.textarea {
  width: 100%;
  background-color: var(--input-bg-color);
  border-radius: 4px;
  border: 1px solid var(--input-border-color);
  display: inline-block;
  font-size: 1rem;
  padding: 1rem;
  resize: none;
  color: var(--table-td-color);
  margin-bottom: 0.4rem;
}
.textarea::placeholder {
  color: var(--input-placeholder-color);
  font-size: 0.875rem;
}
</style>
