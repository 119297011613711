import Vue from 'vue';

// home
import BannerView from '@/layouts/home/BannerView.vue';
import SecondSectionView from '@/layouts/home/SecondSectionView.vue';
import ThirdSectionView from '@/layouts/home/ThirdSectionView.vue';
import TheLogin from '@/layouts/home/TheLogin.vue';
import TheCloud from '@/layouts/home/TheCloud.vue';
import TheVdProgress from '@/layouts/home/TheVdProgress.vue';
import TheVdApplication from '@/layouts/home/TheVdApplication.vue';

Vue.component('BannerView', BannerView);
Vue.component('SecondSectionView', SecondSectionView);
Vue.component('ThirdSectionView', ThirdSectionView);
Vue.component('TheLogin', TheLogin);
Vue.component('TheCloud', TheCloud);
Vue.component('TheVdProgress', TheVdProgress);
Vue.component('TheVdApplication', TheVdApplication);

// modal
import AlertModal from '@/layouts/modal/AlertModal.vue';
import AlertConfirmModal from '@/layouts/modal/AlertConfirmModal.vue';
import AuthenticationModal from '@/layouts/modal/AuthenticationModal.vue';
import InformationRegistModal from '@/layouts/modal/InformationRegistModal.vue';
import TimerModal from '@/layouts/modal/TimerModal.vue';
import VdAccessModal from '@/layouts/modal/VdAccessModal.vue';
import VdProcessStateModal from '@/layouts/modal/VdProcessStateModal.vue';

Vue.component('AlertModal', AlertModal);
Vue.component('AlertConfirmModal', AlertConfirmModal);
Vue.component('AuthenticationModal', AuthenticationModal);
Vue.component('InformationRegistModal', InformationRegistModal);
Vue.component('TimerModal', TimerModal);
Vue.component('VdAccessModal', VdAccessModal);
Vue.component('VdProcessStateModal', VdProcessStateModal);
