<template>
  <TheModal
    :title="_json.modal.vdAccess.notice"
    name="vdAccess"
    width="700px"
    height="500px"
    @isSubmit="modalClose"
  >
    <template #content>
      <div :class="$style.flexWrap">
        <strong :class="$style.contentStrong">
          {{ _json.modal.vdAccess.restricted }}
        </strong>
        <div :class="$style.content" v-html="vdAccessModalData?.Detail"></div>
      </div>
    </template>
    <template #btn>
      <TheButton width="500px" type="submit" ref="submitBtn">
        {{ _json.modal.vdAccess.confirm }}
      </TheButton>
    </template>
  </TheModal>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  computed: {
    ...mapState('modal', ['modalList', 'vdAccessModalData']),
  },
  mounted() {
    this.setFocus();
  },
  methods: {
    ...mapMutations('modal', ['SET_MODAL_OPEN']),
    modalClose() {
      this.SET_MODAL_OPEN('vdAccess');
    },
    setFocus() {
      this.$refs.submitBtn.$el.focus();
    },
  },
};
</script>

<style module>
.flexWrap {
  display: flex;
  flex-direction: column;
  row-gap: 45px;
}

.contentStrong {
  font-weight: 500;
  font-size: 1.2rem;
}

.contentStrong span {
  color: #fb1e66;
}

.content {
  padding: 0 50px;
  width: 100%;
  text-align: start;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.6rem;
}
</style>
