<template>
  <article :class="$style.vdApplicationContainer">
    <h3 :class="$style.vdApplicationTitle">{{ _json.home.vdApp.noVd }}</h3>
    <button type="button" :class="$style.vdApplicationBtn" @click="requestNewVd">
      {{ _json.home.vdApp.internetVd }}
    </button>
    <p :class="$style.vdApplicationText" v-html="_json.home.vdApp.reclaimedVd" />
  </article>
</template>

<script>
import { newVdApi } from '@/api/module/vdApi';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'TheVdApplication',
  computed: {
    ...mapState('auth', ['user']),
  },
  methods: {
    ...mapMutations('modal', ['SET_ALERT_VALUE']),
    ...mapActions('vd', ['GET_PROGRESS_VD']),
    async requestNewVd() {
      try {
        const { data } = await newVdApi({
          userId: this.user?.UserAlias,
        });

        switch (data.status) {
          case '0000':
          case '5000':
            // 성공
            await this.SET_ALERT_VALUE({ desc: this._json.home.vdApp.successVd });
            await this.GET_PROGRESS_VD(); // vd 신청상태 업데이트
            break;
          case '0001':
          case '5001':
            this.SET_ALERT_VALUE({ desc: this._json.home.vdApp.errorVd });
            // 실패
            break;
          default:
            this.SET_ALERT_VALUE({ desc: this._json.home.vdApp.errorVd });
            // 실패
            break;
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style module>
.vdApplicationContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding: 40px 21px;
  width: 500px;
  height: 230px;
  background-color: var(--vdApplication-container-bg);
  box-shadow: 0 4px 8px #0000001f;
  border-radius: 4px;
}

.vdApplicationTitle {
  color: var(--vdApplication-container-title);
  font-size: 1.2rem;
  font-weight: 900;
}

.vdApplicationBtn {
  width: 300px;
  height: 50px;
  color: #fff;
  background: #005bc1;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
}

.vdApplicationBtn:hover {
  background: #006dec;
}

.vdApplicationText {
  font-size: 0.9rem;
  line-height: 1.2rem;
  text-align: center;
  color: #989898;
}
</style>
