import store from '@/store';

// [ 한글 입력 여부 체크 ] -> 아이디 검사
export function CHECK_HANGLE(value) {
  let regex = /[ㄱ-ㅎㅏ-ㅣ가-힣]/;
  return regex.test(value);
}

// [ 숫자 입력 여부 체크 ] -> 생년월일 검사
export function CHECK_NUMBER(value) {
  let regex = /^[0-9]+$/;
  return regex.test(value);
}

// [ 생년월일 양식 체크 ]
export function CHECK_BIRTHDAY_RANGE(birthday) {
  let res = {};
  // 생년월일의 길이가 잘못되었을 때
  if (birthday.length !== 6 && birthday.length >= 1) {
    res = { result: false, text: store.state.common.langJson.pwdReg.birthdayPolicy };
    return res;
  }
  let month = birthday.slice(2, 4);
  let date = birthday.slice(4, 6);
  // 월을 잘못 입력함
  if (Number(month) > 12 || Number(month) <= 0) {
    res = { result: false, text: store.state.common.langJson.pwdReg.failBirthDay };
    return res;
  }
  // 일을 잘못 입력함
  if (Number(date) > 31 || Number(date) <= 0) {
    res = { result: false, text: store.state.common.langJson.pwdReg.failBirthDay };
    return res;
  }
  // 월별로 존재하지 않는 날짜를 입력시 리턴
  switch (Number(month)) {
    case 2:
      if (Number(date) > 29) {
        res = { result: false, text: store.state.common.langJson.pwdReg.failBirthDay };
        return res;
      }
      break;
    case 4:
    case 6:
    case 9:
    case 11:
      if (Number(date) > 30) {
        res = { result: false, text: store.state.common.langJson.pwdReg.failBirthDay };
        return res;
      }
      break;
    default:
      break;
  }
  res = { result: true, text: '' };
  return res;
}
