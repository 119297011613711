<template>
  <header :class="$style.header">
    <TheContainer :class="$style.container" padding="0 0 0 4x" :width="'1100px'">
      <h1 :class="$style.title">
        <router-link to="/">
          <!--   엔지니어 연결 로고    -->
          <img
            v-if="imageExists"
            :class="$style.logoCI"
            @click="LogoClick"
            :src="formattedTitlePath"
            alt="CenterPost"
          />
          <!--   대체이미지 연결 로고    -->
          <img
            v-else-if="fallbackImagePath"
            :class="$style.logoCI"
            @click="LogoClick"
            :src="fallbackImagePath"
            alt="CenterPost"
          />
          <!--   기본 대체 문구    -->
          <span v-else @click="LogoClick">{{ _json.common.header.title }}</span>
        </router-link>
      </h1>
      <nav :class="isLogin() === true ? [$style.nav, $style.height61] : $style.nav">
        <ul :class="$style.smallNav">
          <li :class="[$style.toggleCont, $style.activePrimary]">
            <button type="button" @click="SET_DARK_MODE(!darkMode)">
              {{ _json.common.header.darkMode }}
            </button>
            <label :class="$style.toggle">
              <input :checked="darkMode" type="checkbox" @change="darkModeClick($event)" />
              <span :class="$style.slider"></span>
            </label>
          </li>
          <template v-if="isLogin() === true">
            <li :class="darkMode ? $style.loginDark : $style.login">
              <p>
                <span :class="$style.userName">
                  {{ user?.UserName ? user?.UserName : user?.UserAlias }}
                </span>
                {{ _json.common.header.welcomeText }}
              </p>
              <button @click="LogOut" type="button">
                {{ _json.common.header.logoutText }}
              </button>
            </li>
            <li :class="$style.activePrimary">
              <router-link to="/info">
                {{ _json.common.header.infoChange }}
              </router-link>
            </li>
          </template>
          <li
            :class="user?.UserAlias ? [$style.border, $style.activePrimary] : $style.activePrimary"
          >
            <router-link to="/download">
              {{ _json.common.header.download }}
            </router-link>
          </li>
          <li>
            <select
              :style="{ padding: language === 'EN' ? '3px 20px 3px 3px' : '' }"
              :class="darkMode ? $style.langDropdownDark : $style.langDropdown"
              @change="CHOOSE_LANGUAGE($event.target.value)"
            >
              <option
                v-for="(item, idx) in langOpt.dropdown"
                :key="idx"
                :value="item.value"
                :selected="langOpt.chooseVal === item.value"
              >
                {{ item.text }}
              </option>
            </select>
          </li>
        </ul>
        <template v-if="isLogin() === true">
          <ul :class="$style.navi">
            <li
              v-for="item in _json.common.navList"
              :class="{
                [$style.activeLi]: item.to === isRoutePath,
                [$style.li]: language === 'KR',
                [$style.otherLi]: language !== 'KR',
              }"
              :key="item.to"
            >
              <router-link :to="item.to"> {{ item.name }} </router-link>
            </li>
          </ul>
        </template>
      </nav>
    </TheContainer>
  </header>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { getDarkLogoCI, getLogoCI } from '@/api/module/mainApi';

export default {
  data() {
    return {
      titlePath: '',
      titleName: '',
      imageExists: false,
      fallbackImagePath: '',
      baseTitle: 'CenterPost',
    };
  },
  async mounted() {
    await this.getLogo();
    // 엔지니어에서 받아온 이미지경로가 존재할때만 이미지 교체시킴
    switch (true) {
      case this.formattedTitlePath:
        this.imageExists = await this.checkImageExists(this.formattedTitlePath);
        if (this.isDarkMode) {
          await this.getDarkLogo();
          this.imageExists = await this.checkImageExists(this.formattedTitlePath);
        }
        break;
      default:
        break;
    }
  },
  watch: {
    async isDarkMode(newVal, oldVal) {
      await this.updateLogo();
    },
  },
  computed: {
    ...mapState('common', ['darkMode', 'setIntervalFunc', 'langOpt', 'language']),
    ...mapState('auth', ['user']),
    ...mapGetters('common', ['isDarkMode', 'returnChooseLang']),
    isRoutePath() {
      return '/' + this.$route.path.split('/')[1];
    },
    formattedTitlePath() {
      return `${window.location.protocol}//${window.location.hostname}/userapi${this.titlePath}`;
    },
  },
  methods: {
    ...mapMutations('common', ['SET_DARK_MODE', 'CHOOSE_LANGUAGE']),
    ...mapActions('auth', ['LOGOUT_MODULE']),
    ...mapGetters('auth', ['isLogin']),
    darkModeClick(e) {
      const checked = e.target.checked;
      this.SET_DARK_MODE(checked);
    },
    async updateLogo() {
      await this.getLogo();
      switch (true) {
        case this.formattedTitlePath:
          this.imageExists = await this.checkImageExists(this.formattedTitlePath);
          if (this.isDarkMode) {
            await this.getDarkLogo();
            this.imageExists = await this.checkImageExists(this.formattedTitlePath);
          }
          break;
        default:
          break;
      }
    },
    // 로그아웃
    async LogOut() {
      await this.LOGOUT_MODULE();
      // 로그인 시 VD1개 일때 바로실행을 위한 키값
      sessionStorage.removeItem('VD');
      clearInterval(this.setIntervalFunc.progressVd);
      await this.$router.push('/');
    },
    // 로고 클릭시 동작하는 기능...
    LogoClick() {
      switch (true) {
        case this.$route.path.split('/')[1] === '':
          window.location.reload();
          break;
        default:
          break;
      }
    },
    // 헤더 로고
    async getLogo() {
      try {
        const response = await getLogoCI();

        switch (true) {
          case response.data.status === '0000' && response.data.output:
            this.titlePath = response.data.output?.FilePath;
            this.titleName = response.data.output?.FileName;
            break;
          default:
            break;
        }
      } catch (error) {
        console.error(this._json.common.header.networkError, error);
      }
    },
    async getDarkLogo() {
      try {
        const response = await getDarkLogoCI();

        switch (true) {
          case response.data.status === '0000' && response.data.output:
            this.titlePath = response.data.output?.FilePath;
            this.titleName = response.data.output?.FileName;
            break;
          default:
            break;
        }
      } catch (error) {
        console.error(this._json.common.header.networkError, error);
      }
    },
    checkImageExists(src) {
      return new Promise(resolve => {
        const img = new Image();
        img.src = src;
        // 이미지가 존재하는지 판별 존재하면 true 없으면 false
        img.onload = () => resolve(true);
        img.onerror = () => resolve(false);
      });
    },
  },
};
</script>

<style module>
.header {
  width: 100%;
  background-color: var(--header-bg-color);
  border-bottom: 1px solid var(--header-border-color);
}

.container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 105px;
  max-height: 105px;
}

.title {
  display: block;
  max-width: 247px;
}

.title > img {
  display: block;
  max-height: 58px;
}

.title span {
  display: block;
  font-weight: 600;
  font-size: 26px;
  line-height: 100%;
  padding-bottom: 2px;
  color: var(--header-title-color);
}

.nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  row-gap: 18px;
  margin-right: 10px;
}

.logoCI {
  font-size: 2rem;
  font-weight: 600;
  color: var(--footer-desc-color);
  max-width: 247px;
  max-height: 61px;
  padding-bottom: 2px;
}

.height61 {
  height: 61px;
}

.smallNav {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.smallNav > li {
  font-size: 0.875rem;
  color: var(--header-font-color);
  transition: all 0.3s ease-in-out;
}

.smallNav > li > button {
  font-size: 0.875rem;
}

.activePrimary:hover {
  color: var(--header-font-hover-color);
  cursor: pointer;
}

.toggleCont {
  display: flex;
  align-items: center;
  /*column-gap: 3px;*/
}
.toggleCont:hover .toggle > input:checked + .slider {
  /*background-color: #2196f3;*/
  background: transparent linear-gradient(180deg, #9bc5f3 0%, #3e7bbf 100%) 0 0 no-repeat
    padding-box;
}

.toggleCont > span {
  display: block;
  vertical-align: middle;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 18px;
  margin-bottom: 2px;
  margin-right: 0.5rem;
}

.toggle > input {
  display: none;
}

.border {
  position: relative;
  margin: 0 0 0 6px;
}

.border:before {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: -7px;
  width: 1px;
  height: 100%;
  color: var(--header-line-color);
  background: var(--header-line-color);
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--slider-bg);
  transition: 0.4s;
  border-radius: 18px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.toggle > input:checked + .slider:hover {
  /*background-color: #2196f3;*/
  background: transparent linear-gradient(180deg, #9bc5f3 0%, #3e7bbf 100%) 0 0 no-repeat
    padding-box;
}

.toggle > input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.toggle > input:checked + .slider:before {
  transform: translateX(20px);
}

.toggle > label {
  display: inline-block;
  margin-right: 10px;
}

.login {
  display: flex;
  align-items: center;
  column-gap: 4px;
}

.userName {
  color: var(--li-active-color);
}

.login > button {
  font-size: 0.875rem;
  padding: 0.094rem 0.719rem;
  background: transparent linear-gradient(180deg, #fff, #e4e4e4) 0 0 no-repeat padding-box;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 0 2px 4px;
}

.login > button:hover {
  background: transparent linear-gradient(180deg, #9bc5f3 0%, #3e7bbf 100%) 0 0 no-repeat
    padding-box;
  border: 1px solid transparent;
  color: #fff;
}

.loginDark {
  display: flex;
  align-items: center;
  column-gap: 4px;
}

.loginDark > button {
  font-size: 0.875rem;
  padding: 0.094rem 0.719rem;
  background: transparent linear-gradient(180deg, #3a3a3d 100%, #1d1d1f 0) 0 0 no-repeat padding-box;
  border: 1px solid #3a3a3d;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 0 2px 4px;
}

.loginDark > button:hover {
  background: transparent linear-gradient(180deg, #9bc5f3 0%, #3e7bbf 100%) 0 0 no-repeat
    padding-box;
  border: 1px solid transparent;
  color: #fff;
}

.navi {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: flex-start;
  height: 40px;
  /*column-gap: 52px;*/
}

.li,
.otherLi {
  text-align: center;
  height: 100%;
}

.li:nth-child(1) {
  width: 100px;
}

.li:nth-child(2) {
  width: 60px;
  margin-left: 51px;
}

.li:nth-child(3) {
  width: 60px;
  margin-left: 52px;
}

.li:nth-child(4) {
  width: 60px;
  margin-left: 41px;
}

.otherLi:nth-child(1) {
  width: 170px;
}

.otherLi:nth-child(2) {
  width: 120px;
  margin-left: 40px;
}

.otherLi:nth-child(3) {
  width: 70px;
  margin-left: 40px;
}

.otherLi:nth-child(4) {
  width: 60px;
  margin-left: 40px;
}

.li a,
.otherLi a {
  position: relative;
  display: block;
  height: 100%;
  color: var(--li-color);
}

.li a::after,
.otherLi a::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 4px;
  background-color: #003f86;
  transition: transform 0.25s ease-in-out;
  transform: scaleX(0);
  border-radius: 8px;
}

.li a:hover,
.otherLi a:hover {
  color: var(--li-active-color);
  font-weight: 600;
}

.li a:hover::after,
.otherLi a:hover::after {
  transform: scaleX(1);
}

.activeLi a {
  position: relative;
  display: block;
  height: 100%;
  color: var(--li-active-color);
  font-weight: 600;
}

.activeLi a::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 4px;
  /*background-color: #003f86;*/
  border-radius: 8px;
  transform: scaleX(1);
}

.langDropdown {
  font-size: 0.875rem;
  cursor: pointer;
  line-height: 1rem;
  text-align: center;
  transition: all 0.3s ease-in-out;
  background-color: transparent;
  color: var(--header-font-color);
  font-weight: bold;
  border: 1px solid #8e8e93;
  border-radius: 5px;
  padding: 3px 20px 3px 5px;
}

.langDropdownDark {
  font-size: 0.875rem;
  cursor: pointer;
  line-height: 1rem;
  text-align: center;
  transition: all 0.3s ease-in-out;
  background-color: transparent;
  color: #8e8e93;
  font-weight: bold;
  border: 1px solid #8e8e93;
  border-radius: 5px;
  padding: 3px 20px 3px 5px;
}
.langDropdown:hover {
  color: var(--header-font-hover-color);
}
select {
  -webkit-appearance: none; /* 크롬 화살표 없애기 */
  -moz-appearance: none; /* 파이어폭스 화살표 없애기 */
  appearance: none; /* 화살표 없애기 */
  background-image: url('@/assets/imgs/down-arrow.svg');
  background-size: 1.75rem;
  background-repeat: no-repeat;
  background-position: right -0.25rem center;
}
</style>
