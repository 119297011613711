<template>
  <div :class="$style.container">
    <form
      :class="$style.modal"
      ref="modal"
      :name="name"
      :style="cssProps"
      @submit.prevent="$emit('isSubmit', $event)"
    >
      <div>
        <h2 :class="$style.title">
          {{ title }}
        </h2>
        <p v-if="desc" :class="$style.desc" v-html="desc" />
      </div>
      <div :class="$style.content">
        <slot name="content"></slot>
      </div>
      <div :class="$style.btnGroup">
        <slot name="btn"></slot>
      </div>
    </form>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { _random } from '@/utils/random';

export default {
  data() {
    return {
      dragging: false,
      mouseX: 0,
      mouseY: 0,
      //
      modalTop: 0,
      modalLeft: 0,
    };
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '400px',
    },
    height: {
      type: String,
      default: '206px',
    },
    zIndex: {
      type: Number,
      default: 300,
    },
  },
  computed: {
    ...mapState('modal', ['modalList']),
    cssProps() {
      return {
        '--width': this.width,
        '--height': this.height,
        '--z-index': this.modalList[this.name]?.index,
      };
    },
  },
  methods: {
    ...mapMutations('modal', ['SET_MODAL_INDEX']),
    // handleClick() {
    //   this.SET_MODAL_INDEX({ name: this.name });
    // },
    // ================== [ 모달 클릭시 동작 ] ==================
    handleMouseDown(e) {
      this.SET_MODAL_INDEX({ name: this.name });

      const targetModal = e.currentTarget;

      // 모달 현재 위치값
      // 브라우저에서 사용자에게 웹페이지가 보여지는 영역을 기준으로 좌표를 표시
      this.modalTop = e.clientX - targetModal.offsetLeft;
      this.modalLeft = e.clientY - targetModal.offsetTop;

      document.addEventListener('mousemove', this.handleDragg);
    },
    // ================== [ 모달 드래그시 동작 ] ==================
    handleDragg(e) {
      const targetModal = this.$refs.modal;

      // 이동된 위치값
      let newX = e.clientX - this.modalTop;
      let newY = e.clientY - this.modalLeft;

      // x축 제한
      if (newX < 0) {
        newX = 0;
        document.removeEventListener('mousemove', this.handleDragg);
      } else if (newX > 1800) {
        newX = 1800;
        document.removeEventListener('mousemove', this.handleDragg);
      }

      // Y축 제한
      if (newY < 200) {
        newY = 205;
        document.removeEventListener('mousemove', this.handleDragg);
      } else if (newY > 1000) {
        newY = 1000;
        document.removeEventListener('mousemove', this.handleDragg);
      }

      targetModal.style.left = newX + 'px';
      targetModal.style.top = newY + 'px';

      // this.createCircleEffect(e);
    },
    // ================== [ 모달 드래그시 끝날시 동작 ] ==================
    handleMouseUp() {
      document.removeEventListener('mousemove', this.handleDragg);
    },
    // ================== [ 애니메이션 테스트 ] ==================
    createCircleEffect(e) {
      const modalPage = document.querySelector('#modalPage');
      const circle = document.createElement('span');
      circle.classList.add('circle');

      let newX = e.clientX - this.modalTop;
      let newY = e.clientY - this.modalLeft;

      circle.style.left = newX + 'px';
      circle.style.top = newY + 'px';

      let size = _random() * 5;
      circle.style.width = 5 + size + 'px';
      circle.style.height = 5 + size + 'px';

      let transformValue = _random * 60;
      circle.style.transform = 'rotate(' + transformValue + 'deg)';

      modalPage.appendChild(circle);

      setTimeout(function () {
        circle.remove();
      }, 800);
    },
  },
};
</script>

<style module>
.container {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--modal-page-bg);
  z-index: 400;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1.375rem;
  width: 100%;
  max-width: var(--width);
  height: var(--height);
  padding: 2rem 2.75rem;
  background-color: var(--modal-bg);
  border-radius: 8px;
  color: var(--modal-text);
  z-index: var(--z-index);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.title,
.desc {
  text-align: center;
}

.title {
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 0.625rem;
}

.desc {
  font-size: 0.813rem;
}

.content {
  flex: 1;
  width: 100%;
  text-align: center;
}

.btnGroup {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
}
</style>

<style>
.circle {
  position: absolute;
  pointer-events: none;
  animation: fadeInOut 2s linear infinite;
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0;
  }
  20%,
  80% {
    opacity: 1;
  }
}

.circle::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(202, 228, 225, 0.92);
  box-shadow: 0 0 6px rgba(202, 228, 225, 0.92), 0 0 30px rgba(202, 228, 225, 0.34),
    0 0 12px rgba(30, 132, 242, 0.52), 0 0 21px rgba(30, 132, 242, 0.92),
    0 0 34px rgba(30, 132, 242, 0.78), 0 0 54px rgba(30, 132, 242, 0.92);
  animation: moveShape 2s linear infinite;
}

@keyframes moveShape {
  0% {
    transform: translate(0) rotate(0deg);
  }
  100% {
    transform: translate(300px) rotate(360deg);
  }
}
</style>
