import { allocatedVdApi, getVmIdApi, progressVdApi, vdRestartApi } from '@/api/module/vdApi';

const vdStore = {
  namespaced: true,
  state: {
    vdProgressData: {
      // idx: 고유번호
      // "Comment": 승인결과 메시지,
      // "Comment2": 관리자 메시지,
      // "Req_Flag": 요청 구분 flag, ['r' 반려/ 'd' 대기(검토중)/ 'o' 승인/ 'c' 검토완료],
    },
    vdNameList: [
      // 할당된 VD list
    ],
    selectedVd: {
      // 선택된 VD
    },
  },
  getters: {},
  mutations: {
    SET_PROGRESS_DATA(state, data) {
      state.vdProgressData = data;
    },
    SET_VDNAME_LIST(state, data) {
      state.vdNameList = data;
    },
    SET_SELECTED_VD(state, data) {
      state.selectedVd = data;
    },
  },
  actions: {
    // VD정보 받아오기
    async GET_ALLOCATED_VD_LIST({ commit, rootState }) {
      commit('common/SET_LOADING', true, { root: true });
      try {
        const { data } = await allocatedVdApi({
          UserID: rootState.auth.user?.UserAlias,
        });
        switch (data.status) {
          case '5000':
          case '0000':
            return data;
          default:
            break;
        }
      } catch (e) {
        console.error(e);
      } finally {
        commit('common/SET_LOADING', false, { root: true });
      }
    },
    // VMID받아오기
    async GET_VM_ID({ commit, dispatch }, selectedVd) {
      commit('common/SET_LOADING', true, { root: true });
      try {
        let res;

        if (selectedVd?.dedicateFlag === 1) {
          const { data } = await getVmIdApi({
            VMName: selectedVd?.VDName,
            dedicateFlag: selectedVd?.dedicateFlag,
          });
          res = data;
        }

        if (selectedVd?.dedicateFlag === 2) {
          const { data } = await getVmIdApi({
            VMName: selectedVd?.VMGroupName,
            dedicateFlag: selectedVd?.dedicateFlag,
          });
          res = data;
        }

        switch (res.status) {
          case '5000':
          case '0000':
            dispatch('RUN_LINKER', res.output?.VMID);
            break;
          default:
            break;
        }
      } catch (e) {
        console.error(e);
      } finally {
        commit('common/SET_LOADING', false, { root: true });
      }
    },
    // VMID로 링커 실행
    async RUN_LINKER({ rootState, commit }, VMID) {
      commit('common/SET_LOADING', true, { root: true });
      try {
        const { enc_str, loginserver } = rootState.auth.user;

        const ws = new WebSocket('ws://127.0.0.1:21660');
        const url = `dslinker://CONNECTVM?VMID=${VMID}&userinfo=${enc_str}&info=${loginserver}&OTP=0`;
        ws.onopen = () => {
          window.location.href = url;
        };
        // 닫힘
        // webSocket.onclose = function () {
        // 	alert('[WEBSOCKET] Close : ' + webSocket.readyState);
        // };
        ws.onerror = () => {
          function download() {
            const downloadUrl = `${window.location.protocol}//${window.location.hostname}/userapi/webfile/InstallFile`;
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = '';
            link.click();
            link.remove();
          }

          commit(
            'modal/SET_ALERTCONFIRM_VALUE',
            {
              desc: rootState.common.langJson.modules.vd.programInstall,
              btnName: rootState.common.langJson.modules.vd.confirm,
              func: download,
            },
            { root: true },
          );
        };
      } catch (e) {
        console.error(e);
      } finally {
        commit('common/SET_LOADING', false, { root: true });
      }
    },
    // VD 신청상태 프로그래스 데이터 받아오기
    async GET_PROGRESS_VD({ commit, rootState }) {
      try {
        const { data } = await progressVdApi({
          userId: rootState.auth.user?.UserAlias,
        });
        switch (data.status) {
          case '5000':
          case '0000':
            commit('SET_PROGRESS_DATA', data.output);
            break;
          default:
            break;
        }
      } catch (e) {
        console.error(e);
      }
    },
    // VD 재실행
    async RESTART_VD({ rootState, commit }, VDName) {
      try {
        const { data } = await vdRestartApi({
          VDName: VDName,
        });
        switch (data.status) {
          case '5000':
          case '0000':
            // 성공
            commit(
              'modal/SET_ALERT_VALUE',
              { desc: rootState.common.langJson.modules.vd.restartSuccess },
              { root: true },
            );
            break;
          default:
            // 실패
            commit(
              'modal/SET_ALERT_VALUE',
              { desc: rootState.common.langJson.modules.vd.restartFail },
              { root: true },
            );
            break;
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
};

export default vdStore;
