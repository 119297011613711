import axios from 'axios';
import { setInterceptors } from '@/api/common/interceptors';

function createInstance() {
  const protocol = window.location.protocol;
  const hostName = window.location.hostname;
  const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL || `${protocol}//${hostName}/userapi`,
  });
  return setInterceptors(instance);
}

export const instance = createInstance();
