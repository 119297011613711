<template>
  <div :class="[$style.card, $style[color]]">
    <ul :class="$style.desc">
      <li :class="$style.cardTitle">
        <strong>{{ cardTitle }}</strong>
      </li>
      <li :class="$style.moreBtn" @click="$emit('isClick')">
        <span>{{ _json.common.listCard.viewMore }}</span>
        <div></div>
      </li>
      <li style="height: 91px">
        <template v-if="listLength !== 0">
          <ul :class="$style.listTitle">
            <slot></slot>
          </ul>
        </template>
        <template v-else>
          <div :class="$style.tempDiv">{{ _json.common.listCard.noPosts }}</div>
        </template>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    cardTitle: String,
    color: {
      type: String,
      default: 'orange',
      validator: function (value) {
        return ['orange', 'blue'].includes(value);
      },
    },
    listLength: [String, Number],
  },
};
</script>

<style module>
.card {
  border-radius: 4px;
  border-style: solid none none;
  border-width: 4px 0 0;
  padding: 1.25rem 1.25rem;
}
.orange {
  background-color: var(--list-card-bg-orange);
  border-color: #ff7700;
}
.blue {
  background-color: var(--list-card-bg-blue);
  border-color: #006dec;
}
.desc {
  position: relative;
}
.cardTitle {
  color: var(--list-card-title-color);
  font-size: 1.125rem;
  font-weight: 900;
  line-height: 1.125rem;
  margin-bottom: 28px;
}
.moreBtn {
  display: flex;
  gap: 0.5rem;
  position: absolute;
  top: 0;
  right: 0;
}
.moreBtn span {
  vertical-align: middle;
  font-size: 0.875rem;
  line-height: 1rem;
  color: var(--subTitle-desc-color);
}
.moreBtn div {
  background-image: url('@/assets/imgs/icon_more.png');
  width: 0.875rem;
  height: 0.875rem;
  background-size: cover;
}
.moreBtn:hover {
  cursor: pointer;
}
.orange .moreBtn:hover span {
  color: #ff7f00;
  transition: all 0.3s ease-in-out;
}
.orange .moreBtn:hover div {
  background-image: url('@/assets/imgs/icon_more_notice_hover.png');
  transition: all 0.3s ease-in-out;
}
.blue .moreBtn:hover span {
  color: #006dec;
  transition: all 0.3s ease-in-out;
}
.blue .moreBtn:hover div {
  background-image: url('@/assets/imgs/icon_more_faq_hover.png');
  transition: all 0.3s ease-in-out;
}

.listTitle li {
  color: var(--subTitle-desc-color);
  height: 1.5rem;
}
.listTitle li:not(:last-child) {
  margin-bottom: 9.5px;
}

.blue .listTitle li {
  max-width: 300px;
  /*overflow: hidden;*/
  /*text-overflow: ellipsis;*/
  /*white-space: nowrap;*/
}

.orange .listTitle li tr td:not(:last-child):hover {
  color: var(--orange-hover-color);
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: var(--orange-hover-color);
  text-underline-offset: 5px;
  transition: all 0.5s ease-in-out;
}
.blue .listTitle li:hover {
  color: var(--blue-hover-color);
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: var(--blue-hover-color);
  text-underline-offset: 5px;
  transition: all 0.5s ease-in-out;
}
.tempDiv {
  color: var(--subTitle-desc-color);
  text-align: center;
  padding-top: 1.7rem;
}
</style>
