import store from '@/store';

export async function GET_LIST_ACTION(ApiFunction, errorMsg) {
  try {
    const { data } = await ApiFunction;

    switch (data.status) {
      case '0000':
      case '5000':
        // 성공
        return data.output;
      case '0001':
      case '5001':
        // 실패
        store.commit('modal/SET_ALERT_VALUE', {
          desc: errorMsg ? errorMsg : 'Fail',
          btnName: store.state.common.langJson.common.router.confirm,
        });
        break;
      default:
        store.commit('modal/SET_ALERT_VALUE', {
          desc: errorMsg ? errorMsg : 'Fail',
          btnName: store.state.common.langJson.common.router.confirm,
        });
        break;
    }
  } catch (e) {
    console.error(e);
    store.commit('modal/SET_ALERT_VALUE', {
      desc: errorMsg ? errorMsg : e,
      btnName: store.state.common.langJson.common.router.confirm,
    });
  }
}
