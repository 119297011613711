import store from '@/store';
import router from '@/routes';

export function setInterceptors(instance) {
  instance.interceptors.request.use(
    function (config) {
      const isLogin = store.getters['auth/isLogin'];
      const isFileUpload = store.getters['auth/ifFileUpload'];
      const isLang = store.getters['common/returnChooseLang'];
      const { token, user } = store.state.auth;

      if (isFileUpload) {
        config.headers = {
          'Content-Type': 'multipart/form-data;',
          accessToken: token,
          userid: user?.UserAlias.toLowerCase(),
          Language: isLang,
        };
        return config;
      }

      if (isLogin === true) {
        config.headers = {
          accessToken: token,
          userid: user?.UserAlias.toLowerCase(),
          Language: isLang,
        };
        return config;
      }
      config.headers = {
        Language: isLang,
      };
      return config;
    },
    function (error) {
      // 요청 오류가 있는 작업 수행
      return Promise.reject(error);
    },
  );

  // 응답 인터셉터 추가하기
  instance.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      if (error.response.status === 406) {
        await store.dispatch('auth/LOGOUT_MODULE', { desc: '1' });
        await router.push('/');
        setTimeout(() => {
          alert(store.state.common.langJson.common.interceptors.retryLogin);
        }, 2000);
        return;
      }
      return Promise.reject(error);
    },
  );
  return instance;
}
