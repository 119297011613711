<template>
  <section :class="$style.BannerSection">
    <div :class="darkMode ? $style.BannerBgDark : $style.BannerBg">
      <TheContainer padding="0 4px" :width="'1100px'">
        <div :class="$style.BannerInner">
          <div :class="$style.BannerTextBox">
            <h3
              :class="$style.BannerTitle"
              v-html="bannerTitle !== '' ? bannerTitle : _json.home.banner.title"
            />
            <p v-if="bannerDesc !== ''" :class="$style.BannerText" v-html="bannerTitle" />
            <p v-else :class="$style.BannerText" v-html="_json.home.banner.desc" />
          </div>
          <!--[스켈레톤 UI]-->
          <div v-if="isLogin() && skeleton" :class="$style.skeletonUi" />
          <!--[ 로그인 ]-->
          <TheLogin v-if="!isLogin()" />
          <!--[ VD할당 상태 ]-->
          <transition name="fade">
            <TheCloud v-if="isLogin() && !skeleton" />
          </transition>
          <!--[ 공지사항 모달 ]-->
          <TheNoticeModal
            v-for="(content, idx) in noticeModalData"
            :localStorageName="`noticeBoard${idx}`"
            :Top="`${idx * 30}px`"
            :Left="`${idx * 20}px`"
            :content="content"
            :SeqNumber="String(content?.BoardSeq)"
            :index="content?.BoardSeq"
            :key="idx"
          />
        </div>
      </TheContainer>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { getBannerList } from '@/api/module/mainApi';

export default {
  name: 'BannerView',
  data() {
    return {
      selectItem: this.$route.params.id ? this.$route.params.id : '',
      bannerTitle: '',
      bannerDesc: ``,
    };
  },
  mounted() {
    this.getBannerList();
    this.removeSkeletonUI();
  },
  computed: {
    ...mapState('common', ['darkMode', 'skeleton']),
    ...mapState('modal', ['noticeModalData']),
  },
  methods: {
    ...mapGetters('auth', ['isLogin']),
    ...mapMutations('common', ['SET_SKELETON']),

    async getBannerList() {
      try {
        const response = await getBannerList();
        switch (true) {
          case response.data.status === '0000' && response.data.output:
            this.bannerTitle = response.data.output?.title;
            this.bannerDesc = response.data.output?.description;
            break;
          default:
            this.bannerTitle = '';
            this.bannerDesc = '';
            break;
        }
      } catch (error) {
        console.error(this._json.home.banner.error, error);
      }
    },

    // 스켈레톤UI 동작!!
    removeSkeletonUI() {
      if (this.skeleton) {
        setTimeout(() => {
          this.SET_SKELETON();
        }, 100);
      }
    },
  },
};
</script>

<style module>
.BannerSection {
  width: 100%;
  height: 403px;
  background-color: var(--banner-bg);
}

.BannerBg {
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  min-width: 1000px;
  max-width: 1920px;
  height: 100%;
  background-image: url('@/assets/imgs/main_banner.png');
  background-repeat: no-repeat;
  background-position: center;
}

.BannerBgDark {
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  min-width: 1000px;
  max-width: 1920px;
  height: 100%;
  background-image: url('@/assets/imgs/main_banner_dark.png');
  background-repeat: no-repeat;
  background-position: center;
}

.BannerInner {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 402px;
  /*z-index: 1;*/
}

.BannerTextBox {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 572px;
  gap: 12px;
  margin-bottom: 40px;
}

.BannerTitle {
  font-size: 1.7rem;
  font-weight: 700;
  color: var(--banner-title-color);
  line-height: 40px;
  letter-spacing: -0.27px;
}

.BannerText {
  font-size: 1rem;
  font-weight: 400;
  color: #8e8e93;
  letter-spacing: -0.21px;
  line-height: 22px;
}

.skeletonUi {
  position: absolute;
  top: -80px;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>

<style scoped>
/*fade 애니메이션*/

.fade-enter-active {
  transition: all 0.5s ease-in-out;
}

.fade-enter {
  opacity: 0;
  visibility: hidden;
}
</style>
