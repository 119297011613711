<template>
  <section :class="$style.thirdSection">
    <TheContainer padding="0 4px 0 4px" width="1200px">
      <ul :class="$style.titCont">
        <li :class="$style.areaTitle">
          <h3>{{ _json.home.sectionView.goTo }}</h3>
          <span>※ {{ _json.home.sectionView.withoutLogin }}</span>
          <div
            :class="[$style.showBtn, $style.closeBtn]"
            v-if="showShortcutList"
            @click="[returnShowShortcutList(), scrollToTop()]"
          >
            <span>{{ _json.home.sectionView.collapse }}</span>
            <div></div>
          </div>
          <div :class="[$style.showBtn, $style.openBtn]" v-else @click="returnShowShortcutList">
            <span>{{ _json.home.sectionView.expand }}</span>
            <div></div>
          </div>
        </li>
      </ul>
      <article :class="$style.shortcutList">
        <transition name="slide">
          <div v-if="showShortcutList">
            <ul>
              <li v-for="(list, index) in shortcutDesc" :key="index">
                <strong>{{ list[0].GroupName }}</strong>
                <ul>
                  <li v-for="(item, idx) in shortcutDesc[index]" :key="idx">
                    <a :href="item.Link" target="_blank">{{ item.SiteName }}</a>
                  </li>
                </ul>
              </li>
            </ul>
            <div :class="$style.shortcutInfo">
              {{ _json.home.sectionView.desc }}
            </div>
          </div>
        </transition>
      </article>
    </TheContainer>
  </section>
</template>

<script>
import { getShortcutListApi } from '@/api/module/mainApi';
import { GET_LIST_ACTION } from '@/utils/globalAction';

export default {
  data() {
    return {
      showShortcutList: true,
      // shortcutTitle: ['법원제공 서비스', '업무 관련 서비스', '유관기관 서비스', '법원제공 서비스'],
      shortcutDesc: {},
    };
  },
  async mounted() {
    await this.getShortcutList();
  },
  methods: {
    // 사이트 바로가기 접기 / 펼치기
    returnShowShortcutList() {
      this.showShortcutList = !this.showShortcutList;
    },
    // 사이트 바로가기 리스트 받아오기
    async getShortcutList() {
      const res = await GET_LIST_ACTION(getShortcutListApi(), this._json.hoem.sectionView.listFail);
      this.shortcutDeme = res[0]?.list;
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
};
</script>

<style module>
.thirdSection {
  background-color: var(--third-section-bg);
  min-height: calc(100% - 560px);
}

.titCont {
  width: 992px;
  margin-left: 100px;
  padding: 28px 0;
}

.areaTitle {
  position: relative;
  display: flex;
  align-items: center;
}
.areaTitle h3 {
  color: var(--third-section-title-color);
  font-size: 18px;
  font-weight: 900;
  margin-right: 10px;
}
.areaTitle span {
  color: #86868b;
  font-size: 0.875rem;
  line-height: 0.875rem;
}
.showBtn {
  height: 1.125rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: absolute;
  top: 0;
  right: 0;
  letter-spacing: -0.21px;
  line-height: 1.125rem;
}
.showBtn:hover span {
  cursor: pointer;
  color: var(--primary-color);
  transition: all 0.3s ease-in-out;
}
.showBtn div {
  width: 0.875rem;
  height: 0.875rem;
  background-size: cover;
}
.closeBtn div {
  background-image: url('@/assets/imgs/icon_fold_dark.png');
}
.closeBtn:hover div {
  background-image: url('@/assets/imgs/icon_fold_hover.png');
  transition: all 0.3s ease-in-out;
}
.openBtn div {
  background-image: url('@/assets/imgs/icon_unfold_dark.png');
}
.openBtn:hover div {
  background-image: url('@/assets/imgs/icon_unfold.png');
  transition: all 0.3s ease-in-out;
}
.shortcutList {
  padding: 0.781rem 0 2rem;
  /*min-height: 200px;*/
}

.shortcutList > div > ul {
  width: 1073px;
  display: flex;
  column-gap: 28px;
  height: 100%;
  margin-left: 100px;
}

.shortcutList > div > ul > li {
  width: 248px;
}

.shortcutList li strong {
  color: var(--third-section-title-color);
  line-height: 1rem;
}
.shortcutList li ul {
  padding: 1rem 0;
  color: var(--third-section-desc-color);
}
.shortcutList li ul li {
  cursor: pointer;
  margin-bottom: 0.688rem;
  padding-left: 0.3rem;
  max-width: 240px;
  font-size: 0.875rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shortcutList li ul li:before {
  content: '';
  display: inline-block;
  width: 2px;
  height: 2px;
  margin: -5px 10px 0 0;
  vertical-align: middle;
  background: var(--third-section-desc-color);
  border-radius: 50%;
}
.shortcutInfo {
  margin: 12px 0 18px;
  width: 100%;
  text-align: center;
  font-size: 0.8rem;
  color: #86868b;
}
</style>

<style scoped>
/*슬라이드 애니메이션*/
.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s;
}
.slide-enter,
.slide-leave-to {
  transform: translateY(-30px);
  opacity: 0;
}
</style>
