import Vue from 'vue';
import store from '@/store';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: () => import('@/pages/Index.vue'),
      children: [
        {
          path: '/',
          component: () => import('@/pages/HomePage.vue'),
        },
        {
          path: '/qna',
          component: () => import('@/pages/QnAPage.vue'),
          meta: { auth: true },
        },
        {
          path: '/qna/:id',
          component: () => import('@/pages/QnAPage.vue'),
          meta: { auth: true },
        },
        {
          path: '/notice',
          component: () => import('@/pages/NoticePage.vue'),
        },
        {
          path: '/notice/:id',
          component: () => import('@/pages/NoticePage.vue'),
        },
        {
          path: '/faq',
          component: () => import('@/pages/FaqPage.vue'),
        },
        {
          path: '/faq/:id',
          component: () => import('@/pages/FaqPage.vue'),
        },
        {
          path: '/inquiry',
          component: () => import('@/pages/InquiryPage.vue'),
          meta: { auth: true },
        },
        {
          path: '/download',
          component: () => import('@/pages/FileDownloadPage.vue'),
        },
        {
          path: '/info',
          component: () => import('@/pages/ChangeUserInfoPage.vue'),
          meta: { auth: true },
        },
        {
          path: '/resetpwd',
          component: () => import('@/pages/ResetPwdPage.vue'),
        },
        {
          path: '/firstlogin',
          component: () => import('@/pages/FirstLoginResetPwdPage.vue'),
          // meta: { auth: true },
        },
        {
          path: '*',
          redirect: '/',
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.meta.auth && !store.getters['auth/isLogin']) {
    store.commit('modal/SET_ALERT_VALUE', {
      desc: store.state.common.langJson.common.router.pleaseLogin,
      btnName: store.state.common.langJson.common.router.confirm,
    });
    next('/');
    return;
  }
  next();
});

// NavigationDuplicated 에러방지
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') throw err;
  });
};

export default router;
