<template>
  <article :class="$style.vdProgressContainer">
    <h3 :class="$style.vdProgressTitle">{{ _json.home.vdProgress.progress }}</h3>
    <div :class="$style.vdProgressView">
      <div :class="isFlag === 'a' ? $style.progressCardActive : $style.progressCard">
        <img src="@/assets/imgs/icon_apply.png" />
        <p>{{ _json.home.vdProgress.apply }}</p>
      </div>
      <img src="@/assets/imgs/icon_process.png" />
      <div :class="isFlag === 'd' ? $style.progressCardActive : $style.progressCard">
        <img src="@/assets/imgs/icon_checking.png" />
        <p>{{ _json.home.vdProgress.review }}</p>
      </div>
      <img src="@/assets/imgs/icon_process.png" />
      <div :class="isFlag === 'or' ? $style.progressCardActive : $style.progressCard">
        <img src="@/assets/imgs/icon_confirmed.png" />
        <p>{{ orText }}</p>
      </div>
      <img src="@/assets/imgs/icon_process.png" />
      <div :class="isFlag === 'c' ? $style.progressCardActive : $style.progressCard">
        <img src="@/assets/imgs/icon_apply_complete.png" />
        <p>{{ _json.home.vdProgress.complete }}</p>
      </div>
    </div>
  </article>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'TheVdProgress',
  computed: {
    ...mapState('vd', ['vdProgressData']),
    isFlag() {
      switch (this.vdProgressData[0]?.Req_Flag) {
        case 'r':
          return 'or';
        case 'd':
          return 'd';
        case 'o':
          return 'or';
        case 'c':
          return 'c';
        default:
          return 'a';
      }
    },
    orText() {
      switch (this.vdProgressData[0]?.Req_Flag) {
        case 'r':
          return this._json.home.vdProgress.rejected;
        case 'o':
          return this._json.home.vdProgress.approved;
        default:
          return this._json.home.vdProgress.app_rej;
      }
    },
  },
  async mounted() {
    // await this.getIntervalProgress();
    await this.GET_PROGRESS_VD();
  },
  methods: {
    ...mapMutations('common', ['SET_INTERVAL_FUNC']),
    ...mapActions('vd', ['GET_PROGRESS_VD']),
    getIntervalProgress() {
      // progress정보 지속적으로 받아오기: 5초
      const progressVdFunc = setInterval(async () => {
        await this.GET_PROGRESS_VD();
      }, 5000);
      this.SET_INTERVAL_FUNC({ title: 'progressVd', func: progressVdFunc });
    },
  },
};
</script>

<style module>
.vdProgressContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 25px;
  padding: 40px 21px;
  width: 490px;
  height: 253px;
  background-color: var(--vdprogress-container-bg);
  box-shadow: 0 4px 8px #0000001f;
  border-radius: 4px;
}

.vdProgressTitle {
  color: var(--vdprogress-container-title);
  font-size: 1.2rem;
  font-weight: 900;
}

.vdProgressView {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.progressCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0 8px;
  width: 86px;
  height: 132px;
  font-weight: bold;
  border-radius: 8px;
  color: #c8c8c8;
  opacity: 0.4;
}

.progressCardActive {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0 8px;
  width: 86px;
  height: 132px;
  font-weight: bold;
  border-radius: 8px;
  background: var(--vdprogress-cardActive-bg);
  color: #003f86;
}

.progressCardActive p {
  position: relative;
}

.progressCardActive p::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2.5px;
  background: #003f86;
}
</style>
