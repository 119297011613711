<template>
  <!-- [ ==================== Login Box 컴포넌트 ==================== ] -->
  <article :class="$style.loginContainer">
    <h3 :class="$style.loginTitle">{{ _json.home.login.directVd }}</h3>
    <form :class="$style.loginForm" @submit.prevent="Login">
      <div :class="$style.loginWrap">
        <div :class="$style.loginInputWrap">
          <input
            :class="$style.loginInput"
            type="text"
            :placeholder="_json.home.login.id"
            :value="id"
            @input="replaceWord($event)"
            required
            name="id"
          />
          <input
            :class="$style.loginInput"
            type="password"
            :placeholder="_json.home.login.pwd"
            :value="pw"
            @input="replaceWord($event)"
            autoComplete="off"
            required
            name="pw"
          />
        </div>
        <div :class="$style.loginButtonWrap">
          <div :class="$style.loginCheckBox">
            <input type="checkbox" id="checksaveid" v-model="checked" />
            <label
              :class="darkMode ? $style.checkCustomDark : $style.checkCustom"
              for="checksaveid"
            />
            <div :class="darkMode ? $style.checkTextDark : $style.checkText">
              {{ _json.home.login.saveId }}
            </div>
          </div>
          <button
            v-if="smtpState === '1'"
            :class="{
              [$style.loginResetBtnDark]: darkMode !== false,
              [$style.loginResetOtherBtnDark]: darkMode !== false && language === 'KR',
              [$style.loginResetBtn]: darkMode === false,
              [$style.loginResetOtherBtn]: language !== 'KR',
            }"
            type="button"
            @click="resetPassword"
          >
            {{ _json.home.login.resetPwd }}
          </button>
        </div>
      </div>
      <button :class="$style.loginBtn" type="submit" :disabled="isLoading || !id || !pw">
        {{ _json.home.login.login }}
      </button>
    </form>
  </article>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'TheLogin',
  data() {
    return {
      id: localStorage.getItem('saveId') ? localStorage.getItem('saveId') : '',
      pw: '',
      checked: localStorage.getItem('saveId'),
    };
  },
  computed: {
    ...mapState('common', ['isLoading', 'darkMode', 'smtpState', 'language']),
    ...mapState('auth', ['user']),
  },
  methods: {
    ...mapActions('auth', ['LOGIN']),
    ...mapMutations('modal', ['SET_MODAL_OPEN']),
    // 실시간 입력값 감시
    async replaceWord(event) {
      // -> 한글 입력 불가
      // event.target.value = event.target.value.replace(/[ㄱ-ㅎㅏ-ㅣ가-힣]/g, '');
      this[event.target.name] = event.target.value;
    },
    // 로그인
    async Login() {
      await this.saveId();
      await this.LOGIN({
        id: this.id,
        pw: this.pw,
      });
      // this.id = '';
      this.pw = '';
    },
    // 비밀번호 초기화
    async resetPassword() {
      await this.SET_MODAL_OPEN('authentication');
    },
    // 아이디 기억하기
    saveId() {
      if (this.checked) {
        localStorage.setItem('saveId', this.id);
      } else {
        localStorage.removeItem('saveId');
      }
    },
  },
};
</script>

<style module>
.loginContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 20px;
  padding: 25px 24px;
  width: 420px;
  height: 204px;
  background-color: var(--login-container-bg);
  box-shadow: 0 4px 8px #0000001f;
  border-radius: 4px;
  font-size: 1.2rem;
}

.loginTitle {
  color: var(--login-container-title);
  font-weight: 900;
}

.loginForm {
  flex: 1;
  display: flex;
  gap: 12px;
}

.loginBtn {
  width: 100px;
  height: 127.7px;
  border: none;
  border-radius: 2px;
  background-color: #005bc1;
  font-size: 1.2rem;
  color: #f5f5f7;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.loginBtn:disabled {
  background-color: var(--login-btn-bg);
  color: var(--login-btn-color);
  cursor: default;
}

.loginBtn:not(:disabled):hover {
  background: #004da4;
  color: #f8f8f8;
  cursor: pointer;
}

.loginWrap {
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
}

.loginInputWrap {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}

.loginInput {
  padding: 12px 11px;
  width: 100%;
  height: 40px;
  background-color: var(--input-bg-color);
  border-radius: 2px;
  border: 1px solid var(--input-border-color);
  color: var(--table-td-color);
  font-size: 0.8rem;
}

.loginInput:hover {
  border: 1px solid #005bc1;
}
.loginInput:focus {
  border: 1px solid #005bc1;
  outline: none;
}
.loginInput:-webkit-autofill,
.loginInput:-webkit-autofill:hover,
.loginInput:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px var(--input-bg-color) inset;
}

.loginButtonWrap {
  display: flex;
  justify-content: space-between;
}

.loginCheckBox {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

.loginCheckBox input {
  display: none;
}

.loginCheckBox input:checked + .checkCustom {
  background: url('@/assets/imgs/icon_checkbox_checked.png') no-repeat;
}
.loginCheckBox input:checked ~ .checkText {
  color: var(--login-active-checkText);
}

.loginCheckBox input:checked + .checkCustomDark {
  background-color: #f8f8f8;
}

.loginCheckBox input:checked + .checkCustomDark:after {
  content: '✔';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  color: #0078ff;
  text-align: center;
  font-size: 15px;
  transform: translate(-50%, -50%);
}

.loginCheckBox input:checked ~ .checkTextDark {
  color: #fff;
}

.loginCheckBox .checkCustomDark:hover ~ .checkTextDark {
  color: #fff;
}

.checkCustom {
  display: block;
  width: 20px;
  height: 20px;
  border: 1px gray;
  /*border: var(--login-checkbox-border-width) solid var(--login-checkbox-border);*/
  border-radius: 2px;
  background: url('@/assets/imgs/icon_checkbox_none.png') no-repeat;
  cursor: pointer;
}

.checkCustom:hover {
  background: url('@/assets/imgs/icon_checkbox_hover.png') no-repeat;
}

.checkCustomDark {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  border: 2px solid #006dec;
  border-radius: 2px;
  background-color: #3a3a3d;
  cursor: pointer;
}

.checkCustomDark:hover {
  background-color: #6c6c6d;
}

.checkText {
  display: flex;
  align-items: center;
  margin-left: 6px;
  color: #86868b;
  font-size: 0.8rem;
  transition: all 0.3s ease-in-out;
}

.checkTextDark {
  display: flex;
  align-items: center;
  margin-left: 6px;
  color: #86868b;
  font-size: 0.8rem;
  transition: all 0.3s ease-in-out;
}

.loginResetBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 106px;
  height: 32px;
  border: 1px solid #d4d4d4;
  border-radius: 2px;
  background-color: #f6f6f6;
  font-size: 0.8rem;
  color: #86868b;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.loginResetOtherBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 32px;
  border: 1px solid #d4d4d4;
  border-radius: 2px;
  background-color: #f6f6f6;
  font-size: 0.8rem;
  color: #86868b;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.loginResetBtn:hover {
  background-color: #dfdfdf;
  color: #4c4c4d;
}

.loginResetBtnDark {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 32px;
  border: 1px solid #48484a;
  border-radius: 2px;
  background-color: #666;
  font-size: 0.8rem;
  color: #c8c8c8;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.loginResetOtherBtnDark {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 106px;
  height: 32px;
  border: 1px solid #48484a;
  border-radius: 2px;
  background-color: #666;
  font-size: 0.8rem;
  color: #c8c8c8;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
</style>
