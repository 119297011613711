import { instance } from '@/api';

// 사이트 바로가기 리스트
export const getShortcutListApi = () => {
  return instance.post('/user/main/sitelist', { test: '' });
};

// 비밀번호 초기화
export const sendSecretKeyApi = data => {
  return instance.post('/user/GetSecureCode', data);
};

export const checkSecretKeyApi = data => {
  return instance.post('/user/verifyCode', data);
};

export const resetPwdApi = data => {
  return instance.post('/user/setPassword', data);
};

// 정보변경
export const getSecureCodeApi = data => {
  return instance.post('/user/updateinfo/getSecureCode', data);
};

export const updateEmailApi = data => {
  return instance.post('/user/updateinfo/email', data);
};

export const updateBirthdayApi = data => {
  return instance.post('/user/updateinfo/birthday', data);
};

export const verifyCodeApi = data => {
  return instance.post('/user/updateinfo/verifyCode', data);
};

// 메인 팝업 =====================================================
export const getPopupInfoBeforeApi = data => {
  return instance.post('/user/main/info/modal', data);
};

export const getPopupInfoAfterApi = data => {
  return instance.post('/user/main/info/popup', data);
};

// qna 팝업
export const qnaReadApi = data => {
  return instance.post('/user/inquiry/read', data);
};

// 자원신청 현황 팝업
export const progressReadApi = data => {
  return instance.post('/user/request/read', data);
};

// vd 만료일 팝업
export const vdExpiredApi = data => {
  return instance.post('/user/expiredNotice', data);
};

// vd 접속제한
export const vdAccessApi = () => {
  return instance.post('/user/restrictedNotice');
};

// SMTP 사용여부 확인
export const checkSmtpApi = () => {
  return instance.post('/user/smtp/info');
};

// 메인 배너 타이틀, 문구
export const getBannerList = () => {
  return instance.post('/custom/mainpageinfo');
};
export const getLogoCI = () => {
  return instance.post('/custom/fileinfo');
};
export const getDarkLogoCI = () => {
  return instance.post('/custom/fileinfo/darkmode');
};
