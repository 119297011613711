import {
  getPopupInfoAfterApi,
  getPopupInfoBeforeApi,
  vdAccessApi,
  vdExpiredApi,
} from '@/api/module/mainApi';
import { openNoticeContentApi } from '@/api/module/subPageApi';
import { getCookie } from '@/utils/cookie';
import store from '@/store';

const modalStore = {
  namespaced: true,
  state: {
    modalList: {
      // 로그인전
      authentication: { index: 300, value: false },
      userInfo: { index: 300, value: false },
      // 로그인후
      informationRegist: { index: 300, value: false },
      vdAccess: { index: 300, value: false },
      // 기타
      alert: {
        index: 500,
        value: false,
        desc: '',
        btnName: '',
      },
      alertConfirm: {
        index: 500,
        value: false,
        desc: '',
        btnName: '',
        confirmFunc: function () {},
      },
      smtpFalse: { index: 300, value: false },
      notice: { index: 300, value: false },
      aTest: { index: 300, value: false },
      timerModal: { index: 400, value: false, UserID: '', login: false, success: false },
    },
    popupInfo: {
      // 1:1 문의 팝업 개수
      // InquiryPopUpCount: 0,
      // 1:1 문의 팝업 유무
      // InquiryPopUpFlag: false,
      // 공지 팝업 개수
      // NoticePopUpCount: 1,
      // 공지 팝업 유무
      // NoticePopUpFlag: true,
      // 띄워야 하는 공지 팝업의 seq number
      // NoticeSeqList: '49',
      // 자원요청 팝업 유무
      // RequestPopUpFlag: false,
      // 쟈원요청 팝업 seq number
      // RequestPopUpSeq: null,
      // Vd할당받은 계정인지
      // AllocatedVDFlag: true
    },
    noticeModalList: [
      // popupinfo 에 있는 공지사항 팝업 seq번호 리스트
    ],
    noticeModalData: [
      // 공지사항 팝업 seq번호로 받아온 공지사항 데이터 리스트
    ],
    noticeModalCloseList: [],
    vdAccessModalData: {
      // Description: '사용기간이 만료된 vd에 대한 안내문',
      // 만료 안내문 플래그
      // dateExpired: 2,
      // Detail: '가상 pc가 만료 되었습니다.....',
    },
    vdExpiredModalData: {
      // Description: '사용기간이 만료된 vd에 대한 안내문',
      // 만료 안내문 플래그
      // dateExpired: 2,
      // Detail: '가상 pc가 만료 되었습니다.....',
    },
  },
  getters: {
    isModalState(state) {
      return Object.values(state.modalList).some(item => item.value === true);
    },
    isTimerModal(state) {
      return state.modalList.timerModal.value;
    },
    isTimerModalSuccess(state) {
      return state.modalList.timerModal.success;
    },
    isAlertModal(state) {
      return state.modalList.alert.value;
    },
  },
  mutations: {
    // 모달띄우기
    SET_MODAL_OPEN(state, name) {
      if (name !== 'alert' && name !== 'alertConfirm' && name !== 'timerModal') {
        state.modalList[name].index = 400;
      }
      state.modalList[name].value = !state.modalList[name].value;
    },
    // 모달창 index 설정하기 : 현재는 안쓰임
    SET_MODAL_INDEX(state, { name }) {
      if (name && name === 'alert' && name === 'alertConfirm' && name === 'timerModal') {
        state.modalList[name].index = 400;
        for (let modal in state.modalList) {
          state.modalList[modal].index = 300;
        }
      }
    },
    // Alert 안내모달 띄우기
    SET_ALERT_VALUE(state, { desc, btnName }) {
      if (state.modalList.alert.value) {
        state.modalList.alert.desc = '';
        state.modalList.alert.btnName = store.state.common.langJson.modules.modal.confirm;
        state.modalList.alert.value = false;
      }
      if (!state.modalList.alert.value) {
        state.modalList.alert.desc = desc;
        btnName !== undefined
          ? (state.modalList.alert.btnName = btnName)
          : (state.modalList.alert.btnName = store.state.common.langJson.modules.modal.confirm);
        state.modalList.alert.value = true;
      }
    },
    // AlertConfirm 안내모달 띄우기
    SET_ALERTCONFIRM_VALUE(state, { desc, btnName, func }) {
      if (state.modalList.alertConfirm.value) {
        state.modalList.alertConfirm.desc = '';
        state.modalList.alertConfirm.btnName = store.state.common.langJson.modules.modal.confirm;
        state.modalList.alertConfirm.value = false;
        state.modalList.alertConfirm.confirmFunc = function () {};
      }
      if (!state.modalList.alertConfirm.value) {
        state.modalList.alertConfirm.desc = desc;
        btnName !== undefined
          ? (state.modalList.alertConfirm.btnName = btnName)
          : (state.modalList.alertConfirm.btnName =
              store.state.common.langJson.modules.modal.confirm);
        state.modalList.alertConfirm.value = true;
        func !== undefined
          ? (state.modalList.alertConfirm.confirmFunc = func)
          : state.modalList.alertConfirm.confirmFunc;
      }
    },
    SET_TIMER_MODAL(state, { UserID, login }) {
      if (state.modalList.timerModal.value) {
        // state.modalList.timerModal.UserID = '';
        state.modalList.timerModal.value = false;
        state.modalList.timerModal.login = false;
        return;
      }
      UserID === undefined
        ? (state.modalList.timerModal.UserID = '')
        : (state.modalList.timerModal.UserID = UserID);
      login === undefined
        ? (state.modalList.timerModal.login = false)
        : (state.modalList.timerModal.login = login);
      state.modalList.timerModal.success = false; // 타이머 모달 결과값 초기화
      state.modalList.timerModal.value = true;
    },
    SET_TIMER_SUCCESS(state) {
      state.modalList.timerModal.success = true;
    },
    // 사용자에 따른 각 팝업창 정보 받아오기
    SET_POPUP_INFO(state, output) {
      state.popupInfo = output;

      // 공지사항 팝업 seq 리스트 세팅
      if (state.popupInfo?.NoticePopUpCount >= 1) {
        let newList = state.popupInfo?.NoticeSeqList.split(',').reverse();

        // 닫기 버튼을 누른 공지사항 리스트 제거
        if (state.noticeModalCloseList.length > 0) {
          newList = newList.map(value => value.trim());

          newList = newList.filter(value => !state.noticeModalCloseList.includes(value));
        }

        state.noticeModalList = newList;
      } else {
        state.noticeModalList = [];
      }
    },
    // 공지사항 팝업 데이터 닫기
    DELETE_NOTICEMODAL_DATA(state, seq) {
      state.noticeModalCloseList.push(seq);
    },
    SET_NOTICEMODAL_DATA(state, data) {
      state.noticeModalData = data;
    },
    SET_VD_EXPIREDMODAL_DATA(state, output) {
      // 만료일정 안내 모달
      state.vdExpiredModalData = output;
      if (state.vdExpiredModalData?.dateExpired === 1) {
        state.vdExpiredModalData.vdExpiredModal = true;
      }

      // 만료안내 모달
      state.vdAccessModalData = output;
      if (state.vdAccessModalData?.dateExpired === 2) {
        state.modalList.vdAccess.index = 400;
        state.modalList.vdAccess.value = false;
      }
    },
    // 접근제한 안내 모달
    SET_VD_ACCESSMODAL_DATA(state, output) {
      if (output) {
        state.vdAccessModalData = output;
      }
      state.modalList.vdAccess.index = 400;
      state.modalList.vdAccess.value = true;
    },
    // 모든 모달창 닫는 로직
    SET_ALL_MODAL_CLOSE(state) {
      for (const key in state.modalList) {
        state.modalList[key].value = false;
      }
      state.popupInfo.InquiryPopUpFlag = false;
      state.popupInfo.NoticePopUpFlag = false;
      state.popupInfo.RequestPopUpFlag = false;
    },
  },
  actions: {
    // 사용자 팝업정보 받아오기
    async GET_POPUP_INFO({ commit, rootState, dispatch }) {
      try {
        let res = {};

        // 로그인 후
        if (rootState.auth.user?.UserAlias) {
          res = await getPopupInfoAfterApi({
            userId: rootState.auth.user?.UserAlias ? rootState.auth.user?.UserAlias : '',
          });
        }

        // 로그인 전
        if (!rootState.auth.user?.UserAlias) {
          res = await getPopupInfoBeforeApi();
        }

        switch (res.data.status) {
          case '5000':
          case '0000':
            commit('SET_POPUP_INFO', res.data.output[0]);
            break;
          default:
            break;
        }
      } catch (e) {
        console.error(e);
      }
    },
    // 공지사항 팝업창 정보 받아오기
    async FETCH_NOTICE_MODALDATA({ commit, state, rootState }) {
      try {
        let arr = [];
        let status = true;

        for (let seq of state.noticeModalList) {
          const { data } = await openNoticeContentApi({
            userId: rootState.auth.user?.UserAlias ? rootState.auth.user?.UserAlias : '',
            seq: seq.trim(),
          });

          switch (data.status) {
            case '0000':
            case '5000':
              // 값 있는지 비교
              if (data.output[0] === undefined) {
                status = false;
              }

              // 쿠키 저장안된 값만 배열 넣기
              getCookie(
                `dontShow${seq.trim()}${
                  rootState.auth.user.UserAlias ? rootState.auth.user.UserAlias : ''
                }`,
              ) !== 'on' && data.output[0]?.BoardSeq
                ? arr.push(data.output[0])
                : null;

              // state에 arr 넣기
              await commit('SET_NOTICEMODAL_DATA', arr);
              status = true;
              break;
            case '0001':
            case '5001':
            case '0004':
            case '5004':
              status = false;
              break;
            default:
              status = false;
              break;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    // VD만료 팝업 정보 받아오기
    async FETCH_VD_EXPIRED_MODALDATA({ state, commit, rootState }, vdname) {
      try {
        const vdExpiredModalState = getCookie(
          `vdExpiredModal${rootState.auth.user.UserAlias ? rootState.auth.user.UserAlias : ''}`,
        );

        const { data } = await vdExpiredApi({
          VDName: vdname ? vdname : '',
        });
        switch (data.status) {
          case '0000':
          case '5000':
            // VD만료일정 안내모달 set: 쿠키값 저장 안된것만 데이터 호출
            // VD만료 안내모달 set
            if (
              (data.output?.dateExpired === 1 && vdExpiredModalState !== 'on') ||
              data.output?.dateExpired === 2
            ) {
              commit('SET_VD_EXPIREDMODAL_DATA', data.output);
              return;
            }

            commit('SET_VD_EXPIREDMODAL_DATA', {});
            return;
          case '0001':
          case '5001':
          case '0004':
          case '5004':
            commit('SET_VD_EXPIREDMODAL_DATA', {});
            return;
          default:
            commit('SET_VD_EXPIREDMODAL_DATA', {});

            return;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // VD접속 제한 팝업 정보 받아오기
    async FETCH_VD_ACCESS_MODALDATA({ commit }) {
      try {
        const { data } = await vdAccessApi();
        switch (data.status) {
          case '0000':
          case '5000':
            commit('SET_VD_ACCESSMODAL_DATA', data.output);
            return;
          case '0001':
          case '5001':
          case '0004':
          case '5004':
            return;
          default:
            return;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};

export default modalStore;
