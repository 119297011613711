<template>
  <TheModal
    :title="_json.modal.alert.notice"
    name="alert"
    width="500px"
    height="auto"
    @isSubmit="modalClose"
  >
    <template #content>
      <p :class="$style.desc" v-html="modalList.alert?.desc" />
    </template>
    <template #btn>
      <TheButton type="submit" ref="submitBtn"> {{ modalList.alert?.btnName }} </TheButton>
    </template>
  </TheModal>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  computed: {
    ...mapState('modal', ['modalList']),
  },
  mounted() {
    this.setFocus();
  },
  methods: {
    ...mapMutations('modal', ['SET_MODAL_OPEN']),
    modalClose() {
      this.SET_MODAL_OPEN('alert');
    },
    setFocus() {
      this.$refs.submitBtn.$el.focus();
    },
  },
};
</script>

<style module>
.desc {
  line-height: 1.5rem;
  white-space: pre-wrap;
}

.desc > small {
  font-size: 0.875rem;
  font-weight: 400;
}
</style>
