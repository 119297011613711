export const language_JP = {
  common: {
    header: {
      title: 'CenterPost',
      darkMode: 'ダークモード',
      internetPc: 'インターネット仮想PC',
      notice: 'お知らせ',
      welcomeText: ' 様を歓迎します。',
      logoutText: 'ログアウト',
      networkError: 'ネットワークエラーが発生しました。',
      inquiry: '1:1お問い合わせ',
      infoChange: '情報変更',
      download: 'ダウンロード',
    },
    listCard: {
      viewMore: 'もっと見る',
      noPosts: '登録された投稿がありません。',
    },
    accordionTr: {
      imgPreview: '画像プレビュー',
      attachment: '添付ファイル',
    },
    noticeModal: {
      attachment: '添付ファイル',
      stopToday: '今日はもう見ない',
      stopWeek: '７日間見ない',
      imgPreview: '画像プレビュー',
      close: '閉じる',
      general: '一般',
      urgent: '緊急',
    },
    qnaAccordionTr: {
      imgPreview: '画像プレビュー',
    },
    file: {
      selected: 'ファイル選択',
      noSelected: '選択されたファイルなし',
    },
    idx: {
      anotherTab: '別のタブでログインしたためログアウトしました。',
    },
    interceptors: {
      retryLogin: '接続時間が満了し、ログアウトしました。再度ログインしてください。',
    },
    router: {
      pleaseLogin: '別のタブでログインしたためログアウトしました。',
      confirm: '確認',
    },
    navList: [
      {
        to: '/',
        name: 'インターネット仮想PC',
      },
      {
        to: '/qna',
        name: '1:1お問い合わせ',
      },
      {
        to: '/notice',
        name: 'お知らせ',
      },
      {
        to: '/faq',
        name: 'FAQ',
      },
    ],
  },
  home: {
    banner: {
      title: 'CenterPost ユーザーポータルです。',
      desc: `VD接続ボタンをクリックすると、<br/>自動的に接続クライアントのインストールファイルがダウンロードされます。<br/>詳しい使用方法はマニュアルをご確認ください。<br/>その他のお問い合わせは、1：1お問い合わせページからお問い合わせください。`,
      error: 'ネットワークエラーが発生しました。',
    },
    secondSection: {
      notices: 'お知らせ',
      all: '全体',
      general: '一般',
      urgent: '緊急',
      inquiryAnswer: '1:1お問い合わせの回答が完了しました。',
      goTo: 'ショートカット',
      close: '閉じる',
      annae: 'ご案内',
      confirm: '確認する',
      failFaqList: 'FAQリストの取得に失敗しました。',
      failNoticeList: 'Noiceリストの取得に失敗しました。',
      failInquiryList: '1:1質問の回答の読み込みに失敗しました。',
    },
    cloud: {
      pcStatus: 'PC状態：',
      pcStatusDesc: '',
      wouldRestart: 'インターネット仮想PCを再起動しますか？',
      miscellaneous: 'その他',
      running: '起動中 ',
      stopped: '停止中',
      save: '保存する',
      pause: '一時停止',
      starting: '起動中',
      restart: '再起動',
      normalGroup: 'グループ正常',
      abnormal: '異常',
      expirationDate: '有効期限',
      remainingPeriod: '残り期間',
      notice: 'お知らせ',
      welcome: 'ようこそ、楽しい一日をお過ごしください。',
    },
    login: {
      directVd: 'インターネット仮想PCに接続する',
      id: 'ユーザーIDを入力してください',
      pwd: 'パスワードを入力してください',
      saveId: 'IDを保存する',
      resetPwd: 'パスワードの初期化',
      login: 'ログイン',
    },
    vdApp: {
      noVd: '利用可能なインターネット仮想PCがありません。',
      internetVd: 'インターネット仮想PC申請する',
      reclaimedVd: `インターネット仮想PCに3ヶ月以上未接続の場合、回収されます。<br>必要な場合は、新たにインターネット仮想PCを申請してください。`,
      successVd: 'インターネット仮想PCの申請に成功しました。',
      errorVd: 'VD申請中にエラーが発生しました。',
    },
    vdProgress: {
      progress: 'インターネット仮想PCの申請状況',
      apply: '申請',
      review: '検討',
      complete: '完了',
      rejected: '拒否',
      approved: '承認',
      app_rej: '承認/拒否',
    },
    sectionView: {
      goTo: 'サイトを見る',
      withoutLogin: '下記のサービスは、インターネット仮想PCログインなしでご利用いただけます。',
      collapse: '閉じる',
      expand: '展開する',
      desc: `※ 電子家族関係登録、政府24、国税庁(年末調整)サービスは、韓国の裁判所で発行された<br>裁判所行政電子署名(GPKI)で本人確認を行い、証明書の発行が可能です。`,
      listFail: 'サイトのショートカットリストの取得に失敗しました。',
    },
  },
  firstLoginResetPwd: {
    changePwd: 'パスワード変更',
    enterNewPWd: '新しく使用するパスワードを入力してください。',
    pwdPolicyDesc: `ユーザーのパスワードを変更します。<br>(1を含む,最小2文字以上,最大3文字以下)`,
    id: 'ユーザーID',
    currentPwd: '現在のパスワード',
    enterCurrentPWd: '現在のパスワードを入力してください。',
    unableToChangePwd_policy: 'パスワードポリシーを取得できないため、変更できません。',
    newPwd: '新しいパスワード',
    enterNewPwd2: '新しいパスワードを入力してください。',
    securityLevel: 'セキュリティレベル：',
    availableChar: '使用可能な特殊文字',
    confirmPwd: 'パスワード確認',
    reenterNewPwd: '新しいパスワードを再入力してください。',
    notMatchPwd: 'パスワードが一致しません。',
    currentPwdNewPwdSame: '現在のパスワードと新しいパスワードが同じです。',
    change: '変更',
    refreshingDesc: '更新するとパスワードの変更が最初からやり直しになります。',
    en_num: '英数字',
    en_num_char: '英数字/特殊文字',
    en_num_char_3TypesOrMore: '英大文字/小文字/数字/特殊文字のうち3つ以上含む',
    low: '低い',
    medium: '普通',
    safe: '安全',
    failPwdPolicy: 'パスワードポリシーを取得できませんでした。',
    enterAllVal: 'すべての値を入力してください。',
    confirm: '確認',
    infoChangeSuccess: '情報変更に成功しました。',
    infoChangeFail: '情報変更に失敗しました。',
    courtNetMailIdFail: `パスワード初期化のためのコートネットメールID情報が間違って入力されました。M<br>コートネットメールIDがない場合は、メールをスキップするをチェックしてください。`,
    notMatch_newPwd_ConfirmPwd: '新しいパスワードと確認用パスワードが異なります。',
  },
  changeUserInfo: {
    infoChange: '情報変更',
    currentPwd_newPwd: '現在のパスワードを入力後、新たに使用するパスワードを入力してください。',
    changePwd: 'パスワード変更',
    personalInfoChange: '個人情報変更',
    changeUserPwd: `ユーザーのパスワードを変更します。<br>(1を含む,最小2文字以上,最大3文字以下)`,
    id: 'ユーザーID',
    currentPwd: '現在のパスワード',
    enterCurrentPwd: '現在のパスワードを入力してください。',
    pwdPolicyCouldNotRetrieved: 'パスワードポリシーを取得できず、変更できません。',
    newPwd: '新しいパスワード',
    enterNewPwd: '新しいパスワードを入力してください。',
    securityLevel: 'セキュリティレベル：',
    availableChar: '使用可能な特殊文字',
    confirmPWd: 'パスワード確認',
    reenterNewPwd: '新しいパスワードを再入力してください。',
    pwdMatchFail: 'パスワードが一致しません。',
    currentPwdNewPwdSame: '現在のパスワードと新しいパスワードが同じです。',
    change: '変更する',
    changeUserName_birthday: 'ユーザーのメールアドレスと生年月日を変更します。',
    birthday: '生年月日',
    birthdayEg: '例)950307',
    email: 'メールアドレス',
    emailNotRegi: 'メール未登録',
    emailEg: '例)jshwang',
    emailEg2: '例)jshwang@tilon.com',
    authComplete: '認証完了',
    authIncomplete: '認証未完了',
    skipEmail: 'メールスキップ',
    sendAuthEmail: '認証メール送信',
    change2: '変更する',
    skipDesc: `生年月日とメールアドレスの入力をスキップすると、<br>パスワード初期化機能をご利用いただけません。`,
    confirm: '確認する',
    en_num: '英数字',
    en_num_char: '英数字/特殊文字',
    en_num_char_3TypesOrMore: '英大文字/小文字/数字/特殊文字のうち3つ以上',
    low: '低い',
    medium: '普通',
    safe: '安全',
    failPwdPolicy: 'パスワードポリシーを取得できませんでした。',
    enterAllVal: 'すべての値を入力してください。',
    infoChangeSuccess: '情報変更に成功しました。',
    infoChangeFail: '情報変更に失敗しました。',
    notMeetPwdPolicy: 'パスワードポリシーを満たしていません。',
    notMatch_newPwd_ConfirmPwd: '新しいパスワードと確認用パスワードが異なります。',
    enterOnlyNum: '生年月日は数字のみ入力可能です。',
    enterEmail: 'メールアドレスを入力してください。',
    noMeetEmailPolicy: '入力されたメールアドレスがフォームに合いません。',
    noKor: 'メールアドレスにハングルは入力できません。',
    failResetPwdToIncorrectMailId: `パスワードを初期化するためのメールアドレスが間違って入力されました。<br>メールアドレスがない場合は、メールをスキップするをチェックしてください。`,
    failSendMailDesc: `認証メール送信に失敗しました。管理者にお問い合わせください。`,
    success: '成功',
    fail: '失敗',
  },
  resetPwd: {
    changePwd: 'パスワードの変更',
    enterNewPwd: '新しいパスワードを入力してください。',
    pwdPolicy: `パスワードを変更します。<br>(1を含む、最小2文字以上、最大3文字以下)`,
    newPwd: '新しいパスワード',
    enterNewPwd2: '新しいパスワードを入力してください。',
    failPwdPolicy: 'パスワードポリシーを取得できず、変更できません。',
    securityLevel: 'セキュリティレベル：',
    availableChar: '使用可能な特殊文字',
    confirmPwd: 'パスワード確認',
    reenterNewPwd: '新しいパスワードを再入力してください。',
    failPwdPolicy2: 'パスワードポリシーをインポートできず、変更できません。',
    notMatchPwd: 'パスワードが一致しません。',
    change: '変更する',
    en_num: '英数字',
    en_num_char: '英数字/特殊文字',
    en_num_char_3TypesOrMore: '英大文字/小文字/数字/特殊文字のうち3つ以上含む',
    low: '低',
    medium: '普通',
    safe: '安全',
    infoChangeFail: '情報変更に失敗しました。',
    infoChangeSuccessRetryLogin: `情報変更に成功しました。<br>再度ログインをお試しください。`,
    refreshingDesc: '更新するとパスワードの初期化が最初からやり直しになります。',
    failPwdPolicy3: 'パスワードポリシーをインポートできませんでした。。',
    enterAllVal: 'すべての値を入力してください。。',
    failPwdValidity: 'パスワードの有効性制約条件に違反したため、<br>情報変更に失敗しました。',
  },
  email: {
    enterCode: 'パスワード初期化画面に下記の認証番号を入力してください。',
    authCode: '認証番号：',
    emailDesc: '本メールは送信専用です。ご返信またはお問い合わせいただいてもご返答ができません。',
    contactTilon:
      'インターネット仮想PCに関するお問い合わせは、Tilon(02-2627-9000)までお問い合わせください。',
  },
  notice: {
    noPosts: '登録された投稿がありません。',
    num: '番号',
    notices: 'お知らせ',
    title: 'タイトル',
    regiDate: '登録日',
    all: '全体',
    general: '一般',
    urgent: '緊急',
    failList: 'noticeリストの読み込みに失敗しました。',
    failPagination: 'noticeリストのページネーションを取得するのに失敗しました。',
    dropdown: {
      columns: ['番号', 'タイトル', '登録日'],
    },
  },
  faq: {
    noRegiPosts: '登録された投稿がありません。',
    num: '番号',
    title: 'タイトル',
    regiDate: '登録日',
    listFail: 'FAQリストの読み込みに失敗しました。',
    paginationFail: 'FAQリストのページネーションを取得するのに失敗しました。',
    dropdown: {
      columns: ['番号', 'タイトル', '登録日'],
    },
  },
  qna: {
    noRegiPosts: '登録された投稿がありません。',
    inquiry: '1:1お問い合わせ',
    responseComplete: '回答完了',
    waitingForRes: '回答待ち',
    delIcon: '削除アイコン',
    writeInquiry: 'お問い合わせ登録',
    num: '番号',
    title: 'タイトル',
    writingDate: '登録日',
    resStatus: '回答状況',
    delete: '削除',
    failCheck: '1:1お問い合わせのチェックに失敗しました。',
    confirm: '確認',
    fileList: '1:1お問い合わせのリストを取得できませんでした。',
    failPagination: '1:1お問い合わせのページネーションを取得できませんでした。',
    deleteConfirm: `1:1質問を削除します。<br><br>削除すると、<br>作成した投稿や回答の内容は復元できません。`,
    failDelete: 'お問い合わせの削除に失敗しました。',
    dropdown: {
      columns: ['番号', 'タイトル', '登録日', '回答状況', '削除'],
    },
  },
  inquiry: {
    inquiryTitle: 'タイトル',
    writeContent: 'お問い合わせ内容入力',
    enterTitle: 'タイトルを入力してください。',
    content: 'お問い合わせ内容',
    enterContent: 'お問い合わせ内容を入力してください。',
    charCnt: '/ 500文字',
    attachment: '添付ファイル',
    attachmentDesc: '添付ファイル容量制限:最大10MB、最大1個まで添付可能。画像ファイルのみ添付可能',
    register: '登録',
    cancel: 'キャンセル',
    titleCntDesc: 'お問い合わせのタイトルは40文字以内で作成可能です。',
    confirm: '確認する',
    contentCntDesc: 'お問い合わせ内容は500文字以内で作成可能です。',
    onlyImg: '画像ファイルのみアップロード可能です。',
    fileSize: '添付ファイルサイズは20MB以内で登録可能です。',
    blankTitle: 'お問い合わせのタイトルを入力してください。',
    registrationComplete: '1:1お問い合わせの登録が完了しました。',
    registerFail: '1:1お問い合わせの登録に失敗しました。',
  },
  fileDownload: {
    download: 'ダウンロード',
    installClientDesc: '仮想PCに接続するためのクライアントをダウンロードします。',
    connectionClient: '接続クライアント',
    clientDesc: '仮想PCに接続するためのクライアントです。',
    installDesc: 'インターネット仮想PC使用のためのプログラムをインストールします。',
    internetProgram: 'インターネット仮想PCプログラム',
    dataTransferProgram: 'データ転送プログラム',
    desc: '説明',
    install: 'インストール',
    program: 'プログラム',
    vd_dataTransferProgramDesc:
      'インターネット仮想PCとデータ転送を使用するための統合プログラムです。',
    vd_dataTransferProgramDesc2: 'インターネット仮想PCを使用するための基本プログラムです。',
    dataTransferProgramDesc: 'インターネット仮想PCのデータ転送プログラムです。',
    fail: 'プログラムの説明を取得するのに失敗しました。',
    confirm: '確認',
    dropdown: {
      columns: ['プログラム', '説明', 'インストール'],
      description: [
        'インターネット仮想PCとデータ転送を使用するための統合プログラムです。',
        'インターネット仮想PCを使用するための基本プログラムです。',
        'インターネット仮想PCのデータ転送プログラムです。',
      ],
    },
  },
  pwdReg: {
    birthdayPolicy: '生年月日は6文字以内で入力してください。',
    failBirthDay: '生年月日が形式に合っていません。',
  },
  modal: {
    alertConfirm: {
      notice: 'ご案内',
      close: '閉じる',
    },
    alert: {
      notice: 'ご案内',
    },
    authentication: {
      userAuth: 'ユーザー認証',
      emailAuth: '登録されたメールに認証番号が送信されます。',
      enterId: 'ユーザーIDを入力してください。',
      enterName: 'ユーザー名を入力してください。',
      birthday: '生年月日(例:220101)',
      confirm: '確認する',
      close: '閉じる',
      errorId: 'IDはハングル入力ができません。',
      errorBirthday: '生年月日は数字のみ入力可能です。',
      errorResetPwd: 'パスワードの初期化に失敗しました。',
      errorEmail: '認証メール送信に失敗しました。管理者にお問い合わせください。',
      notExistId: '入力したIDが存在しません。',
      wrongEmail: `パスワード初期化のためのユーザーメールアドレス情報が間違って入力されました。<br>ユーザーメールアドレスがない場合は、メールをスキップするをチェックしてください。`,
      notMatchName: '入力したユーザー名が一致しません。',
      notMatchBirthday: '入力した生年月日が一致しません。',
      notMatchName_birthday: '入力した生年月日と名前が一致しません。',
      notExistBirth: '入力した生年月日が存在しません。',
      wrongCourtNet: `パスワードを初期化するためのコートネットメールID情報が間違って入力されました。<br>コートネットメールアカウントがない場合は、メールをスキップするをチェックしてください。`,
    },
    infoRegi: {
      infoRegi: '情報登録',
      noRegisteredBirth_Email:
        '登録された生年月日及びメール情報がありません。<br />情報を入力してください。',
      birthday: '生年月日(例:220101)',
      eg: '例)',
      notRegiEmail: 'メール未登録',
      sendAuthEmail: '認証メール送信',
      authComplete: '認証完了',
      authInComplete: '認証未完了',
      skipEmail: 'メールをスキップ',
      confirm: '確認する',
      close: '閉じる',
      skipDesc: `生年月日とメールアドレスの入力をスキップすると、<br>パスワードの初期化機能をご利用いただけません。`,
      enterEmail: 'メールアドレスを入力してください。',
      noKor: 'メールアドレスにハングルは入力できません。',
      success: '成功',
      sendFail: '認証番号の送信に失敗しました。',
      emailFail: 'メールで認証番号を送信するのに失敗しました。',
      yetComplete: 'メール認証をまだ行っていません。',
      enterBirthday: '生年月日を入力してください',
      onlyNumber: '生年月日は数字のみ入力可能です。',
      infoChangeSuccess: '情報変更に成功しました。',
      infoChangeFail: '情報変更に失敗しました。',
      noKor_email: 'メールアドレスはハングル入力ができません。',
      infoChangeSuccess2: '情報変更に成功しました。',
      infoChangeFail_birth: `情報変更に失敗しました。<br>生年月日を再入力してください。`,
      infoChangeFail_email: `情報変更に失敗しました。<br>メールアドレスを再入力してください。`,
      infoChangeFail_info: `情報変更に失敗しました。<br>情報を再入力してください。`,
    },
    timer: {
      authCodeDesc: '認証番号送信のご案内',
      enterCode: '認証番号を入力してください。',
      confirm: '確認する',
      close: '閉じる',
      sendToCourtNet: 'コートネットメールに認証番号を送信しました',
      sendToEmail: 'ユーザーメールに認証番号を送信しました。',
      timeEnd: `認証可能時間が終了しました。<br>認証番号を再発行してください。`,
      recheckCode: '認証番号を再確認してください。',
      success: '認証番号の確認に成功しました。',
    },
    vdAccess: {
      notice: 'ご案内',
      restricted: `インターネット仮想PCの使用が<span>制限</span>されました。`,
      confirm: '確認する',
    },
    vdProgress: {
      vdProgress: 'インターネット仮想PCの申請状況',
      approved: 'インターネット仮想PCの申請が承認されました。',
      leastOneday: `インターネット仮想PCの申請が承認された後、<br>インターネット仮想PCが割り当てられるまで最低1日かかります。`,
      rejection: '拒否理由:',
      checkReason: '拒否理由を確認します。',
      close: '閉じる',
      confirm: '確認する',
      review: '検討',
      approve: '承認',
      failRead: 'VD進行状況の読み込みに失敗しました。',
    },
  },
  modules: {
    auth: {
      lockedAccount_admin: '管理者によってアカウントが停止されました。',
      title: 'IDまたはパスワードを確認してください。',
      changeIdPwd: 'インターネット仮想PCのパスワードを変更してください。',
      pwdResetDesc: `パスワードエラーでアカウントがロックされました。<br><br><small>パスワード入力欄の下部にあるパスワード初期化ボタンをクリックして新しいパスワードを設定すると、<br>自動的にアカウントのロックが解除されます。</small>`,
      failLogin: `ログインに失敗しました。<br>IDまたはパスワードをご確認ください。`,
      lockedAccount_pwd: `パスワードエラーでアカウントがロックされました。<br>管理者にお問い合わせください。`,
      lockedAccount_undefined: '定義されていないエラーでログインに失敗しました。',
    },
    common: {
      failUserData: 'ユーザー認証情報データの読み込みに失敗しました。',
    },
    modal: {
      confirm: '確認',
    },
    vd: {
      programInstall: `プログラムがインストールされていません。<br>統合プログラムをインストールしますか？`,
      confirm: '確認',
      restartSuccess: 'VDの再起動に成功しました。',
      restartFail: 'VDの再起動に失敗しました。',
    },
  },
};
