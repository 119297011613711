// ========== [ 쿠키값 저장함수 ] ==========
export function setCookie(key, setDate) {
  let date = new Date();
  date.setDate(date.getDate() + setDate); // 쿠키 저장 날자 설정

  let Cookie = '';
  Cookie += `${key}=on;`; // 쿠키 이름 지정
  Cookie += 'expires=' + date.toUTCString();

  // 쿠키에 넣는다.
  document.cookie = Cookie;
}

// ========== [ 쿠키값 제거함수 ] ==========
export function deleteCookie(key, setDate) {
  let date = new Date();
  date.setDate(date.getDate() - setDate);

  let Cookie = '';
  Cookie += `${key}=on;`; // 쿠키 이름 지정
  Cookie += 'expires=' + date.toUTCString();

  // 쿠키에 넣는다.
  document.cookie = Cookie;
}

// ========== [ 쿠키값 읽기함수 ] ==========
// getCookie() 에 setCookie로 설정한 key 값을 넣는다.
// 예) "close=on" 으로 설정했을때 =>  if(getCookie(close) === 'on'){  실행로직   }
export function getCookie(Name) {
  let search = Name + '=';
  if (document.cookie.length > 0) {
    let offset = document.cookie.indexOf(search);
    if (offset != -1) {
      offset += search.length;

      let end = document.cookie.indexOf(';', offset);

      if (end == -1) end = document.cookie.length;
      return unescape(document.cookie.substring(offset, end));
    }
  }
  return '';
}
