<template>
  <div :class="$style.fileUpload">
    <label :class="[$style.fileBtn, isDisabled ? $style.disabled : '']" :for="id">
      {{ _json.common.file.selected }}
    </label>
    <input
      :id="id"
      :ref="isRef"
      :accept="accept"
      :class="$style.input"
      :disabled="isDisabled"
      :name="name"
      style="display: none"
      type="file"
      @change="$emit('onChange', $event)"
    />
    <div :class="$style.removeBtn">
      <template>
        <a
          v-if="imgPath !== '' && fileName !== null"
          :class="$style.desc"
          :href="imgPath"
          target="_blank"
        >
          {{ isFileName() }}
        </a>
        <span v-else :class="$style.desc">{{ isFileName() }}</span>
      </template>
      <button v-if="fileName !== null" type="button" @click="removeFile">
        <font-awesome-icon :class="$style.icon" icon="fa-solid fa-trash-arrow-up" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    fileName: {
      type: String,
      default: '',
    },
    isRef: {
      type: String,
      default: '',
    },
    accept: {
      type: String,
      default: '',
    },
    imgPath: {
      type: String,
      default: '',
    },
  },
  computed: {
    isFileName() {
      return () => {
        return this.fileName !== null ? this.fileName : this._json.common.file.noSelected;
      };
    },
    isDisabled() {
      // console.log('file name : ', typeof this.fileName, this.fileName);
      return this.fileName !== ('' || undefined || null);
    },
  },
  methods: {
    removeFile() {
      const ref = this.isRef;
      this.$refs[ref].value = '';
      this.$emit('removeFiles', this.name);
    },
  },
};
</script>

<style module>
.fileUpload {
  display: flex;
  align-items: center;
}

.fileBtn {
  padding: 0.5rem 1rem;
  border: 1px solid var(--input-border-color);
  border-radius: 2px;
  background-color: var(--input-bg-color);
  transition: ease-in-out all 0.25s;
  cursor: pointer;
}

.fileBtn:hover {
  background-color: #005bc1;
  color: #ffffff;
}

.removeBtn {
  display: flex;
  max-width: 200px;
  color: var(--input-placeholder-color);
}

.removeBtn > span,
.removeBtn > a {
  margin: 0 0.5rem 0 1rem;
}

.removeBtn > a:hover {
  color: var(--primary-color);
}

.removeBtn > button:hover {
  color: #e72020;
}
.disabled {
  color: var(--header-border-color);
  cursor: not-allowed;
}
.disabled:hover {
  background-color: var(--input-bg-color);
  color: var(--header-border-color);
}

.desc {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
