<template>
  <div :class="$style.titleArea" :style="{ marginBottom: marginBottom, marginTop: marginTop }">
    <h2>{{ subpageTitle }}</h2>
  </div>
</template>

<script>
export default {
  props: {
    subpageTitle: String,
    marginBottom: {
      type: [String, Number],
      default: '80px',
    },
    marginTop: {
      type: [String, Number],
      default: '0',
    },
  },
};
</script>

<style module>
.titleArea {
  color: var(--subTitle-text-color);
  font-size: 1.75rem;
  font-weight: 700;
  letter-spacing: -0.42px;
  line-height: 2.5rem;
}
</style>
