<template>
  <TheModal
    :title="_json.modal.timer.authCodeDesc"
    :desc="desc"
    name="timerModal"
    height="auto"
    width="432px"
    @isSubmit="sendEmailCode"
  >
    <template #content>
      <div :class="$style.flexWrap">
        <TheInput
          name="Code"
          :value="Code"
          :placeholder="_json.modal.timer.enterCode"
          type="text"
          @isInput="replaceWord($event)"
        />
        <span id="timer" :class="$style.timer">5:00</span>
      </div>
    </template>
    <template #btn>
      <TheButton width="134px" :disabled="submitBtnDisabled" type="submit" ref="submitBtn">
        {{ _json.modal.timer.confirm }}
      </TheButton>
      <TheButton width="134px" btnStyle="warning" @isClick="modalClose">
        {{ _json.modal.timer.close }}
      </TheButton>
    </template>
  </TheModal>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { checkSecretKeyApi, verifyCodeApi } from '@/api/module/mainApi';

export default {
  data() {
    return {
      Code: '',
      interval: '',
    };
  },
  mounted() {
    if (this.modalList.timerModal.value) {
      this.activeTimer();
    }
    this.setFocus();
  },
  computed: {
    ...mapState('modal', ['modalList']),
    ...mapState('auth', ['user']),
    submitBtnDisabled() {
      return this.Code === '';
    },
    desc() {
      return this._json.modal.timer.sendToEmail;
    },
  },
  methods: {
    ...mapMutations('modal', [
      'SET_MODAL_OPEN',
      'SET_ALERT_VALUE',
      'SET_TIMER_MODAL',
      'SET_TIMER_SUCCESS',
    ]),
    async replaceWord(event) {
      // event.target.value = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
      this[event.target.name] = event.target.value;
    },
    // timer start
    activeTimer() {
      let defaultMin = 60 * 5 - 1;
      let display = document.querySelector('#timer');
      this.setTimer(defaultMin, display);
      // this.setTimer(10, display);
    },
    // timer setting
    setTimer(duration, display) {
      let timer = duration,
        minutes,
        seconds;
      this.interval = setInterval(() => {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;

        display.textContent = minutes + ':' + seconds;

        if (--timer < 0) {
          timer = duration;
        }
        if (timer === 0) {
          clearInterval(this.interval);
          this.SET_ALERT_VALUE({
            desc: this._json.modal.timer.timeEnd,
            btnName: this._json.modal.timer.confirm,
          });
          this.SET_MODAL_OPEN('timerModal');
        }
      }, 1000);
    },
    async sendEmailCode() {
      this.Code = this.Code.trim();
      if (this.Code.length !== 6) {
        this.SET_ALERT_VALUE({
          desc: this._json.modal.timer.recheckCode,
          btnName: this._json.modal.timer.confirm,
        });
        return;
      }
      let req = {
        UserID: this.modalList.timerModal.login
          ? this.user.UserAlias
          : this.modalList.timerModal.UserID,
        Code: this.Code,
      };
      let res;
      if (this.modalList.timerModal.login) {
        // 로그인 후 -> 정보변경 페이지
        res = await verifyCodeApi(req);
        switch (res.data.status) {
          case '0000':
          case '5000':
            this.SET_TIMER_SUCCESS(); // 타이머 모달 결과값 참으로 넣기
            this.SET_TIMER_MODAL({ UserID: '', login: '' });
            this.SET_ALERT_VALUE({
              desc: this._json.modal.timer.success,
              btnName: this._json.modal.timer.confirm,
            });
            clearInterval(this.interval);
            break;
          default:
            await this.SET_ALERT_VALUE({
              desc: this._json.modal.timer.recheckCode,
              btnName: this._json.modal.timer.confirm,
            });
            break;
        }
      } else {
        // 미로그인 상태로 -> 개인정보 인증 후 -> 비번 초기화 페이지로 이동
        res = await checkSecretKeyApi(req);
        switch (res.data.status) {
          case '0000':
          case '5000':
            await this.SET_MODAL_OPEN('authentication');
            await this.SET_TIMER_SUCCESS(); // 타이머 모달 결과값 참으로 넣기
            await this.SET_TIMER_MODAL({ UserID: '', login: '' });
            await this.SET_ALERT_VALUE({
              desc: this._json.modal.timer.success,
              btnName: this._json.modal.timer.confirm,
            });
            clearInterval(this.interval);
            await this.$router.push('/resetpwd');
            break;
          default:
            await this.SET_ALERT_VALUE({
              desc: this._json.modal.timer.recheckCode,
              btnName: this._json.modal.timer.confirm,
            });
            break;
        }
      }
    },
    modalClose() {
      clearInterval(this.interval);
      this.SET_MODAL_OPEN('timerModal');
    },
    setFocus() {
      this.$refs.submitBtn.$el.focus();
    },
  },
};
</script>

<style module>
.flexWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
}

.timer {
  color: var(--list-card-warring-box-color);
  font-weight: bold;
}
</style>
