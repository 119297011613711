export const language_KR = {
  common: {
    header: {
      title: 'CenterPost',
      darkMode: '다크모드',
      internetPc: '인터넷 가상PC',
      notice: '공지사항',
      welcomeText: ' 님 환영합니다.',
      logoutText: '로그아웃',
      networkError: '네트워크 에러가 발생하였습니다.',
      inquiry: '1:1문의',
      infoChange: '정보변경',
      download: '다운로드',
    },
    listCard: {
      viewMore: '더보기',
      noPosts: '등록된 게시물이 없습니다.',
    },
    accordionTr: {
      imgPreview: '이미지 미리보기',
      attachment: '첨부파일 :',
    },
    noticeModal: {
      attachment: '첨부파일',
      stopToday: '오늘 하루 그만 보기',
      stopWeek: '일주일 그만 보기',
      imgPreview: '이미지 미리보기',
      close: '닫기',
      general: '일반',
      urgent: '긴급',
    },
    qnaAccordionTr: {
      imgPreview: '이미지 미리보기',
    },
    file: {
      selected: '파일 선택',
      noSelected: '선택된 파일 없음',
    },
    idx: {
      anotherTab: '다른탭에서 로그인을 하여 로그아웃이 진행되었습니다.',
    },
    interceptors: {
      retryLogin: '접속시간이 만료되어 로그아웃 되었습니다. 다시 로그인을 진행해 주세요.',
    },
    router: {
      pleaseLogin: '로그인이 필요한 서비스 입니다.',
      confirm: '확인',
    },
    navList: [
      {
        to: '/',
        name: '인터넷 가상PC',
      },
      {
        to: '/qna',
        name: '1:1문의',
      },
      {
        to: '/notice',
        name: '공지사항',
      },
      {
        to: '/faq',
        name: 'FAQ',
      },
    ],
  },
  home: {
    banner: {
      title: 'CenterPost 사용자 포탈 입니다.',
      desc: `VD 접속 버튼을 클릭하면 자동으로 접속 클라이언트 설치 파일이 다운로드 됩니다.<br>자세한 사용자 포탈 사용 방법은 매뉴얼을 확인해 주세요.<br>기타 문의사항은 1:1문의 페이지에서 문의해 주세요.`,
      error: '네크워트 에러가 발생하였습니다.',
    },
    secondSection: {
      notices: '공지사항',
      all: '전체',
      general: '일반',
      urgent: '긴급',
      inquiryAnswer: '1:1문의 답변이 완료되었습니다.',
      goTo: '바로가기',
      close: '닫기',
      annae: '안내',
      confirm: '확인',
      failFaqList: 'FAQ 리스트를 받아오는데 실패했습니다.',
      failNoticeList: 'Noice 리스트를 받아오는데 실패했습니다.',
      failInquiryList: '1:1문의 답변 읽기에 실패하였습니다.',
    },
    cloud: {
      pcStatus: 'PC상태:',
      pcStatusDesc: ' 입니다.',
      wouldRestart: '인터넷 가상PC를 재시작 하시겠습니까?',
      miscellaneous: '기타',
      running: '실행 중',
      stopped: '중지 중',
      save: '저장',
      pause: '일시 중지',
      starting: '시작 중',
      restart: '재시작',
      normalGroup: '그룹 정상',
      abnormal: '비정상',
      expirationDate: '만료일',
      remainingPeriod: '남은 기간',
      notice: '안내사항',
      welcome: '방문을 환영합니다. 즐거운 하루 되세요.',
    },
    login: {
      directVd: '인터넷 가상PC 바로 접속하기',
      id: '사용자 아이디를 입력하세요',
      pwd: '비밀번호를 입력하세요',
      saveId: '내 아이디 저장하기',
      resetPwd: '비밀번호 초기화',
      login: '로그인',
    },
    vdApp: {
      noVd: '사용 가능한 인터넷 가상PC가 없습니다.',
      internetVd: '인터넷 가상PC 신청하기',
      reclaimedVd: `인터넷 가상PC를 3개월 이상 미접속시 회수되며,<br>사용이필요할 경우 신규로 인터넷 가상PC 신청을 해주시기 바랍니다.`,
      successVd: '인터넷 가상PC 신청에 성공하셨습니다.',
      errorVd: 'VD신청 도중 에러가 발생했습니다.',
    },
    vdProgress: {
      progress: '인터넷 가상PC 신청 진행 현황',
      apply: '신청',
      review: '검토',
      complete: '완료',
      rejected: '반려',
      approved: '승인',
      app_rej: '승인/반려',
    },
    sectionView: {
      goTo: '사이트 바로가기',
      withoutLogin: '아래 서비스는 인터넷 가상PC 로그인 없이 직접 이용하실 수 있습니다.',
      collapse: '접기',
      expand: '펼쳐보기',
      desc: `※ 전자가족관계등록, 정부24, 국세청(연말정산) 서비스는 우리 법원에서 발급된<br>법원행정전자서명(GPKI)으로 본인확인을 통해 증명서 발급이 가능합니다.`,
      listFail: '사이트 바로가기 리스트를 받아오는데 실패했습니다.',
    },
  },
  firstLoginResetPwd: {
    changePwd: '비밀번호 변경',
    enterNewPWd: '새로 사용할 비밀번호를 입력해주세요.',
    pwdPolicyDesc: `사용자의 비밀번호를 변경합니다.<br>( 1 포함 , 최소 2 자 이상 , 최대 3 자 이하 )`,
    id: '아이디',
    currentPwd: '현재 비밀번호',
    enterCurrentPWd: '현재 비밀번호를 입력해주세요.',
    unableToChangePwd_policy: '비밀번호 정책을 가져오지 못해 변경 불가합니다.',
    newPwd: '새 비밀번호',
    enterNewPwd2: '새로운 비밀번호를 입력해주세요.',
    securityLevel: '보안수준 :',
    availableChar: '사용 가능한 특수문자',
    confirmPwd: '비밀번호 확인',
    reenterNewPwd: '새로운 비밀번호를 다시 입력해주세요.',
    notMatchPwd: '비밀번호가 일치하지 않습니다.',
    currentPwdNewPwdSame: '현재 비밀번호와 새 비밀번호가 동일합니다.',
    change: '변경하기',
    refreshingDesc: '새로고침 시 비밀번호 변경을 처음부터 다시 진행합니다.',
    en_num: '영문/숫자',
    en_num_char: '영문/숫자/특수문자',
    en_num_char_3TypesOrMore: '영문 대문자/소문자/숫자/특수문자 중 3가지 이상 포함',
    low: '낮음',
    medium: '보통',
    safe: '안전',
    failPwdPolicy: '패스워드 정책을 가져오지 못했습니다.',
    enterAllVal: '모든 값을 입력해주세요.',
    confirm: '확인',
    infoChangeSuccess: '정보변경에 성공했습니다.',
    infoChangeFail: '정보변경에 실패했습니다.',
    courtNetMailIdFail: `비밀번호 초기화를 위한 코트넷 메일 아이디 정보가 잘못 입력되어 실패했습니다. <br>코트넷 메일 아이디가 없을 경우 이메일 건너뛰기를 체크해주세요.`,
    notMatch_newPwd_ConfirmPwd: '새 비밀번호와 확인용 비밀번호가 서로 다릅니다.',
  },
  changeUserInfo: {
    infoChange: '정보변경',
    currentPwd_newPwd: '현재 비밀번호 입력 후, 새로 사용할 비밀번호를 입력해 주세요.',
    changePwd: '비밀번호 변경',
    personalInfoChange: '개인정보 변경',
    changeUserPwd: `사용자의 비밀번호를 변경합니다.<br>( 1 포함 , 최소 2 자 이상 , 최대 3 자 이하 )`,
    id: '아이디',
    currentPwd: '현재 비밀번호',
    enterCurrentPwd: '현재 비밀번호를 입력해주세요.',
    pwdPolicyCouldNotRetrieved: '비밀번호 정책을 가져오지 못해 변경 불가합니다.',
    newPwd: '새 비밀번호',
    enterNewPwd: '새로운 비밀번호를 입력해주세요.',
    securityLevel: '보안수준 :',
    availableChar: '사용 가능한 특수문자',
    confirmPWd: '비밀번호 확인',
    reenterNewPwd: '새로운 비밀번호를 다시 입력해주세요.',
    pwdMatchFail: '비밀번호가 일치하지 않습니다.',
    currentPwdNewPwdSame: '현재 비밀번호와 새 비밀번호가 동일합니다.',
    change: '변경하기',
    changeUserName_birthday: '사용자의 이메일 및 생년월일을 변경합니다.',
    birthday: '생년월일',
    birthdayEg: '예) 950307',
    email: '이메일',
    emailNotRegi: '이메일 미등록',
    emailEg: '예) jshwang',
    emailEg2: '예) jshwang@tilon.com',
    authComplete: '인증 완료',
    authIncomplete: '인증 미완료',
    skipEmail: '이메일 건너뛰기',
    sendAuthEmail: '인증메일 발송',
    change2: '변경하기',
    skipDesc: `생년월일 및 이메일 입력을 건너뛰기할 경우<br>비밀번호 초기화 기능을 이용할 수 없습니다.`,
    confirm: '확인',
    en_num: '영문/숫자',
    en_num_char: '영문/숫자/특수문자',
    en_num_char_3TypesOrMore: '영문 대문자/소문자/숫자/특수문자 중 3가지 이상',
    low: '낮음',
    medium: '보통',
    safe: '안전',
    failPwdPolicy: '패스워드 정책을 가져오지 못했습니다.',
    enterAllVal: '모든 값을 입력해주세요.',
    infoChangeSuccess: '정보변경에 성공했습니다.',
    infoChangeFail: '정보변경에 실패했습니다.',
    notMeetPwdPolicy: '비밀번호 정책을 만족하지 않습니다.',
    notMatch_newPwd_ConfirmPwd: '새 비밀번호와 확인용 비밀번호가 서로 다릅니다.',
    enterOnlyNum: '생년월일은 숫자만 입력 가능합니다.',
    enterEmail: '이메일을 입력해 주세요.',
    noMeetEmailPolicy: '입력하신 이메일이 양식에 맞지 않습니다.',
    noKor: '이메일에 한글은 입력 불가합니다.',
    failResetPwdToIncorrectMailId: `비밀번호 초기화를 위한 사용자 메일 아이디 정보가 잘못 입력되어 실패했습니다.<br>사용자 메일 아이디가 없을 경우 이메일 건너뛰기를 체크해주세요.`,
    failSendMailDesc: '인증 메일 전송 실패. 관리자에게 문의해주세요.',
    success: '성공',
    fail: '실패',
  },
  resetPwd: {
    changePwd: '비밀번호 변경',
    enterNewPwd: '새로 사용할 비밀번호를 입력해주세요.',
    pwdPolicy: `사용자의 비밀번호를 변경합니다. <br>( 1 포함 , 최소 2 자 이상, 최대 3 자 이하 )`,
    newPwd: '새 비밀번호',
    enterNewPwd2: '새로운 비밀번호를 입력해주세요.',
    failPwdPolicy: '비밀번호 정책을 가져오지 못해 변경 불가합니다.',
    securityLevel: '보안수준 :',
    availableChar: '사용 가능한 특수문자',
    confirmPwd: '비밀번호 확인',
    reenterNewPwd: '새로운 비밀번호를 다시 입력해주세요.',
    failPwdPolicy2: '비밀번호 정책을 가져오지 못해 변경 불가합니다.',
    notMatchPwd: '비밀번호가 일치하지 않습니다.',
    change: '변경하기',
    en_num: '영문/숫자',
    en_num_char: '영문/숫자/특수문자',
    en_num_char_3TypesOrMore: '영문 대문자/소문자/숫자/특수문자 중 3가지 이상 포함',
    low: '낮음',
    medium: '보통',
    safe: '안전',
    infoChangeFail: '정보변경에 실패했습니다.',
    infoChangeSuccessRetryLogin: `정보변경에 성공했습니다. <br>다시 로그인을 시도해 주세요`,
    refreshingDesc: '새로고침 시 비밀번호 초기화를 처음부터 다시 진행합니다.',
    failPwdPolicy3: '패스워드 정책을 가져오지 못했습니다.',
    enterAllVal: '모든 값을 입력해주세요.',
    failPwdValidity: '비밀번호 유효성 제약 조건에 위반되어 <br>정보변경에 실패했습니다.',
  },
  email: {
    enterCode: '비밀번호 초기화 화면에 아래 인증번호를 입력해주세요.',
    authCode: '인증번호 :',
    emailDesc: '본 메일은 발신전용이며, 문의에 대한 회신은 처리되지 않습니다.',
    contactTilon:
      '인터넷 가상PC 관련하여 궁금하신 사항은 틸론(02-2627-9000)(으)로 문의하시면 답변드리도록 하겠습니다.',
  },
  notice: {
    noPosts: '등록된 게시물이 없습니다.',
    num: '번호',
    notices: '공지사항',
    title: '제목',
    regiDate: '등록일',
    all: '전체',
    general: '일반',
    urgent: '긴급',
    failList: '공지사항 리스트를 가져오는데에 실패했습니다.',
    failPagination: '공지사항 리스트 페이지네이션을 가져오는데에 실패했습니다.',
    dropdown: {
      columns: ['번호', '제목', '등록일'],
    },
  },
  faq: {
    noRegiPosts: '등록된 게시물이 없습니다.',
    num: '번호',
    title: '제목',
    regiDate: '등록일',
    listFail: 'faq 리스트를 가져오는데에 실패했습니다.',
    paginationFail: 'faq 리스트 페이지네이션을 가져오는데에 실패했습니다.',
    dropdown: {
      columns: ['번호', '제목', '등록일'],
    },
  },
  qna: {
    noRegiPosts: '등록된 게시물이 없습니다.',
    inquiry: '1:1 문의',
    responseComplete: '답변 완료',
    waitingForRes: '답변 대기 중',
    delIcon: '삭제 아이콘',
    writeInquiry: '문의 작성',
    num: '번호',
    title: '제목',
    writingDate: '작성일',
    resStatus: '답변 상태',
    delete: '삭제',
    failCheck: '1:1문의 체크를 실패했습니다.',
    confirm: '확인',
    fileList: '1:1문의 리스트를 가져오지 못했습니다.',
    failPagination: '1:1문의 페이지네이션을 가져오지 못했습니다.',
    deleteConfirm: `1:1 문의를 삭제합니다. <br><br>삭제 시, 작성한 게시글 및 <br>답변 내용은 복구할 수 없습니다.`,
    failDelete: '문의 삭제를 실패하였습니다.',
    dropdown: {
      columns: ['번호', '제목', '작성일', '답변 상태', '삭제'],
    },
  },
  inquiry: {
    inquiryTitle: '문의 제목',
    writeContent: '문의 내용 작성',
    enterTitle: '문의하실 제목을 입력해주세요.',
    content: '문의 내용',
    enterContent: '문의하실 내용을 입력해주세요.',
    charCnt: '/ 500자',
    attachment: '첨부파일',
    attachmentDesc: '첨부파일 용량 제한: 10MB, 최대 1개 첨부 가능. 이미지 파일만 첨부 가능',
    register: '등록',
    cancel: '취소',
    titleCntDesc: '문의 제목은 40자 이내로만 작성 가능합니다.',
    confirm: '확인',
    contentCntDesc: '문의사항은 500자 이내로만 작성 가능합니다.',
    onlyImg: '이미지 파일만 업로드 가능합니다.',
    fileSize: '첨부파일 사이즈는 20MB 이내로 등록 가능합니다.',
    blankTitle: '문의 제목을 빈칸으로만 작성하실수 없습니다.',
    registrationComplete: '1:1문의 등록이 완료되었습니다.',
    registerFail: '1:1문의 등록에 실패했습니다.',
  },
  fileDownload: {
    download: '다운로드',
    installClientDesc: '가상PC 사용을 위한 클라이언트를 설치합니다.',
    connectionClient: '접속 클라이언트',
    clientDesc: '가상 PC에 접속하기 위한 클라이언트 입니다.',
    installDesc: '인터넷 가상PC 사용을 위한 프로그램을 설치합니다.',
    internetProgram: '인터넷 가상PC 프로그램',
    dataTransferProgram: '자료전송 프로그램',
    desc: '설명',
    install: '설치',
    program: '프로그램',
    vd_dataTransferProgramDesc: '인터넷 가상PC와 자료전송 사용을 위한 통합 프로그램 입니다.',
    vd_dataTransferProgramDesc2: '인터넷 가상PC 사용을 위한 기본 프로그램 입니다.',
    dataTransferProgramDesc: '인터넷 가상PC 자료전송 프로그램 입니다.',
    fail: '프로그램의 설명을 가져오는데에 실패했습니다.',
    confirm: '확인',
    dropdown: {
      columns: ['프로그램', '설명', '설치'],
      description: [
        '인터넷 가상PC와 자료전송 사용을 위한 통합 프로그램 입니다.',
        '인터넷 가상PC 사용을 위한 기본 프로그램 입니다.',
        '인터넷 가상PC 자료전송 프로그램 입니다.',
      ],
    },
  },
  pwdReg: {
    birthdayPolicy: '생년월일은 6자 이내로 입력해 주세요.',
    failBirthDay: '생년월일이 형식에 맞지 않습니다.',
  },
  modal: {
    alertConfirm: {
      notice: '안내',
      close: '닫기',
    },
    alert: {
      notice: '안내',
    },
    authentication: {
      userAuth: '사용자 인증',
      emailAuth: '사용자 인증 시 사용자 메일로 인증번호가 발송됩니다.',
      enterId: '사용자 아이디를 입력해 주세요.',
      enterName: '사용자 이름을 입력해 주세요.',
      birthday: '생년월일 (예:220101)',
      confirm: '확인',
      close: '닫기',
      errorId: '아이디는 한글 입력이 불가합니다',
      errorBirthday: '생년월일은 숫자만 입력 가능합니다.',
      errorResetPwd: '비밀번호 초기화에 실패했습니다.',
      errorEmail: '인증 메일 전송 실패. 관리자에게 문의해주세요.',
      notExistId: '입력한 아이디가 존재하지 않습니다.',
      wrongEmail: `비밀번호 초기화를 위한 사용자 메일 아이디 정보가 잘못 입력되어 실패했습니다. <br>사용자 메일 아이디가 없을 경우 이메일 건너뛰기를 체크해주세요.`,
      notMatchName: '입력한 사용자 이름이 일치하지 않습니다.',
      notMatchBirthday: '입력한 사용자 생년월일이 일치하지 않습니다.',
      notMatchName_birthday: '입력한 사용자 생년월일과 이름이 일치하지 않습니다.',
      notExistBirth: '입력한 생년월일이 존재하지 않습니다.',
      wrongCourtNet: `비밀번호 초기화를 위한 코트넷 메일 아이디 정보가 잘못 입력되어 실패했습니다. <br>코트넷 메일 아이디가 없을 경우 이메일 건너뛰기를 체크해주세요.`,
    },
    infoRegi: {
      infoRegi: '정보등록',
      noRegisteredBirth_Email:
        '등록 된 생년월일 및 이메일 정보가 없습니다.<br />정보를 입력해 주시기 바랍니다.',
      birthday: '생년월일 (예:220101)',
      eg: '예)',
      notRegiEmail: '이메일 미등록',
      sendAuthEmail: '인증메일 발송',
      authComplete: '인증 완료',
      authInComplete: '인증 미완료',
      skipEmail: '이메일 건너뛰기',
      confirm: '확인',
      close: '닫기',
      skipDesc: `생년월일 및 이메일 입력을 건너뛰기할 경우 <br>비밀번호 초기화 기능을 이용할 수 없습니다.`,
      enterEmail: '이메일을 입력해 주세요.',
      noKor: '이메일에 한글은 입력 불가합니다.',
      success: '성공',
      sendFail: '인증번호를 전송하는데 실패했습니다.',
      emailFail: '이메일로 인증번호를 전송하는데 실패했습니다.',
      yetComplete: '메일 인증을 아직 하지 않으셨습니다.',
      enterBirthday: '생년월일을 입력해 주세요',
      onlyNumber: '생년월일은 숫자만 입력 가능합니다.',
      infoChangeSuccess: '정보 변경에 성공했습니다.',
      infoChangeFail: '정보 변경에 실패했습니다.',
      noKor_email: '이메일은 한글 입력이 불가합니다.',
      infoChangeSuccess2: '정보 변경에 성공했습니다.',
      infoChangeFail_birth: `정보 변경에 실패했습니다. <br>생년월일을 다시 입력해 주세요.`,
      infoChangeFail_email: `정보 변경에 실패했습니다. <br>이메일을 다시 입력해 주세요.`,
      infoChangeFail_info: `정보 변경에 실패했습니다. <br>정보를 다시 입력해 주세요.`,
    },
    timer: {
      authCodeDesc: '인증번호 발송 안내',
      enterCode: '인증번호를 입력해 주세요.',
      confirm: '확인',
      close: '닫기',
      sendToCourtNet: '코트넷 메일로 인증번호를 발송했습니다.',
      sendToEmail: '사용자 메일로 인증번호를 발송했습니다.',
      timeEnd: `인증 가능 시간이 종료되었습니다. <br>인증번호를 재발급 받아주세요.`,
      recheckCode: '인증번호를 다시 확인해 주세요.',
      success: '인증번호 확인에 성공하셨습니다.',
    },
    vdAccess: {
      notice: '안내',
      restricted: `인터넷 가상PC 사용이 <span>제한</span>되었습니다.`,
      confirm: '확인',
    },
    vdProgress: {
      vdProgress: '인터넷 가상PC 신청 진행 현황',
      approved: '인터넷 가상PC 신청이 승인 되었습니다.',
      leastOneday: `인터넷 가상PC 신청 승인 후 인터넷 가상PC가 할당 되기까지 <br>최소 하루가 소요 됩니다.`,
      rejection: '반려사유:',
      checkReason: '반려 사유 확인.',
      close: '닫기',
      confirm: '확인',
      review: '검토',
      approve: '승인',
      failRead: 'VD 진행상태 읽기에 실패하였습니다.',
    },
  },
  modules: {
    auth: {
      lockedAccount_admin: '관리자에 의해 계정이 중지되었습니다.',
      title: '아이디 또는 비밀번호를 확인해 주세요.',
      changeIdPwd: '인터넷 가상 PC 비밀번호를 변경해주세요.',
      pwdResetDesc: `비밀번호 오류로 계정이 잠겼습니다. <br><br><small>비밀번호 입력란 하단의 비밀번호 초기화 버튼을 클릭하여 <br>새로운 비밀번호 설정을 하시면 자동으로 계정 잠김이 해제됩니다.</small>`,
      failLogin: `로그인에 실패했습니다. <br>아이디 또는 패스워드를 확인해주세요.`,
      lockedAccount_pwd: `비밀번호 오류로 계정이 잠겼습니다.<br>관리자에게 문의해주세요.`,
      lockedAccount_undefined: '정의되지 않은 오류로 인해 로그인에 실패했습니다.',
    },
    common: {
      failUserData: '사용자 인증 정보 데이터를 불러오는데 실패하였습니다.',
    },
    modal: {
      confirm: '확인',
    },
    vd: {
      programInstall: `프로그램이 설치되어있지 않습니다. <br>통합프로그램 설치를 진행하시겠습니까?`,
      confirm: '확인',
      restartSuccess: 'VD 재시작에 성공하였습니다.',
      restartFail: 'VD 재시작에 실패하셨습니다.',
    },
  },
};
