<template>
  <ul :class="$style.paginationWrap">
    <li>
      <button
        type="button"
        :class="currentPageNum === 1 ? $style.disabled : ''"
        @click="firstPage"
        :disabled="currentPageNum === 1"
      >
        {{ first }}
      </button>
      <button
        type="button"
        :class="currentPageNum === 1 ? $style.disabled : ''"
        @click="prevPage"
        :disabled="currentPageNum === 1"
      >
        {{ prev }}
      </button>
    </li>
    <li>
      <button
        :class="currentPageNum === num ? $style.active : ''"
        @click="pageMove(num)"
        type="button"
        v-for="(num, idx) in pages"
        :key="idx"
      >
        {{ num }}
      </button>
    </li>
    <li>
      <button
        type="button"
        :class="currentPageNum === totalPages ? $style.disabled : ''"
        @click="nextPage"
        :disabled="currentPageNum === totalPages"
      >
        {{ next }}
      </button>
      <button
        type="button"
        :class="currentPageNum === totalPages ? $style.disabled : ''"
        @click="lastPage"
        :disabled="currentPageNum === totalPages"
      >
        {{ last }}
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  data() {
    return {
      first: '<<',
      prev: '<',
      next: '>',
      last: '>>',
      currentPageNum: 1,
      rangeNum: 5,
    };
  },
  props: {
    totalPages: {
      type: Number,
    },
    currentPage: {
      type: Number,
    },
  },
  mounted() {
    this.currentPageNum = this.currentPage;
  },
  computed: {
    pages() {
      let start = (Math.ceil(this.currentPageNum / this.rangeNum) - 1) * this.rangeNum + 1;
      let end = Math.min(start + this.rangeNum - 1, this.totalPages);
      return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    },
  },
  methods: {
    pageMove(page) {
      this.currentPageNum = page;
      this.$emit('changePage', page);
    },
    firstPage() {
      this.pageMove(1);
    },
    prevPage() {
      this.pageMove(Math.max(this.currentPageNum - this.rangeNum, 1));
      // this.currentPageNum--;
    },
    nextPage() {
      this.pageMove(Math.min(this.currentPageNum + this.rangeNum, this.totalPages));
      // this.currentPageNum++;
    },
    lastPage() {
      this.pageMove(this.totalPages);
    },
  },
};
</script>

<style module>
.paginationWrap {
  display: flex;
  gap: 0.5rem;
}

.paginationWrap button {
  border: 1px solid var(--pagination-border-color);
  padding: 0.3rem 0.5rem;
  border-radius: 4px;
  color: var(--pagination-font);
  /*background-color: var(--pagination-bg);*/
}

.paginationWrap button:not(:last-child) {
  margin-right: 0.3rem;
}

.paginationWrap button:hover:not(.disabled) {
  color: var(--pagination-hover-font);
  background-color: var(--pagination-hover-bg);
  /*font-weight: 600;*/
}

.disabled {
  color: var(--pagination-disable-font) !important;
  background-color: var(--pagination-disable-bg) !important;
  cursor: not-allowed;
  border-color: var(--pagination-disable-border) !important;
}

.paginationWrap .active {
  color: var(--pagination-active);
  /*border-color: var(--pagination-active);*/
  background: var(--pagination-active-bg);
}
</style>
