export const language_EN = {
  common: {
    header: {
      title: 'CenterPost',
      darkMode: 'Dark Mode',
      internetPc: 'Internet Virtual PC',
      notice: 'Notices',
      welcomeText: ' Welcome.',
      logoutText: 'Log Out',
      networkError: 'A network error has occurred.',
      inquiry: '1:1 Inquiry',
      infoChange: 'Change Information',
      download: 'Download',
    },
    listCard: {
      viewMore: 'View More',
      noPosts: 'No posts have been registered.',
    },
    accordionTr: {
      imgPreview: 'Image Preview',
      attachment: 'Attachment',
    },
    noticeModal: {
      attachment: 'Attachment',

      stopToday: 'Stop viewing for today',
      stopWeek: '일주일 그만 보기',
      imgPreview: 'Image Preview',
      close: 'Close',
      general: 'General',
      urgent: 'Urgent',
    },
    qnaAccordionTr: {
      imgPreview: 'Image Preview',
    },
    file: {
      selected: 'Select File',
      noSelected: 'No File Selected',
    },
    idx: {
      anotherTab: 'You have been logged out due to login in another tab.',
    },
    interceptors: {
      retryLogin: 'You have been logged out due to expired session time. Please log in again.',
    },
    router: {
      pleaseLogin: 'You have been logged out due to login in another tab.',
      confirm: 'Confirm',
    },
    navList: [
      {
        to: '/',
        name: 'Internet Virtual PC',
      },
      {
        to: '/qna',
        name: '1:1 Inquiry',
      },
      {
        to: '/notice',
        name: 'Notices',
      },
      {
        to: '/faq',
        name: 'FAQ',
      },
    ],
  },
  home: {
    banner: {
      title: 'Welcome to the CenterPost User Portal.',
      desc: `Click the VD Connection button to download the connection<br/>client installation file automatically.<br>Please check the manual for detailed instructions on using the user portal.<br>For other inquiries, please use the 1:1 Inquiry page.`,
      error: 'A network error has occurred.',
    },
    secondSection: {
      notices: 'Notices',
      all: 'All',
      general: 'General',
      urgent: 'Urgent',
      inquiryAnswer: 'Your 1:1 inquiry response is complete.',
      goTo: 'Go to',
      close: 'Close',
      annae: 'Notice',
      confirm: 'Confirm',
      failFaqList: 'Failed to retrieve the FAQ list.',
      failNoticeList: 'Failed to retrieve the Notice list.',
      failInquiryList: 'Failed to retrieve the Inquiry list.',
    },
    cloud: {
      pcStatus: 'PC Status:',
      pcStatusDesc: '',
      wouldRestart: 'Would you like to restart the Internet Virtual PC?',
      miscellaneous: 'Miscellaneous',
      running: 'Running',
      stopped: 'Stopped',
      save: 'Save',
      pause: 'Pause',
      starting: 'Starting',
      restart: 'Restart',
      normalGroup: 'Group Normal',
      abnormal: 'Abnormal',
      expirationDate: 'Expiration Date',
      remainingPeriod: 'Remaining Period',
      notice: 'Notice',
      welcome: 'We welcome your visit. Have a pleasant day.',
    },
    login: {
      directVd: 'Direct Access to Internet Virtual PC',
      id: 'Please enter your user ID',
      pwd: 'Please enter your password',
      saveId: 'Remember ID',
      resetPwd: 'Reset Password',
      login: 'Login',
    },
    vdApp: {
      noVd: 'No available Internet Virtual PCs.',
      internetVd: 'Apply for Internet Virtual PC',
      reclaimedVd: `It will be reclaimed if you do not access an Internet Virtual PC for more than 3 months. <br>If needed, please apply for a new Internet Virtual PC.`,
      successVd: 'You have successfully applied for an Internet Virtual PC.',
      errorVd: 'An error occurred during the VD application.',
    },
    vdProgress: {
      progress: 'Internet Virtual PC Application Progress',
      apply: 'Apply',
      review: 'Review',
      complete: 'Complete',
      rejected: 'Rejected',
      approved: 'Approved',
      app_rej: 'Approve/Reject',
    },
    sectionView: {
      goTo: 'Quick Access to Sites',
      withoutLogin:
        'The following services can be directly accessed without logging into the Internet Virtual PC.',
      collapse: 'Collapse',
      expand: 'Expand',
      desc: `※ Services like Electronic Family Relations Registration, Government 24, and National Tax Service (Year-end Settlement) can issue certificates through identity <br>verification with the Court Administration Electronic Signature (GPKI) issued by our courts.`,
      listFail: 'Failed to retrieve the quick access site list.',
    },
  },
  firstLoginResetPwd: {
    changePwd: 'Change Password',
    enterNewPWd: 'Please enter the new password you wish to use.',
    pwdPolicyDesc: `Change the user's password.<br>(Including 1, at least 2 characters, <br>up to 3 characters)`,
    id: 'ID',
    currentPwd: 'Current Password',
    enterCurrentPWd: 'Please enter your current password.',
    unableToChangePwd_policy: 'Unable to change as the password policy could not be retrieved.',
    newPwd: 'New Password',
    enterNewPwd2: 'Please enter a new password.',
    securityLevel: 'Security Level :',
    availableChar: 'Available Special Characters',
    confirmPwd: 'Confirm Password',
    reenterNewPwd: 'Please re-enter your new password.',
    notMatchPwd: 'Passwords do not match.',
    currentPwdNewPwdSame: 'The current password and the new password are the same.',
    change: 'Change',
    refreshingDesc: 'Refreshing will restart the password change process from the beginning.',
    en_num: 'English/Numeric',
    en_num_char: 'English/Numeric/Special Characters',
    en_num_char_3TypesOrMore:
      'Include at least 3 of the following: English Uppercase/Lowercase/Numeric/Special Characters.',
    low: 'Low',
    medium: 'Medium',
    safe: 'Safe',
    failPwdPolicy: 'Failed to retrieve password policy.',
    enterAllVal: 'Please enter all values.',
    confirm: 'Confirm',
    infoChangeSuccess: 'Information change was successful.',
    infoChangeFail: 'Information change failed.',
    courtNetMailIdFail: `Password reset failed due to incorrect CourtNet mail ID information. <br>If you do not have a CourtNet mail ID, please check to skip email.`,
    notMatch_newPwd_ConfirmPwd: 'The new password and confirmation password do not match.',
  },
  changeUserInfo: {
    infoChange: 'Information Change',
    currentPwd_newPwd: 'Please enter your current password and the new one you wish to use.',
    changePwd: 'Change Password',
    personalInfoChange: 'Change Personal Information',
    changeUserPwd: `Change the user's password.<br>(Including 1, at least 2 characters, <br>up to 3 characters)`,
    id: 'ID',
    currentPwd: 'Current Password',
    enterCurrentPwd: 'Please enter your current password.',
    pwdPolicyCouldNotRetrieved: 'Unable to change as the password policy could not be retrieved.',
    newPwd: 'New Password',
    enterNewPwd: 'Please enter a new password.',
    securityLevel: 'Security Level :',
    availableChar: 'Available Special Characters',
    confirmPWd: 'Confirm Password',
    reenterNewPwd: 'Please re-enter your new password.',
    pwdMatchFail: 'Passwords do not match.',
    currentPwdNewPwdSame: 'The current password and the new password are the same.',
    change: 'Change',
    changeUserName_birthday: "Change the user's email and date of birth.",
    birthday: 'Date of Birth',
    birthdayEg: 'e.g.) 950307',
    email: 'Email',
    emailNotRegi: 'Email Not Registered',
    emailEg: 'e.g.) jshwang',
    emailEg2: 'e.g.) jshwang@tilon.com',
    authComplete: 'Authentication Complete',
    authIncomplete: 'Authentication Incomplete',
    skipEmail: 'Skip Email',
    sendAuthEmail: 'Send Authentication Email',
    change2: 'Change',
    skipDesc:
      'Skipping the date of birth and email entry <br>will disable the password reset function.',
    confirm: 'Confirm',
    en_num: 'English/Numeric',
    en_num_char: 'English/Numeric/Special Characters',
    en_num_char_3TypesOrMore:
      'English Uppercase/Lowercase/Numeric/Special Characters - 3 types or more',
    low: 'Low',
    medium: 'Medium',
    safe: 'Safe',
    failPwdPolicy: 'Failed to retrieve password policy.',
    enterAllVal: 'Please enter all values.',
    infoChangeSuccess: 'Information change was successful.',
    infoChangeFail: 'Information change failed.',
    notMeetPwdPolicy: 'Does not meet password policy.',
    notMatch_newPwd_ConfirmPwd: 'The new password and confirmation password do not match.',
    enterOnlyNum: 'Only numbers can be entered for the date of birth.',
    enterEmail: 'Please enter an email.',
    noMeetEmailPolicy: 'The entered email does not follow the correct format.',
    noKor: 'Korean characters cannot be entered in the email.',
    failResetPwdToIncorrectMailId: `Password reset failed due to incorrect user mail ID information. <br>If you do not have a user mail ID, please check to skip email.`,
    failSendMailDesc: 'Failed to send the authentication email. Please contact the administrator.',
    success: 'Success',
    fail: 'Failure',
  },
  resetPwd: {
    changePwd: 'Change Password',
    enterNewPwd: 'Please enter the new password you wish to use.',
    pwdPolicy: `Change the user's password. <br>(Including 1, at least 2 characters, up to 3 characters)`,
    newPwd: 'New Password',
    enterNewPwd2: 'Please enter a new password.',
    failPwdPolicy: 'Unable to change as the password policy could not be retrieved.',
    securityLevel: 'Security Level :',
    availableChar: 'Available Special Characters',
    confirmPwd: 'Confirm Password',
    reenterNewPwd: 'Please re-enter your new password.',
    failPwdPolicy2: 'Unable to change as the password policy could not be retrieved.',
    notMatchPwd: 'Passwords do not match.',
    change: 'Change',
    en_num: 'English/Numeric',
    en_num_char: 'English/Numeric/Special Characters',
    en_num_char_3TypesOrMore:
      'Include at least 3 of the following: English Uppercase/Lowercase/Numeric/Special Characters.',
    low: 'Low',
    medium: 'Medium',
    safe: 'Safe',
    infoChangeFail: 'Information change failed.',
    infoChangeSuccessRetryLogin: `Information change was successful. <br>Please try logging in again.`,
    refreshingDesc: 'Refreshing will restart the password reset process from the beginning.',
    failPwdPolicy3: 'Failed to retrieve password policy.',
    enterAllVal: 'Please enter all values.',
    failPwdValidity:
      'Information change failed due to a violation <br>of password validity constraints.',
  },
  email: {
    enterCode: 'Please enter the following authentication code on the password reset screen.',
    authCode: 'Authentication Code :',
    emailDesc: 'This email is for sending only, and replies to inquiries will not be processed.',
    contactTilon:
      'For inquiries related to Internet Virtual PC, please contact TILON (02-2627-9000).',
  },
  notice: {
    noPosts: 'No registered posts.',
    num: 'Number',
    notices: 'Notices',
    title: 'Title',
    regiDate: 'Date of Registration',
    all: 'All',
    general: 'General',
    urgent: 'Urgent',
    failList: 'Failed to retrieve the notice list.',
    failPagination: 'Failed to retrieve notice list pagination.',
    dropdown: {
      columns: ['Number', 'Title', 'Registration Date'],
    },
  },
  faq: {
    noRegiPosts: 'No registered posts.',
    num: 'Number',
    title: 'Title',
    regiDate: 'Date of Registration',
    listFail: 'Failed to retrieve the FAQ list.',
    paginationFail: 'Failed to retrieve FAQ list pagination.',
    dropdown: {
      columns: ['Number', 'Title', 'Registration Date'],
    },
  },
  qna: {
    noRegiPosts: 'No registered posts.',
    inquiry: '1:1 Inquiry',
    responseComplete: 'Response Complete',
    waitingForRes: 'Waiting for Response',
    delIcon: 'Delete Icon',
    writeInquiry: 'Write Inquiry',
    num: 'Number',
    title: 'Title',
    writingDate: 'Date of Writing',
    resStatus: 'Response Status',
    delete: 'Delete',
    failCheck: 'Failed to check the 1:1 inquiry.',
    confirm: 'Confirm',
    fileList: 'Failed to retrieve the 1:1 inquiry list.',
    failPagination: 'Failed to retrieve the 1:1 inquiry pagination.',
    deleteConfirm: `Deleting a 1:1 inquiry. <br><br>Once deleted, <br>the written posts and responses cannot be recovered.`,
    failDelete: 'Failed to delete the inquiry.',
    dropdown: {
      columns: ['Number', 'Title', 'Date of Writing', 'Response Status', 'Delete'],
    },
  },
  inquiry: {
    inquiryTitle: 'Inquiry Title',
    writeContent: 'Write Inquiry Content',
    enterTitle: 'Please enter the title of your inquiry.',
    content: 'Inquiry Content',
    enterContent: 'Please enter the content of your inquiry.',
    charCnt: '/ 500 char',
    attachment: 'Attachment',
    attachmentDesc: `Attachment size limit: 10MB, maximum 1 attachment allowed. <br>Only image files can be attached.`,
    register: 'Register',
    cancel: 'Cancel',
    titleCntDesc: 'The inquiry title must be within 40 characters.',
    confirm: 'Confirm',
    contentCntDesc: 'Inquiries must be within 500 characters.',
    onlyImg: 'Only image files can be uploaded.',
    fileSize: 'The attachment file size can be up to 20MB.',
    blankTitle: 'The inquiry title cannot be left blank.',
    registrationComplete: 'Your 1:1 inquiry registration is complete.',
    registerFail: 'Failed to register your 1:1 inquiry.',
  },
  fileDownload: {
    download: 'Download',
    installClientDesc: 'Install the client for using Virtual PC.',
    connectionClient: 'Connection Client',
    clientDesc: 'This is the client for connecting to the Virtual PC.',
    installDesc: 'Install the program for using Internet Virtual PC.',
    internetProgram: 'Internet Virtual PC Program',
    dataTransferProgram: 'Data Transfer Program',
    desc: 'Description',
    install: 'Install',
    program: 'Program',
    vd_dataTransferProgramDesc: `This is an integrated program <br>for using both Internet Virtual PC and Data Transfer.`,
    vd_dataTransferProgramDesc2: 'This is a basic program for using Internet Virtual PCs.',
    dataTransferProgramDesc: 'Internet Virtual PC Data Transfer Program.',
    fail: 'Failed to retrieve the program description.',
    confirm: 'Confirm',
    dropdown: {
      columns: ['Program', 'Description', 'Install'],
      description: [
        `This is an integrated program <br>for using both Internet Virtual PC and Data Transfer.`,
        'This is a basic program for using Internet Virtual PCs.',
        'Internet Virtual PC Data Transfer Program.',
      ],
    },
  },
  pwdReg: {
    birthdayPolicy: 'Please enter your date of birth within 6 characters.',
    failBirthDay: 'The date of birth does not match the format.',
  },
  modal: {
    alertConfirm: {
      notice: 'Notice',
      close: 'Close',
    },
    alert: {
      notice: 'Notice',
    },
    authentication: {
      userAuth: 'User Authentication',
      emailAuth:
        "During user authentication, an authentication code will be sent to the user's email.",
      enterId: 'Please enter your user ID.',
      enterName: 'Please enter your name.',
      birthday: 'Date of Birth (e.g., 220101)',
      confirm: 'Confirm',
      close: 'Close',
      errorId: 'ID cannot be entered in Korean.',
      errorBirthday: 'Date of birth must be entered in numbers only.',
      errorResetPwd: 'Failed to reset the password.',
      errorEmail: 'Authentication email sending failed. Please contact the administrator.',
      notExistId: 'The entered ID does not exist.',
      wrongEmail: `Password reset failed due to incorrect user mail ID information. <br>If you do not have a user mail ID, please check to skip email.`,
      notMatchName: 'The entered user name does not match.',
      notMatchBirthday: 'The entered date of birth does not match.',
      notMatchName_birthday: 'The entered date of birth and name do not match.',
      notExistBirth: 'The entered date of birth does not exist.',
      wrongCourtNet: `Password reset failed due to incorrect CourtNet mail ID information. <br>If you do not have a CourtNet mail ID, please check to skip email.`,
    },
    infoRegi: {
      infoRegi: 'Information Registration',
      noRegisteredBirth_Email:
        'No registered date of birth or email information.<br />Please enter your information.',
      birthday: 'Date of Birth (e.g., 220101)',
      eg: 'e.g.,)',
      notRegiEmail: 'Email Not Registered',
      sendAuthEmail: 'Send Authentication Email',
      authComplete: 'Authentication Complete',
      authInComplete: 'Authentication Incomplete',
      skipEmail: 'Skip Email',
      confirm: 'Confirm',
      close: 'Close',
      skipDesc: `Skipping the date of birth and email entry will <br>disable the password reset function.`,
      enterEmail: 'Please enter an email.',
      noKor: 'Korean characters cannot be entered in the email.',
      success: 'Success',
      sendFail: 'Failed to send the authentication code.',
      emailFail: 'Failed to send the authentication code to the email.',
      yetComplete: 'You have not yet completed email verification.',
      enterBirthday: 'Please enter your date of birth.',
      onlyNumber: 'Only numbers can be entered for the date of birth.',
      infoChangeSuccess: 'Information change was successful.',
      infoChangeFail: 'Information change failed.',
      noKor_email: 'Korean characters cannot be entered in the email.',
      infoChangeSuccess2: 'Information change was successful.',
      infoChangeFail_birth: `Information change failed. <br>Please re-enter your date of birth.`,
      infoChangeFail_email: `Information change failed. <br>Please re-enter your email.`,
      infoChangeFail_info: `Information change failed. <br>Please re-enter your information.`,
    },
    timer: {
      authCodeDesc: 'Authentication Code Sending Instructions',
      enterCode: 'Please enter the authentication code.',
      confirm: 'Confirm',
      close: 'Close',
      sendToCourtNet: 'The authentication code has been sent to your CourtNet email.',
      sendToEmail: 'The authentication code has been sent to your user email.',
      timeEnd: `The available time for authentication has ended. <br>Please reissue the authentication code.`,
      recheckCode: 'Please recheck the authentication code.',
      success: 'You have successfully verified the authentication code.',
    },
    vdAccess: {
      notice: 'Notice',
      restricted: `Your use of the Internet Virtual PC has been <span>restricted.</span>`,
      confirm: 'Confirm',
    },
    vdProgress: {
      vdProgress: 'Internet Virtual PC Application Progress',
      approved: 'Your application for an Internet Virtual PC has been approved.',
      leastOneday: `After approval of the Internet Virtual PC application, <br>it takes at least one day for the Internet Virtual PC to be allocated.`,
      rejection: 'Reason for Rejection:',
      checkReason: 'Check the reason for rejection.',
      close: 'Close',
      confirm: 'Confirm',
      review: 'Review',
      approve: 'Approve',
      failRead: 'Failed to read the VD progress status.',
    },
  },
  modules: {
    auth: {
      lockedAccount_admin: 'The administrator has suspended your account.',
      title: 'Please check your ID or password.',
      changeIdPwd: 'Please change your Internet Virtual PC password.',
      pwdResetDesc: `Your account has been locked due to a password error. <br><br><small>Click the 'Reset Password' button below the password field to set a new password, <br>automatically unlocking your account.</small>`,
      failLogin: `Login failed. <br>Please check your ID or password.`,
      lockedAccount_pwd: `Your account has been locked due to a password error.<br>Please contact the administrator.`,
      lockedAccount_undefined: 'The login failed due to an undefined error.',
    },
    common: {
      failUserData: 'Failed to load user authentication data.',
    },
    modal: {
      confirm: 'Confirm',
    },
    vd: {
      programInstall: `The program is not installed. <br>Would you like to proceed with <br>the integrated program installation?`,
      confirm: 'Confirm',
      restartSuccess: 'Successfully restarted the VD.',
      restartFail: 'Failed to restart the VD.',
    },
  },
};
